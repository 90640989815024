import {
  ClassicEditor,
  Context,
  InlineEditor,
  ContextWatchdog,
  Autoformat,
  Bold,
  Italic,
  BlockQuote,
  Base64UploadAdapter,
  CKFinder,
  CKFinderUploadAdapter,
  CloudServices,
  EditorConfig,
  Essentials,
  Heading,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  PictureEditing,
  Indent,
  Link,
  List,
  Mention,
  Paragraph,
  PasteFromOffice,
  Table,
  TableToolbar,
  TextTransformation,
  SourceEditing
} from "ckeditor5";
import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";

import "ckeditor5/ckeditor5.css";
import { FC } from "react";
interface ILabelAndEditor {
  refId: any;
  label: string;
  value: string | undefined;
  name?: string;
  setValue: React.Dispatch<React.SetStateAction<string>> | undefined;
  isRequired?:boolean;
}
const LabelAndEditor: FC<ILabelAndEditor> = ({
  refId,
  label,
  value,
  setValue,
  isRequired,
  name
}) => {
  const handleEditorChange = (event: any, editor: any) => {
    if (setValue)
      setValue(editor.getData());
  };
  return (
    <div className="mb-5 last:mb-0">
      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
        {label}{isRequired==true?<span className="text-14 text-red-500">*</span>:''}
      </label>
      <CKEditorContext context={Context} contextWatchdog={ContextWatchdog}>
        <CKEditor
          editor={ClassicEditor}
          ref={refId}
          config={{
            plugins: [
              Essentials,
              Autoformat,
              Bold,
              Italic,
              BlockQuote,
              CKFinder,
              CKFinderUploadAdapter,
              CloudServices,
              Heading,
              Image,
              ImageCaption,
              ImageStyle,
              ImageToolbar,
              ImageUpload,
              Base64UploadAdapter,
              Indent,
              Link,
              List,
              Mention,
              Paragraph,
              PasteFromOffice,
              PictureEditing,
              Table,
              TableToolbar,
              TextTransformation,
              SourceEditing
            ],
            toolbar: [
              "sourceEditing",
              "|",
              "undo",
              "redo",
              "|",
              "heading",
              "|",
              "bold",
              "italic",
              "|",
              "link",
              "uploadImage",
              "insertTable",
              "blockQuote",
              "|",
              "bulletedList",
              "numberedList",
              "|",
              "outdent",
              "indent"
            ],
            heading: {
              options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
              ]
            }
          }}
          data={value ? value : ""}
          onReady={editor => { }}
          onBlur={handleEditorChange}
        />
      </CKEditorContext>
    </div>
  );
};
export default LabelAndEditor;
