import { ReactElement } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import OverViewPage from "./sections/OverviewPage";
import LatestNewsPage from "./sections/LatestNewsPage";
import CategoryPage from "./sections/CategoryPage";
import LatestCategoryPage from "./sections/LatestCategoryPage";
import LatestNewsSectionImageList from "./components/services/LatestNewsSectionImageList";
export const latestNewsPagesList = [
  {
    id: 1,
    path: "#",
    component: <OverViewPage />,
  },
  {
    id: 2,
    path: "overview",
    component: <OverViewPage />,
  },
  {
    id: 3,
    path: "latest-news-list",
    component: <LatestNewsPage />,
  },
  {
    id: 4,
    path: "image-list",
    component: <LatestNewsSectionImageList />,
  },
  {
    id: 5,
    path: "category",
    component: <LatestCategoryPage />,
  },
];
const LatestNewsPageRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  return (
    <BasicLayout userData={data} permissions={permissions}>
      <div className="pt-0 pb-[58px]">
        <Outlet />
      </div>
    </BasicLayout>
  );
};
export default LatestNewsPageRoute;
