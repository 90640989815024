import { FC, useEffect, useState } from "react";
import CommonLanguage from "../common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndImage from "./LabelAndImage";
import LabelAndTextbox from "./LabelAndTextbox";
export interface IBanner {
    banner: IBannerData | undefined;
    setBanner: React.Dispatch<React.SetStateAction<IBannerData | undefined>>;
}

export interface IBannerData {
    image: File | undefined;
    image_url: string | undefined;
    title_en: string;
    title_tc: string;
    title_sc: string;
}
const BannerSingle: FC<IBanner> = ({
    banner,
    setBanner
}) => {
    const [data, setData] = useState<IBannerData>({
        title_en: '',
        title_tc: '',
        title_sc: '',
        image: undefined,
        image_url: ''
    });
    const [activeLang, setActiveLang] = useState<string>("en");
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(() => {
        if (banner) {
            setData(banner)
        }
    }, [banner]);
    const change_data = (value: any, key: string) => {
        if (banner) {
            if (key == "image") {
                const url=value?URL.createObjectURL(value):'';
                setBanner((prev) => {
                    if (prev) {
                        return {
                            ...prev,
                            image: value,
                            image_url: url
                        }
                    }
                });
                return;
            }
            setBanner({ ...banner, [key]: value })
        }
    }
    return <div>
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >

                <LabelAndTextbox label="Banner Title (EN)" value={data?.title_en} setValue={(value) => change_data(value, 'title_en')} />
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >

                <LabelAndTextbox label="Banner Title (TC)" value={data?.title_tc} setValue={(value) => change_data(value, 'title_tc')} />
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >

                <LabelAndTextbox label="Banner Title (SC)" value={data?.title_sc} setValue={(value) => change_data(value, 'title_sc')} />
            </div>
            <LabelAndImage label="Banner Image" value={data?.image_url} setValue={(value) => change_data(value, 'image_url')} setFile={(value) => change_data(value, 'image')} />
        </div>
    </div>
}
export default BannerSingle;