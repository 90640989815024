import { FC, useEffect, useState } from "react";
import FooterSocialMediaTable from "./FooterSocialMediaTable";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { baseObj } from "../../utils/constants";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import Button from "../../components/common/Button";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../components/common/Icons";
import { useGetFooterQuery } from "../../app/services/footer";
export interface ISocialMediaData {
  social_media: ISocialMedia[];
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailData: React.Dispatch<React.SetStateAction<ISocialMedia | undefined>>;
}
export interface ISocialMedia {
  id?: number;
  image: File | undefined;
  image_url: string | undefined;
  link: string | undefined;
}
const FooterSocialMedia: FC<ISocialMediaData> = ({
  social_media,
  setShow,
  setDetailData
}) => {
  // const [data, setData] = useState<ISocialMedia[]>(social_media);
  const [editRowData, setEditRowData] = useState<any>();
  const [showList, setShowList] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [viewCols, setCols] = useState<any>([]);
  const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
    false
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [data, setData] = useState<baseObj[]>(social_media);
  const [itemCount, setItemCount] = useState<number>(0);
  const [tempList, setTempList] = useState<any>(templateList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [sorting, setSorting] = useState<SortingState>([]);
  const [socialMedia, setSocialMedia] = useState<ISocialMedia[]>([]);
  const { data: footer } = useGetFooterQuery({
    search:globalSearch,
    sort: sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${s.id}`
      )
      .join(","),
    page_type:'footer_social'
  });  
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20
  });
  useEffect(
    () => {
      if (footer) {
        setData(footer?.footer_social);
      }
    },
    [footer]
  );
  const clear_data = () => {
    setDetailData({
      id: 0,
      link: "",
      image: undefined,
      image_url: ""
    });
  };
  useEffect(() => {
    if (footer) {
      const social = footer?.footer_social;
      setSocialMedia(social?.map((social: any) => {
        return {
          id: social.id,
          image_url: social?.logo,
          link: social?.link,
          image: undefined
        }
      }))
    }
  }, [footer])
  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
          <DebounceSearchInput
            setState={setGlobalSearch}
            debounce={800}
          >
            {(setState) => (
              <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px] sm:w-[260px] w-[225px]">
                <input
                  type="text"
                  placeholder="Search"
                  className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                  onChange={(e) => setState(e.target.value)}
                />
                <SearchIcon className="mr-2" />
              </div>
            )}
          </DebounceSearchInput>
        </div>
        <div className="items-center xs:mt-1 sm:mt-1 flex justify-end mb-3">
          <Button
            label="+ Add New"
            onClick={() => { clear_data(); setShow(true) }}
            type="button"
            variant="primary"
            customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
            size="base"
          />
        </div>
      </div>

      <div className="text-left">
        <FooterSocialMediaTable
          // columnsData={columnsData}
          isLayoutControlOpen={isLayoutControlOpen}
          setIsLayoutControlOpen={setIsLayoutControlOpen}
          globalSearch={globalSearch}
          setGlobalSearch={setGlobalSearch}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          pagination={pagination}
          data={data}
          setData={setData}
          setPagination={setPagination}
          hiddenFields={hiddenFields ? hiddenFields : []}
          setHiddenFields={setHiddenFields}
          checkManagement={true}
          total={itemCount}
          localModuleId={localModuleId}
          // visArray={visArray}
          templateList={tempList}
          setCurrentView={() => 1}
          setCols={setCols}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setEditRowData={setEditRowData}
          editRowData={editRowData}
          setShowList={setShow}
          setDetailData={setDetailData}
          sorting={sorting}
          setSorting={setSorting}
        />
      </div>
    </div>
  );
};
export default FooterSocialMedia;
