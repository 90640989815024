import { redirectToLogin } from "../annoucement-sheet";
import { api } from "../api";
import authHeader from "../auth-header";
export interface IServicePageInfoData {
    "page": IServiceDataInfo,
    "page_service_section":any;
}
export interface IServiceDataInfo {
    "id": number,
    "sub_menu_id": number,
    "menu_id": number,
    "slug": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "meta_title_en": string,
    "meta_title_tc": string,
    "meta_title_sc": string,
    "meta_description_en": string,
    "meta_description_tc": string,
    "meta_description_sc": string,
    "meta_image": string,
    "banner_mobile_image": string,
    "banner_desktop_image": string,
}

export type TData = {
    data: [];
    total: number;
};
export type PaginationData = {
    page: number;
    limit: number | string | undefined;
    search: string | number;
    sort?: string;
    type?: string;
};
export const jobDepartmentApi = api.injectEndpoints({
    endpoints: (build) => ({
        fetch_jobdepartment_opening: build.query<TData, PaginationData>({
            query: ({ limit, page, search,type,sort }) => ({
                url: `department/fetch?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "JobDepartment" as const, id: "LIST" }]
        }),
        fetch_jobdepartment_opening_info: build.mutation<TData, PaginationData>({
            query({ limit, page, search, type, sort }) {
                return {
                    headers: authHeader(),
                    url: `department/fetch?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['JobDepartment']
        }),
        deleteJobDeparment: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `department/delete`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['JobDepartment']
        }),
    })
});

export const { useFetch_jobdepartment_openingQuery,useFetch_jobdepartment_opening_infoMutation,useDeleteJobDeparmentMutation } = jobDepartmentApi;

export const {
    endpoints: { fetch_jobdepartment_opening,fetch_jobdepartment_opening_info,deleteJobDeparment }
} = jobDepartmentApi;
