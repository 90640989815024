import { api } from "../api";
import authHeader from "../auth-header";
import { redirectToLogin } from "../notice-board";

export type IhomeServiceInfo = {
  id: number;
  title_en: string;
  title_tc: string;
  title_sc: string;
  image:string;
};
export type TData = {
  data: IhomeServiceInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
};
export type PaginationData = {
  page: number;
  limit: number | string | undefined;
  search: string | number;
  sort?: string;
};
export type TaskCategoriesList = {
  categoryList: TaskDepartmentListData[];
};
export type ManualData = {
  data: TData;
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};

export const manualApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHomeServices: build.query<TData, PaginationData>({
      query: ({ limit, page, search,sort }) => ({
        url: `home-services?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "Services" as const, id: "LIST" }],
    }),
    // createHomeService: build.mutation<ManualData, FaqData>({
    //   query(body) {
    //     return {
    //       headers: authHeader(),
    //       url: `services`,
    //       body,
    //       method: "POST",
    //     };
    //   },
    // }),
    // updateHomeService: build.mutation<TaskCategoriesList, FaqData>({
    //   query(body) {
    //     return {
    //       headers: authHeader(),
    //       url: `services/${body.id}`,
    //       body,
    //       method: "PUT",
    //     };
    //   },
    // }),
    deleteHomeService: build.mutation<TaskCategoriesList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/delete-home-services`,
          body:{
            id:id
          },
          method: "POST",
        };
      },
      invalidatesTags: [{ type: "Services", id: "LIST" }],
    })
  }),
});

export const {
 useGetHomeServicesQuery,
 useDeleteHomeServiceMutation
} = manualApi;

export const {
  endpoints: { getHomeServices, deleteHomeService },
} = manualApi;
