import { FC, useEffect, useState } from "react";
import { baseObj } from "../../../utils/constants";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { templateList } from "../../../active-time-report/views/ActiveTimeReport";
import { twJoin } from "tailwind-merge";
import Button from "../../../components/common/Button";
import AwardAndCertificateInput from "./AwardAndCertificateInput";
import AwardsAndCertificateTable from "./AwardsAndCertificateTable";
import { useFetch_award_categoryQuery, useGetAwardInfoMutation, useGetAwardsListQuery, useGetCertsListQuery } from "../../../app/services/company/award";
import axios from "../../../axios";
import { endpointUrl } from "../../../app/services/api";
import { ToastContainer, toast } from "react-toastify";
import CommonErrorPopup from "../../../components/common/CommonErrorPopup";
import { renderDateFormatDMY } from "../../../components/DashboardCalendarView/DashboardAddEventPopup";
import { ILabelValueData } from "../../../latest-news/components/LatestNews";
import CertificateTable from "./CertificateTable";
import DebounceSearchInput from "../../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../../components/common/Icons";
import { NumberDropdownList } from "../../../components/common/DropdownList";
export interface IAwardAndCertificate {
    id: number;
    upload_date?: Date | undefined;
    title_en: string;
    title_tc: string;
    title_sc: string;
    description_en: string;
    description_tc: string;
    description_sc: string;
    image: File | undefined;
    image_url: string;
    category_id?: string;
    categoryList?: ILabelValueData[];
}
export interface IAwardAndCertificateList {
    data_list: baseObj[];
    showData?: boolean;
}
const AwardAndCertificateList: FC<IAwardAndCertificateList> = ({
    data_list,
    showData
}) => {
    const [editRowData, setEditRowData] = useState<any>();
    const [detailData, setDetailData] = useState<IAwardAndCertificate>({
        id: 0,
        title_en: '',
        title_tc: '',
        title_sc: '',
        description_en: '',
        description_tc: '',
        description_sc: '',
        image: undefined,
        image_url: ''
    });
    const [show, setShow] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [viewCols, setCols] = useState<any>([]);
    const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
        false
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    let [localModuleId, setLocalModuleId] = useState<number>(0);
    const [data, setData] = useState<baseObj[]>([]);
    const [itemCount, setItemCount] = useState<number>(0);
    const [tempList, setTempList] = useState<any>(templateList);
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [getAwardsInfo] = useGetAwardInfoMutation();
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20
    });
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const [sorting, setSorting] = useState<SortingState>([]);
    const { data: awards } = useGetCertsListQuery({
        page: currentPage,
        search: globalSearch,
        limit: pagination.pageSize,
        sort: sorting?sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(','):'',
    });
    useEffect(() => {
        if (awards) {
            setData(awards?.data);
            setItemCount(awards?.total);
        }
    }, [awards]);
    const handle_submit = async (data: IAwardAndCertificate) => {
        const formData = new FormData();
        if (data) {
            formData.set('id', data.id?.toString() ?? 0)
            formData.set('title_en', data.title_en);
            formData.set('title_tc', data.title_tc);
            formData.set('title_sc', data.title_sc);
            formData.set('description_en', data.description_en);
            formData.set('description_tc', data.description_tc);
            formData.set('description_sc', data.description_sc);
            if (data.upload_date) {
                formData.set('date', renderDateFormatDMY(data.upload_date, '-'));
            }

            if (data.image) {
                formData.set('image', data.image);
            }
            const url = detailData?.id ? 'update-certification-content' : 'certification-content';
            await axios(endpointUrl + url, {
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: formData,
            })
                .then((res: any) => {
                    if (res?.data?.status == true) {
                        getAwardsInfo({
                            page: currentPage,
                            limit: pagination.pageSize,
                            search: '',
                            sort:''
                        }).then((res: any) => {
                            setData(res?.data?.data);
                            setItemCount(res?.data?.total);
                        })
                        toast(res?.data?.message, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            type: "success",
                        });
                        setShow(true);
                    } else {
                        console.log('res ', res?.response?.data)
                        setErrorPopup(true);
                        setMessage(Object.values(res?.response?.data?.errors)?.join(' '));
                        //setMessage(res?.response?.data?.message);
                    }
                    setIsSave(false);
                })
                .catch((err) => {
                    console.log('res ', err?.response?.data)
                    setErrorPopup(true);
                    setMessage(Object.values(err?.response?.data?.errors)?.join(' '));
                    // setMessage(err?.data?.message);
                });
        }

    }
    const clear_data = () => {
        setDetailData({
            id: 0,
            title_en: '',
            title_tc: '',
            title_sc: '',
            description_en: '',
            description_tc: '',
            description_sc: '',
            image: undefined,
            image_url: ''
        })
    }
    return <div
        className={twJoin(
            "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
        )}
    >
        <ToastContainer className={"text-12 font-poppins font-normal"} />
        <CommonErrorPopup
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
        />
        <div className="text-left">
            {show == false ? <AwardAndCertificateInput cateogryList={[]} handle_submit={handle_submit} isSave={isSave} isShowDate={showData} isShowCategory={false} awards={detailData} setAwards={setDetailData} setShow={setShow} /> :
                <>
                    <div className="items-center xs:mt-1 sm:mt-1 flex justify-between mb-3">
                        <div className="flex">
                            <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
                                <DebounceSearchInput
                                    setState={setGlobalSearch}
                                    debounce={800}
                                >
                                    {(setState) => (
                                        <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                                                onChange={(e) => setState(e.target.value)}
                                            />
                                            <SearchIcon className="mr-2" />
                                        </div>
                                    )}
                                </DebounceSearchInput>
                            </div>
                        </div>
                        <Button
                            label="+ Add New"
                            onClick={() => { clear_data(); setShow(false) }}
                            type="button"
                            variant="primary"
                            customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                            size="base"
                        />
                    </div>
                    <div className="flex sm:flex-row flex-col justify-end mb-1">
                        <div className="flex items-center sm:justify-start justify-end">
                            <div className="flex items-center 3xl:ml-3 mr-[10px]">
                                <p className="xl:text-sm text-12 mr-2 text-black2 font-primary">
                                    DISPLAY
                                </p>
                                <NumberDropdownList
                                    state={pagination.pageSize}
                                    setState={value =>
                                        setPagination(prev => ({
                                            ...prev,
                                            pageSize: value as number
                                        }))}
                                    dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                                        const count = (index + 1) * 20;
                                        return {
                                            value: count,
                                            label: String(count)
                                        };
                                    })}
                                    className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                                    textAlign="text-left pl-3"
                                    paddingClass="py-[2px]"
                                    placeholder="All"
                                />
                            </div>
                        </div>
                    </div>
                    <CertificateTable
                        // columnsData={columnsData}
                        isLayoutControlOpen={isLayoutControlOpen}
                        setIsLayoutControlOpen={setIsLayoutControlOpen}
                        globalSearch={globalSearch}
                        setGlobalSearch={setGlobalSearch}
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}
                        pagination={pagination}
                        data={data}
                        setData={setData}
                        setPagination={setPagination}
                        hiddenFields={hiddenFields ? hiddenFields : []}
                        setHiddenFields={setHiddenFields}
                        checkManagement={true}
                        total={itemCount}
                        localModuleId={localModuleId}
                        // visArray={visArray}
                        templateList={tempList}
                        setCurrentView={() => 1}
                        setCols={setCols}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setEditRowData={setEditRowData}
                        editRowData={editRowData}
                        setShowList={setShow}
                        setDetailData={setDetailData}
                        sorting={sorting}
                        setSorting={setSorting}
                    />
                </>
            }
        </div>
    </div>
}
export default AwardAndCertificateList;