import { useEffect, useRef, useState } from "react";
import SeoData from "../../../components/SeoData/SeoData";
import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../../components/common/CommonLanguage";
import { CancelIcon, SaveIcon } from "../../../components/common/Icons";
import classNames from "classnames";
import BannerSingle, { IBannerData } from "../../../components/SeoData/BannerSingle";
import CompanyProfileSection, { ICompanyProfile, ICompanyProfileData } from "../CompanyProfileSection";
import { ICompanyHistory } from "../CompanyHistorySection";
import { IMission } from "../CompanyMissionInput";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../../axios";
import { endpointUrl } from "../../../app/services/api";
import ErrorPopup from "../../../components/ErrorPopup/ErrorPopup";
import { useGetAirlinesDataQuery, useGetAirlinesQuery } from "../../../app/services/company/airline";
import UndoChanges from "../../../components/UndoChanges/UndoChanges";

const CustomerAirlines = () => {
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [metaTitle_en, setMetaTitleEn] = useState<string>("");
    const [metaTitle_zh, setMetaTitleZh] = useState<string>("");
    const [metaTitle_cn, setMetaTitleCn] = useState<string>("");
    const [metaDescription_en, setMetaDescriptionEn] = useState<string>("");
    const [metaDescription_zh, setMetaDescriptionZh] = useState<string>("");
    const [metaDescription_cn, setMetaDescriptionCn] = useState<string>("");
    const [metaImage, setMetaImage] = useState<string>("");
    const [metaImageFile, setMetaImageFile] = useState<File | undefined>();
    const [banner, setBanner] = useState<IBannerData | undefined>();
    const [company_profile, setCompanyProfile] = useState<ICompanyProfile | undefined>();
    const [company_history, setCompanyHistory] = useState<ICompanyHistory | undefined>();
    const [mission, setMission] = useState<IMission | undefined>();
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const [activeLang, setActiveLang] = useState<string>("en");
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    const {data:airline}=useGetAirlinesDataQuery();
    const [homeData, setHomeData] = useState<any>();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        setHomeData((prevState: any) => ({
            ...prevState,
            [target.name]: target.value
        }));
    };
    const set_initial_value=()=>{
        if(airline){
            const overview=airline.airline_page;             
            const airline_section=airline.airline_section;
           if(overview){
               setMetaTitleEn(overview.meta_title_en);
               setMetaTitleZh(overview.meta_title_tc);
               setMetaTitleCn(overview.meta_title_sc);
               setMetaDescriptionEn(overview.meta_description_en);
               setMetaDescriptionZh(overview.meta_description_tc);
               setMetaDescriptionCn(overview.meta_description_sc);
               setMetaImage(overview.meta_image);
               setBanner({
                   title_en: overview.title_en,
                   title_tc: overview.title_tc,
                   title_sc: overview.title_sc,
                   image_url: overview.banner_desktop_image,
                   image: (overview.banner_desktop_image as any)
               });
           }
           if(airline_section){
               setCompanyProfile({
                   title_en:airline_section.title_en,
                   title_tc:airline_section.title_tc,
                   title_sc:airline_section.title_sc,
                   subtitle_en:airline_section.sub_title_en,
                   subtitle_tc:airline_section.sub_title_tc,
                   subtitle_sc:airline_section.sub_title_sc,
                   description_en:airline_section.description_en,
                   description_tc:airline_section.description_tc,
                   description_sc:airline_section.description_sc,
                   image:undefined,
                   image_url:airline_section.image
               })
           }
       }
    }
    useEffect(()=>{
       set_initial_value();
    },[airline])
    const handle_submit=async()=>{
        const formData = new FormData();
        formData.append('meta_title_en', metaTitle_en ?? '');
        formData.append('meta_title_tc', metaTitle_zh ?? '');
        formData.append('meta_title_sc', metaTitle_cn ?? '');
        formData.append('meta_description_en', metaDescription_en ?? '');
        formData.append('meta_description_tc', metaDescription_zh ?? '');
        formData.append('meta_description_sc', metaDescription_cn ?? '');
        formData.append('meta_image', metaImageFile as any);
        formData.append('page_title_en', banner?.title_en ?? '');
        formData.append('page_title_tc', banner?.title_tc ?? '');
        formData.append('page_title_sc', banner?.title_sc ?? '');
        formData.append('banner_desktop_image', banner?.image ?? '');
        formData.append('airline_title_en', company_profile?.title_en ?? '');
        formData.append('airline_title_tc', company_profile?.title_tc ?? '');
        formData.append('airline_title_sc', company_profile?.title_sc ?? '');
        formData.append('airline_sub_title_en', company_profile?.subtitle_en ?? '');
        formData.append('airline_sub_title_tc', company_profile?.subtitle_tc ?? '');
        formData.append('airline_sub_title_sc', company_profile?.subtitle_sc ?? '');
        formData.append('airline_description_en', company_profile?.description_en ?? '');
        formData.append('airline_description_tc', company_profile?.description_tc ?? '');
        formData.append('airline_description_sc', company_profile?.description_sc ?? '');
        if(company_profile?.image){
            formData.append('airline_image', company_profile?.image);
        }
        await axios(endpointUrl + "update-customer-airline-page", {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
            .then((res: any) => {
                if (res?.data?.status == true) {
                    toast(res?.data?.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        type: "success",
                    });
                } else {
                    setErrorPopup(true);
                    setMessage(res?.data?.message);
                }
                setIsSave(false);
            })
            .catch((err) => {
                setErrorPopup(true);
                setMessage(Object.values(err?.response?.data?.errors)?.join(' '));
            });
    }
    const undoChanges=()=>{
        set_initial_value();
        setIsCancel(false);
      }
    return (
        <div
            className={twJoin(
                "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
            )}
        >
            <ToastContainer className={"text-12 font-poppins font-normal"} />
            <ErrorPopup
                descText="Error Message!"
                confirmBtnText="Yes, Close."
                show={errorPopup}
                setShow={setErrorPopup}
                message={message}
                setMessage={setMessage}
            />           
            <UndoChanges setShow={setIsCancel} show={isCancel} undoChange={()=>undoChanges()}/>   
            <div className="text-left">
                <h2 className="text-lg text-left font-bold mb-5 mt-5 text-black">
                    Overview
                </h2>
                <div className="mt-5">
                    <CommonLanguage
                        changeLanguage={changeLanguage}
                        activeLang={activeLang}
                    />
                    <CompanyProfileSection company_profile={company_profile} setCompanyProfile={setCompanyProfile} activeLang={activeLang} setActiveLang={setActiveLang} />
                </div>
            </div>
            <div className="flex">
                <div className="text-left w-1/2 mr-5">
                    <SeoData
                        showPageTitle={false}
                        setPageTitleEn={() => 1}
                        setPageTitleZh={() => 1}
                        setPageTitleCn={() => 1}
                        metatitle_en={metaTitle_en}
                        metatitle_zh={metaTitle_zh}
                        metatitle_cn={metaTitle_cn}
                        setMetaTitleEn={setMetaTitleEn}
                        setMetaTitleZh={setMetaTitleZh}
                        setMetaTitleCn={setMetaTitleCn}
                        metadescription_en={metaDescription_en}
                        metadescription_zh={metaDescription_zh}
                        metadescription_cn={metaDescription_cn}
                        setMetaDescriptionEn={setMetaDescriptionEn}
                        setMetaDescriptionZh={setMetaDescriptionZh}
                        setMetaDescriptionCn={setMetaDescriptionCn}
                        meta_image={metaImage}
                        setMetaImage={setMetaImage}
                        setMetaImageFile={setMetaImageFile}

                    />

                </div>
                <div className="w-1/2 text-left">
                    <BannerSingle banner={banner} setBanner={setBanner} />
                </div>
            </div>
            <div className="mt-5 last:mb-0 flex items-center">
                <button
                    className={classNames(
                        // saving ? "opacity-50 pointer-events-none" : "",
                        "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                    )}
                    onClick={() => handle_submit()}
                >
                    <SaveIcon className="mr-2" /> Save
                </button>
                <button
                    className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                    onClick={() => {
                        setIsCancel(true);
                    }}
                >
                    <CancelIcon className="mr-2" color="#9E3039" />
                    Cancel
                </button>
            </div>
        </div>
    );
};
export default CustomerAirlines;
