import { makeStyles } from "@mui/material";
import {
  ColumnDef,
  ColumnFiltersState,
  createColumnHelper,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EditIcon, TrashIcon } from "../../../components/common/Icons";
import LayoutAdjustableTable from "../../../components/Tables/LayoutAdjustableTable";
import TablePaginator from "../../../components/Tables/TablePaginator";
import DeletePopup from "../../../components/DeletePopup/DeletePopup";
import ErrorPopup from "../../../components/ErrorPopup/ErrorPopup";
import { toast, ToastContainer } from "react-toastify";
import { baseObj } from "../../../utils/constants";
import styles from "./SectionTable.module.scss";
import { useDeleteHomeServiceMutation } from "../../../app/services/home/services";
import { useDeleteJobcategoryMutation } from "../../../app/services/job/jobCategory";
export type RowData = { [key: string]: any };

export const tagsColor = ["#FFF0CD", "#E1E9FF", "#E5F6FF"];

const SectionTable = (props: any) => {
  const [isNewAnModalOpen, setIsNewAnModalOpen] = useState<boolean>(false);
  const columnHelper = useMemo(() => createColumnHelper<any>(), []); // TODO add type definitions when api binding
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const selectRowsCount = Object.keys(rowSelection).length;
  const [data, setData] = useState<RowData[]>([]);
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [multipleDelete, setMultipleDelete] = useState<boolean>(false);
  const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  const [selectedUser, setSelectedUser] = useState<number>(0);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<"assign" | "remove">("assign");
  const [quotationModalState, setQuotationModalState] =
    useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [deleteService] = useDeleteJobcategoryMutation();

  const [editRowData, setEditRowData] = useState<any>();
  const [itemCount, setItemCount] = useState<number>(data?.length);
  const [deleting, setDeleting] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setData(props.data);
    }
  }, [props]);

  const editRow = useCallback(
    (rowData?: any) => {
      props.setDetailData(rowData);
      props.setShowList(false);
      setEditRowData(rowData?.id);
    },
    [editRowData]
  );
  const deleteItem = useCallback(
    (id?: number, dataId?: number) => {
      const selectedRows = Object.keys(rowSelection);      
      if (dataId) {
        deleteService(dataId)
          .unwrap()
          .then((payload: any) => {
            if (payload.status) {
              setOpenDeleteopup(false);
              setSuccessPopup(true);
              setMessage([payload?.message]);
              toast("Successfully Deleted.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                type: "success",
                theme: "light",
              });
              setDeleting(false);
              setData((prev: any) => {
                return prev.filter((x: any) => x.id != dataId);
              });
            }
          })
          .catch((error: any) => {
            setOpenDeleteopup(true);
            setErrorPopup(true);
            setDeleting(false);
            setMessage(
              error ? [error?.data?.message] : ["Something went wrong!"]
            );
          });
      }
    },
    [setData, rowSelection]
  );
  const deleteUsersHandler = useCallback(() => {
    const selectedRows = Object.keys(rowSelection);
    var selectionIds = data
      .filter((x: any, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x : 0;
      })
      .map((x: any) => x.id);

    var $data: any = {
      ids: selectionIds,
    };
  }, [setData, rowSelection]);
  const deleteRow = useCallback(
    (id?: number, dataId?: number) => {
      setDeleteRowID(id);
      setDeleteID(dataId);
      setMultipleDelete(false);
      setOpenDeleteopup(true);
    },
    [setData, rowSelection]
  );

  const confirmDeleteData = () => {
    setDeleting(true);
    let id = deleteRowID;
    var realId = deleteID;

    if (multipleDelete) {
      deleteUsersHandler();
    } else {
      deleteItem(id, realId);
      setDeleteID(-1);
    }
  };

  const MultideleteHandler = () => {
    setOpenDeleteopup(true);
    setMultipleDelete(true);
  };
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        header: "ID",
        id: "id",
        cell: ({ getValue }) => {
          const noticeCode = getValue();
          return (
            <div className=" ">
              <p className="h-full flex items-center">{noticeCode}</p>
            </div>
          );
        },
      }),

      columnHelper.accessor("title_en", {
        header: "Title (EN)",
        id: "title_en",
        cell: ({ getValue }) => {
          const title = getValue();
          return (
            <div className=" bg-white text-black2 px-2 flex items-center">
              <p className="h-full flex items-center">{title}</p>
            </div>
          );
        },
      }),
      
      
      columnHelper.display({
        header: "Action",
        id: `${props?.checkManagement ? "actions" : "none-actions"}`,
        cell: ({ cell }) => {
          const isNew: boolean = cell.row.original?.isNew;
          const dataId: number = cell.row.original?.id
            ? cell.row.original?.id
            : 0;
          return (
            <div className="flex justify-center items-center  max-w-[120px] min-w-[100px]">
              <>
                <button
                  type="button"
                  onClick={() => {
                    editRow(cell.row.original);
                  }}
                  className="w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                >
                  <EditIcon />
                </button>
                <button
                  type="button"
                  onClick={() => deleteRow(Number(cell.row.id), dataId)}
                  className="w-6 h-6 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300"
                >
                  <TrashIcon />
                </button>
              </>
            </div>
          );
        },
      }),
    ],
    [columnHelper, props?.data]
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  document.querySelectorAll("th,td").forEach((x) => {
    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }
    x.classList.remove("absolute");
    x.classList.add("left-0");
    x.classList.add("relative");
    var div = document.getElementById(x.id);
    if (div) {
      div.style.setProperty("left", "");
    }
    if (x.id.includes("action")) {
      x.classList.add("absolute");
      x.classList.remove("relative");
      x.classList.add("right-0");
      x.classList.remove("left-0");
    }
  });

  const visibleColumns: { [key: string]: boolean } = {};

  props?.hiddenFields?.map((x: any) => {
    visibleColumns[x] = true;
  });

  if (props?.checkManagement) {
    visibleColumns["actions"] = true;
  } else {
    visibleColumns["actions"] = false;
  }

  if (props?.hiddenFields?.length > 0) {
    columns.map((x: any) => {
      if (x.id == "actions" || x.id == "lasted" || x.id == "selection") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else if (x.id?.includes("none")) {
        visibleColumns[x.id ? x.id : ""] = true;
      } else {
        var isHidden = props?.hiddenFields?.find((h: any) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  useEffect(() => {
    if (data) {
      setData(props.data);
    }
  }, [props]);

  useEffect(() => {
    if (props?.hiddenFields.length > 0) {
      setColumnOrder(["selection", ...props?.hiddenFields]);
    }
  }, [props?.hiddenFields]);

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);

  return (
    <div className={classNames(styles.anouncementsTable, "w-full")}>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <DeletePopup
        descText="Are you sure to delete?"
        loading={deleting}
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
      />

      <div className="text-primary text-left">
        <div
          className={classNames(
            styles.annoucementTable,
            "relative max-w-full w-full"
          )}
        >
          <LayoutAdjustableTable
            // saveTemplateData={saveTemplateData}
            hideColumn={visibleColumns}
            columns={columns}
            data={data}
            sorting={props.sorting}
            setSortingState={props.setSorting}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            unDraggableFields={["actions", "selection"]}
            theaderColor="text-graydark"
            isLayoutControlOpen={props?.isLayoutControlOpen}
            setIsLayoutControlOpen={props?.setIsLayoutControlOpen}
            label="Annoucement Lists"
            setItemCount={setItemCount}
            pagination={props?.pagination}
            setPagination={props?.setPagination}
            globalFilter={props?.globalSearch}
            setGlobalFilter={props?.setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            localModuleId={props?.localModuleId}
            // visArray={props?.visArray}
            setCols={props?.setCols}
            setIsTemplate={props?.setIsTemplate}
          />
        </div>
        {selectRowsCount > 0 && (
          <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-8 mb-10 lg:mt-10 lg:mb-12 ">
            <p>{selectRowsCount} selected.</p>
            {props?.checkManagement ? (
              <button
                type="button"
                onClick={() => MultideleteHandler()}
                className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
              >
                <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-[#FFEAEF]  transition-all duration-300 ">
                  <TrashIcon width={10} height={12} />
                </div>
                Delete
              </button>
            ) : null}
          </div>
        )}
        <div className="pt-6">
          <TablePaginator
            total={props.itemCount}
            pagination={props.pagination}
            setPagination={props.setPagination}
            itemCount={props.total}
            setCurrentPage={props.setCurrentPage}
            currentPageDefault={props.currentPage}
            // changePage={props.changePage}
          />
        </div>
      </div>
    </div>
  );
};

export default SectionTable;
