import { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import classNames from "classnames";
import BannerSingle, {
  IBannerData,
} from "../../components/SeoData/BannerSingle";
import SeoData from "../../components/SeoData/SeoData";
import OverviewSection, { IOverview } from "../components/Overview";
import { useGetCareerDataPageQuery, useGetCaresDataPageQuery } from "../../app/services/job/career";
import { endpointUrl } from "../../app/services/api";
import axios from "../../axios";
import { ToastContainer, toast } from "react-toastify";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
import UndoChanges from "../../components/UndoChanges/UndoChanges";

const CareActivitiesOverview = () => {
    const [metaTitle_en, setMetaTitleEn] = useState<string>("");
    const [metaTitle_zh, setMetaTitleZh] = useState<string>("");
    const [metaTitle_cn, setMetaTitleCn] = useState<string>("");
    const [metaDescription_en, setMetaDescriptionEn] = useState<string>("");
    const [metaDescription_zh, setMetaDescriptionZh] = useState<string>("");
    const [metaDescription_cn, setMetaDescriptionCn] = useState<string>("");
    const [metaImage, setMetaImage] = useState<string>("");
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [overview, setOverview] = useState<IOverview>({
      sub_title_en:'',
      sub_title_tc:'',
      sub_title_sc:'',    
      title_en:'',
      title_tc:'',
      title_sc: '',  
      description_en:'',
      description_tc:'',
      description_sc: '',   
      passenger_service_title_en:'',
      passenger_service_title_tc:'',
      passenger_service_title_sc: '',   
      passenger_service_text_en:'',
      passenger_service_text_tc:'',
      passenger_service_text_sc: '',   
      ramp_service_title_en:'',
      ramp_service_title_tc:'',
      ramp_service_title_sc: '',   
      ramp_service_text_en:'',
      ramp_service_text_tc:'',
      ramp_service_text_sc: '',   
      passenger_service_image: undefined,
      passenger_service_image_url:'',
      ramp_service_image: undefined,
      ramp_service_image_url:'',
    });

    const [metaImageFile, setMetaImageFile] = useState<File | undefined>();
    const [banner, setBanner] = useState<IBannerData | undefined>();
    const [activeLang, setActiveLang] = useState<string>("en");
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    const {data:career}=useGetCaresDataPageQuery('staff-activities');
    const [homeData, setHomeData] = useState<any>();
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        setHomeData((prevState: any) => ({
            ...prevState,
            [target.name]: target.value
        }));
    };
    const set_initial_data=()=>{
      if (career) {
        const page_data = career.sats_care_page;
        const overview=career.sats_care_page;
        if (overview) {
            setMetaTitleEn(page_data.meta_title_en);
            setMetaTitleZh(page_data.meta_title_tc);
            setMetaTitleCn(page_data.meta_title_sc);
            setMetaDescriptionEn(page_data.meta_description_en);
            setMetaDescriptionZh(page_data.meta_description_tc);
            setMetaDescriptionCn(page_data.meta_description_sc);
            setMetaImage(page_data.meta_image);
            setBanner({
                title_en: page_data.title_en,
                title_tc: page_data.title_tc,
                title_sc: page_data.title_sc,
                image_url: page_data.banner_desktop_image,
                image: (page_data.banner_desktop_image as any)
            });
        }
        // setOverview({
        //   title_en:overview?.title_en,
        //   title_tc:overview?.title_tc,
        //   title_sc:overview?.title_sc,
        // })
    }
    }
    useEffect(() => {
     set_initial_data();
  }, [career]);
  const handle_submit = async () => {
      const formData = new FormData();
      formData.append('meta_title_en', metaTitle_en ?? '');
      formData.append('meta_title_tc', metaTitle_zh ?? '');
      formData.append('meta_title_sc', metaTitle_cn ?? '');
      formData.append('meta_description_en', metaDescription_en ?? '');
      formData.append('meta_description_tc', metaDescription_zh ?? '');
      formData.append('meta_description_sc', metaDescription_cn ?? '');
      formData.append('meta_image', metaImageFile as any);

      formData.append('page_title_en', banner?.title_en ?? '');
      formData.append('page_title_tc', banner?.title_tc ?? '');
      formData.append('page_title_sc', banner?.title_sc ?? '');
      if(banner?.image)
      formData.append('banner_desktop_image', banner?.image ?? '');
      formData.append('page_type', 'staff-activities');
      if(overview?.passenger_service_image)
      formData.append('service_info_image', overview?.passenger_service_image ?? '');
      if(overview?.ramp_service_image )
      formData.append('ramp_info_image', overview?.ramp_service_image ?? '');
      await axios(endpointUrl + "update-sats-care-page", {
          method: "POST",
          headers: {
              "Content-Type": "multipart/form-data",
          },
          data: formData,
      })
          .then((res: any) => {
              if (res?.data?.status == true) {
                  toast(res?.data?.message, {
                      position: "top-right",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      type: "success",
                  });
              } else {
                  setErrorPopup(true);
                  setMessage(res?.data?.message);
              }
              setIsSave(false);
          })
          .catch((err) => {
              setErrorPopup(true);
              setMessage(Object.values(err?.response?.data?.errors)?.join(' '));
          });
  }
  const undoChanges=()=>{
    set_initial_data();
    setIsCancel(false);
  }
    return (
      <div
        className={twJoin(
          "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
        )}
      >
         <ToastContainer className={"text-12 font-poppins font-normal"} />
      <CommonErrorPopup
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
        <UndoChanges setShow={setIsCancel} show={isCancel} undoChange={()=>undoChanges()}/> 
        <div className="flex">
          <div className="text-left w-1/2 mr-5">
            <SeoData
              showPageTitle={false}
              setPageTitleEn={() => 1}
              setPageTitleZh={() => 1}
              setPageTitleCn={() => 1}
              metatitle_en={metaTitle_en}
              metatitle_zh={metaTitle_zh}
              metatitle_cn={metaTitle_cn}
              setMetaTitleEn={setMetaTitleEn}
              setMetaTitleZh={setMetaTitleZh}
              setMetaTitleCn={setMetaTitleCn}
              metadescription_en={metaDescription_en}
              metadescription_zh={metaDescription_zh}
              metadescription_cn={metaDescription_cn}
              setMetaDescriptionEn={setMetaDescriptionEn}
              setMetaDescriptionZh={setMetaDescriptionZh}
              setMetaDescriptionCn={setMetaDescriptionCn}
              meta_image={metaImage}
              setMetaImage={setMetaImage}
              setMetaImageFile={setMetaImageFile}
            />
          </div>
          <div className="w-1/2 text-left">
            <BannerSingle banner={banner} setBanner={setBanner} />
          </div>
        </div>

        <div className="mt-5 last:mb-0 flex items-center">
          <button
            className={classNames(
               isSave ? "opacity-50 pointer-events-none" : "",
              "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
            )}
            onClick={() => handle_submit()}
          >
            <SaveIcon className="mr-2" /> Save
          </button>
          <button
            className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
            onClick={() => setIsCancel(true)}
          >
            <CancelIcon className="mr-2" color="#9E3039" />
            Cancel
          </button>
        </div>
      </div>
    );
};
export default CareActivitiesOverview;
