import { redirectToLogin } from "../annoucement-sheet";
import { api } from "../api";
import authHeader from "../auth-header";
export interface IMediaData {
    "service_page": IMediaDataInfo,
    "page_service_section":any;
}
export interface IMediaDataInfo {
    "id": number,
    "sub_menu_id": number,
    "menu_id": number,
    "slug": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "meta_title_en": string,
    "meta_title_tc": string,
    "meta_title_sc": string,
    "meta_description_en": string,
    "meta_description_tc": string,
    "meta_description_sc": string,
    "meta_image": string,
    "banner_mobile_image": string,
    "banner_desktop_image": string,
}

export type TData = {
    data: [];
    total: number;
};
export type PaginationData = {
    page: number;
    limit: number | string | undefined;
    search: string | number;
    sort?: string;
    type?: string;
};
export const mediaResourceApi = api.injectEndpoints({
    endpoints: (build) => ({
        getMediasResourceData: build.query<IMediaData, void>({
            query: (page_type) => ({
                url: `update-service-page`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "MediaData" as const, id: "LIST" }]
        }),
        getMediasResourceList: build.query<TData, PaginationData>({
            query: ({ limit, page, search,type,sort }) => ({
                url: `media-resources-content?per_page=${limit}&page=${page}&search=${search}&type=${type}&sort=${sort}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "MediaData" as const, id: "LIST" }]
        }),
        getMediasResourceInfo: build.mutation<TData, PaginationData>({
            query({ limit, page, search, type,sort }) {
                return {
                    headers: authHeader(),
                    url: `media-resources-content?type=${type}&per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['MediaData']
        }),
        getDownloadItemsList: build.query<TData, PaginationData>({
            query: ({ limit, page, search,type,sort }) => ({
                url: `media-download-items?per_page=${limit}&page=${page}&search=${search}&type=${type}&sort=${sort}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "MediaData" as const, id: "LIST" }]
        }),
        getDownloadItemsInfo: build.mutation<TData, PaginationData>({
            query({ limit, page, search, type, sort }) {
                return {
                    headers: authHeader(),
                    url: `media-download-items?type=${type}&per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['MediaData']
        }),
        deleteMediaResource: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `delete-media-resource-content`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['MediaData']
        }),
        deleteDownloadItem: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `delete-media-download-items`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['MediaData']
        }),
        getLatestImageList: build.query<TData, PaginationData>({
            query: ({ limit, page, search,type,sort }) => ({
                url: `media-files/fetch?per_page=${limit}&page=${page}&search=${search}&type=${type}&sort=${sort}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "MediaData" as const, id: "LIST" }]
        }),
        getLatestImageListInfo: build.mutation<TData, PaginationData>({
            query({ limit, page, search, type, sort }) {
                return {
                    headers: authHeader(),
                    url: `media-files/fetch?per_page=${limit}&page=${page}&search=${search}&type=${type}&sort=${sort}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['MediaData']
        }),
        deleteImageFile: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `media-files/delete`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['MediaData']
        }),
    })
});

export const { useGetMediasResourceDataQuery,useGetMediasResourceListQuery,useGetMediasResourceInfoMutation,
    useDeleteMediaResourceMutation,useGetDownloadItemsListQuery,useGetDownloadItemsInfoMutation,useDeleteDownloadItemMutation,
useGetLatestImageListQuery,useGetLatestImageListInfoMutation,useDeleteImageFileMutation } = mediaResourceApi;

export const {
    endpoints: { getMediasResourceData,getMediasResourceList,getMediasResourceInfo,deleteMediaResource,getDownloadItemsList
        ,getDownloadItemsInfo,deleteDownloadItem,getLatestImageList,getLatestImageListInfo,deleteImageFile }
} = mediaResourceApi;
