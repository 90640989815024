import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import Services from "../components/Services";
import { useState } from "react";
import ServiceSectionList from "./Services/ServiceSectionList";
import WhyChooseUsList from "./WhyChooseUs/WhyChooseUsSectionList";
import WhyChooseUs from "../components/WhyChooseUs";
export interface WhyChooseUsInfo {
  id: number;
  title_en: string;
  image: string;
  icon: string;
  title_tc: string;
  title_sc: string;
  card_title_en: string;
  card_title_tc: string;
  card_title_sc: string;
  card_description_en: string;
  card_description_tc: string;
  card_description_sc: string;
}
const WhyChooseUsSection = () => {
  const [activeLang, setActiveLang] = useState<string>("en");
  const [whyChooseUsData, setWhyChooseData] = useState<WhyChooseUsInfo>({
    id:0,
    title_en:'',
    title_tc:'',
    title_sc:'',
    card_description_en:'',
    card_description_tc:'',
    card_description_sc:'',
    card_title_en:'',
    card_title_tc:'',
    card_title_sc:'',
    image:'',
    icon:'',
  });
  const [showList, setShowList] = useState<boolean>(true);
  
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  return (
    <div
      className={twJoin(
        "text-left bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pb-8 sm:pb-9 "
      )}
    >
      {showList == true
        ? <WhyChooseUsList setShowList={setShowList} setWhyChooseData={setWhyChooseData}/>
        : <div>
            <CommonLanguage
              changeLanguage={changeLanguage}
              activeLang={activeLang}
            />
            <WhyChooseUs
              activeLang={activeLang}
              setActiveLang={setActiveLang}
              initialData={whyChooseUsData}
              setValue={setWhyChooseData}
              setShowList={setShowList}
            />
          </div>}
    </div>
  );
};
export default WhyChooseUsSection;
