import {
  ClassicEditor,
  Context,
  InlineEditor,
  ContextWatchdog,
  Autoformat,
  Bold,
  Italic,
  BlockQuote,
  Base64UploadAdapter,
  CKFinder,
  CKFinderUploadAdapter,
  CloudServices,
  EditorConfig,
  Essentials,
  Heading,
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  PictureEditing,
  Indent,
  Link,
  List,
  Mention,
  Paragraph,
  PasteFromOffice,
  Table,
  TableToolbar,
  TextTransformation
} from "ckeditor5";

import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";

import "ckeditor5/ckeditor5.css";
import { FC } from "react";
interface ILabelAndEditor {
  refId?: any;
  label: string;
  value: string|undefined;
  setValue: (value: React.SetStateAction<string>) => void;
  isRequired?:boolean;
}
const LabelAndTextbox: FC<ILabelAndEditor> = ({
  refId,
  label,
  value,
  setValue,isRequired
}) => {
  const handleEditorChange = (event: any, editor: any) => {
    setValue(editor.getData());
  };
  return (
    <div className="mb-5 last:mb-0">
      <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
      {label}{isRequired==true?<span className="text-14 text-red-500">*</span>:''}
      </label>
      <input
        type="text"
        name="textbox"
        value={value}
        onChange={event => setValue(event.target.value)}
        placeholder=""
        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
      />
    </div>
  );
};
export default LabelAndTextbox;
