import { FC, useEffect, useState } from "react";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { ILeaderShipMember } from "./LeadershipPage";
import LabelAndNumber from "../../components/SeoData/LabelAndNumber";
export interface ILeadershipInput{
    subtitle_en:string;
    subtitle_tc:string;
    subtitle_sc:string;
    title_en:string;
    title_tc:string;
    title_sc:string;
    id:number;
    order:number;
}
interface ILeaderShip{
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    info:ILeaderShipMember;
    setInfo:React.Dispatch<React.SetStateAction<ILeaderShipMember>>;
}
const LeadershipInput:FC<ILeaderShip>=({
    setShow,
    info,
    setInfo
})=>{
    const [data, setData] = useState<ILeadershipInput | undefined>();
    const [activeLang, setActiveLang] = useState<string>("en");
    const change_data = (value: any, key: string) => {
        if (info) {
            setInfo({ ...info, [key]: value });
        }
    }
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(()=>{
        if(info){            
            setData({
                id:info.id,
                title_en:info.title_en,
                title_tc:info.title_tc,
                title_sc:info.title_sc,
                subtitle_en:info.subtitle_en,
                subtitle_tc:info.subtitle_tc,
                subtitle_sc:info.subtitle_sc,
                order:info.order
            });
        }
    },[info])
    return <div>
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox label="Subtitle (EN)" value={data?.subtitle_en} setValue={(value) => change_data(value, 'subtitle_en')} />
                <div className="mt-5">
                    <LabelAndTextbox label="Title (EN)" value={data?.title_en} setValue={(value) => change_data(value, 'title_en')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
               <LabelAndTextbox label="Subtitle (TC)" value={data?.subtitle_tc} setValue={(value) => change_data(value, 'subtitle_tc')} />
                <div className="mt-5">
                    <LabelAndTextbox label="Title (TC)" value={data?.title_tc} setValue={(value) => change_data(value, 'title_tc')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox label="Subtitle (SC)" value={data?.subtitle_sc} setValue={(value) => change_data(value, 'subtitle_sc')} />
                <div className="mt-5">
                    <LabelAndTextbox label="Title (SC)" value={data?.title_sc} setValue={(value) => change_data(value, 'title_sc')} />
                </div>
            </div>
            
        </div>
        
    </div>
}
export default LeadershipInput;