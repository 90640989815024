import { ReactElement, useState } from "react";
import BasicLayout from "../../layouts/BasicLayout";
import Privacy from "./Privacy";
import BaggagePage from "./BaggagePage";

const BaggageRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  return (
    <BasicLayout userData={data} permissions={permissions}>
       <BaggagePage/>
    </BasicLayout>
  );
};

export default BaggageRoute;
