import { FC, useEffect, useRef, useState } from "react";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
export interface IFooterContactUsInputData{
    contact_us:IFooterContactUsInput|undefined;
    setContactUs: React.Dispatch<React.SetStateAction<IFooterContactUsInput>>
}
export interface IFooterContactUsInput{
    location_en:string|undefined;
    location_tc:string|undefined;
    location_sc:string|undefined;
    phone_en:string|undefined;
    phone_tc:string|undefined;
    phone_sc:string|undefined;
    email_en:string|undefined;
    email_tc:string|undefined;
    email_sc:string|undefined;
    copyright_en:string|undefined;
    copyright_tc:string|undefined;
    copyright_sc:string|undefined;
}
const FooterContactUs:FC<IFooterContactUsInputData> = ({
    contact_us,
    setContactUs
}) => {
    const [activeLang, setActiveLang] = useState<string>("en");
    const [data, setData] = useState<IFooterContactUsInput|undefined>(contact_us);
    
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    const handle_change=(value:any,key:string)=>{
        if(data){
            setData({...data,[key]:value})
        }
        if(contact_us){
            setContactUs({...contact_us,[key]:value});
        }
    }
    const locationRef_en: any = useRef(null);
    const locationRef_zh: any = useRef(null);
    const locationRef_cn: any = useRef(null);
    useEffect(()=>{
        setData(contact_us);
    },[contact_us])
    return <div>
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndEditor label="Location (EN)" refId={locationRef_en} setValue={(value)=>handle_change(value,'location_en')} value={data?.location_en}/>
                <div className="mt-5">
                <LabelAndTextbox label="Phone (EN)" setValue={(value)=>handle_change(value,'phone_en')} value={data?.phone_en}/>
                </div>
                <div className="mt-5">
                <LabelAndTextbox label="Email (EN)" setValue={(value)=>handle_change(value,'email_en')} value={data?.email_en}/>
                </div>
                <div className="mt-5">
                <LabelAndTextbox label="Copyright Text (EN)" setValue={(value)=>handle_change(value,'copyright_en')} value={data?.copyright_en} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndEditor label="Location (TC)" refId={locationRef_zh} setValue={(value)=>handle_change(value,'location_tc')} value={data?.location_tc}/>
                <div className="mt-5">
                <LabelAndTextbox label="Phone (TC)" setValue={(value)=>handle_change(value,'phone_tc')} value={data?.phone_tc}/>
                </div>
                <div className="mt-5">
                <LabelAndTextbox label="Email (TC)" setValue={(value)=>handle_change(value,'email_tc')} value={data?.email_tc}/>
                </div>
                <div className="mt-5">
                <LabelAndTextbox label="Copyright Text (TC)" setValue={(value)=>handle_change(value,'copyright_tc')} value={data?.copyright_tc} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndEditor label="Location" refId={locationRef_cn} setValue={(value)=>handle_change(value,'location_sc')} value={data?.location_sc}/>
                <div className="mt-5">
                <LabelAndTextbox label="Phone (SC)" setValue={(value)=>handle_change(value,'phone_sc')} value={data?.phone_sc}/>
                </div>
                <div className="mt-5">
                <LabelAndTextbox label="Email (SC)" setValue={(value)=>handle_change(value,'email_sc')} value={data?.email_sc}/>
                </div>
                <div className="mt-5">
                <LabelAndTextbox label="Copyright Text (SC)" setValue={(value)=>handle_change(value,'copyright_sc')} value={data?.copyright_sc} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
            </div>
        </div>
    </div>
}
export default FooterContactUs; 