import { redirectToLogin } from "../annoucement-sheet";
import { api } from "../api";
import authHeader from "../auth-header";
export interface ICompanyData {
    "our_story": IOurStory,
    "overview": ICompanyOverview,
    "ourJourney": IOurJourney
}
export interface ICompanyOverview {
    "id": number,
    "page_id": number,
    "section_order": number,
    "unique_name": string,
    "sub_title_en": string,
    "sub_title_tc": string,
    "sub_title_sc": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "description_en": string,
    "description_tc": string,
    "description_sc": string,
    "image": string,
    "additional_data_en": null,
    "additional_data_tc": null,
    "additional_data_sc": null,
    name_en: string;
    name_tc: string;
    name_sc: string;
    position_en: string;
    position_tc: string;
    position_sc: string;
    check_text_en: string;
    check_text_tc: string;
    check_text_sc: string;
    phone: string;
    email: string;
}
export interface IOurStory {
    "id": number,
    "sub_menu_id": number,
    "menu_id": number,
    "slug": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "meta_title_en": string,
    "meta_title_tc": string,
    "meta_title_sc": string,
    "meta_description_en": string,
    "meta_description_tc": string,
    "meta_description_sc": string,
    "meta_image": string,
    "banner_mobile_image": string,
    "banner_desktop_image": string,
    "is_slider": number,
}
export interface IOurJourney {
    "id": number,
    "page_id": number,
    "section_order": number,
    "sub_title_en": string,
    "sub_title_tc": string,
    "sub_title_sc": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "description_en": string,
    "description_tc": string,
    "description_sc": string,
    "image": string,
    "additional_data_en": string,
    "additional_data_tc": string,
    "additional_data_sc": string,
}
export interface ICompanyHistory {

}
export type TData = {
    data: ICompanyHistory[];
    total: number;
};
export type PaginationData = {
    page: number;
    limit: number | string | undefined;
    search: string | number;
    sort?: string;
};
export const companyApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCompanyData: build.query<ICompanyData, void>({
            query: () => ({
                url: `fetch-our-story-page`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "CompanyHistory" as const, id: "LIST" }]
        }),
        getHistory: build.query<TData, PaginationData>({
            query: ({ limit, page, search,sort }) => ({
                url: `our-journey-content?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "CompanyHistory" as const, id: "LIST" }]
        }),
        getCompanyInfoData: build.mutation<ICompanyData, PaginationData>({
            query({limit, page, search,sort}) {
                return {
                    headers: authHeader(),
                    url: `our-journey-content?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['CompanyHistory']
        }),
        deleteCompanyHistory: build.mutation<ICompanyData, number>({
            query(body) {
              return {
                headers: authHeader(),
                url: `delete-our-journey-content`,
                body: {
                  id: body
                },
                method: "POST",
              };
            },
            invalidatesTags: ['CompanyHistory']
          }),
    })
});

export const { useGetCompanyDataQuery, useGetHistoryQuery,useGetCompanyInfoDataMutation,useDeleteCompanyHistoryMutation } = companyApi;

export const {
    endpoints: { getCompanyData, getHistory,getCompanyInfoData,deleteCompanyHistory }
} = companyApi;
