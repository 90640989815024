import { redirectToLogin } from "../annoucement-sheet";
import { api } from "../api";
import authHeader from "../auth-header";
export interface IVisionMissionInfo {
    "award_page": IAwardInfo,
    
}
export interface IAwardInfo {
    "id": number,
    "sub_menu_id": number,
    "menu_id": number,
    "slug": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "meta_title_en": string,
    "meta_title_tc": string,
    "meta_title_sc": string,
    "meta_description_en": string,
    "meta_description_tc": string,
    "meta_description_sc": string,
    "meta_image": string,
    "banner_mobile_image": string,
    "banner_desktop_image": string,
}

export type TData = {
    data: [];
    total: number;
};
export type PaginationData = {
    page: number;
    limit: number | string | undefined;
    search: string | number;
    sort?: string;
    type?: string;
};
export const awardApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCerts: build.query<IVisionMissionInfo, void>({
            query: () => ({
                url: `fetch-award-page`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Awards" as const, id: "LIST" }]
        }),
        getCertsList: build.query<TData, PaginationData>({
            query: ({ limit, page, search, sort }) => ({
                url: `certification-content?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Awards" as const, id: "LIST" }]
        }),
        getCertsfo: build.mutation<TData, PaginationData>({
            query({ limit, page, search, type, sort }) {
                return {
                    headers: authHeader(),
                    url: `certification-content?type=${type}&per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['Awards']
        }),
        getAwards: build.query<IVisionMissionInfo, void>({
            query: () => ({
                url: `fetch-award-page`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Awards" as const, id: "LIST" }]
        }),
        getAwardsList: build.query<TData, PaginationData>({
            query: ({ limit, page, search,sort }) => ({
                url: `award-content?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Awards" as const, id: "LIST" }]
        }),
        getAwardInfo: build.mutation<TData, PaginationData>({
            query({ limit, page, search, type, sort }) {
                return {
                    headers: authHeader(),
                    url: `certification-content?type=${type}&per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['Awards']
        }),
        deleteAward: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `delete-award-content`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['Awards']
        }),
        deleteCertificate: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `delete-certification-content`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['Awards']
        }),
        fetch_award_category: build.query<TData, PaginationData>({
            query: ({ limit, page, search,type, sort }) => ({
                url: `fetch-award-category?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "AwardCategory" as const, id: "LIST" }]
        }),
        fetch_award_category_info: build.mutation<TData, PaginationData>({
            query({ limit, page, search, type, sort }) {
                return {
                    headers: authHeader(),
                    url: `fetch-award-category?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['JobCategory']
        }),
        deleteAwardcategory: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `delete-award-category`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['JobCategory']
        }),
    })
});

export const { useGetAwardsQuery, useGetAwardsListQuery, useGetAwardInfoMutation, useDeleteAwardMutation,
    useGetCertsListQuery,useGetCertsQuery,useGetCertsfoMutation,
useFetch_award_categoryQuery,useFetch_award_category_infoMutation,useDeleteAwardcategoryMutation,useDeleteCertificateMutation } = awardApi;

export const {
    endpoints: { getAwards, getAwardsList, getAwardInfo, deleteAward,getCertsList,getCerts,getCertsfo,
    fetch_award_category,fetch_award_category_info,deleteAwardcategory,deleteCertificate }
} = awardApi;
