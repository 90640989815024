import { twJoin } from "tailwind-merge";
import FooterSocialMedia, { ISocialMedia } from "./FooterSocialMedia";
import { useEffect, useState } from "react";
import FooterSocialMediaInput from "./FooterSocialMediaInput";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { useGetFooterDataMutation, useGetFooterQuery } from "../../app/services/footer";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { useDeleteFooterSocialMutation } from "../../app/services/home/home";
interface IFooterSocialMedia {
  data: ISocialMedia[];
}
const FooterSocialMediaPage = () => {
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [showMediaCRUD, setShowMediaCRUD] = useState<boolean>(false);
  const [socialMedia, setSocialMedia] = useState<ISocialMedia[]>([]);
  const [socialDetail, setSocialDetail] = useState<ISocialMedia | undefined>({
    id: 0,
    link: '',
    image: undefined,
    image_url: ''
  });
  
  const [getFooterPageData]=useGetFooterDataMutation();
  const handle_submit_social = async (social: ISocialMedia | undefined) => {
    if (social) {
      var formData = new FormData();
      formData.append('link', social.link ?? '');
      formData.append('logo', social.image ?? '');
      formData.append('id', social.id?.toString() ?? '0');
      const url=social?.id?'update-footer-social':'create-footer-social';
      await axios(endpointUrl + url, {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then((res: any) => {
          if (res?.data?.status == true) {
            if (res?.data?.status == true) {
              getFooterPageData({
                search:'',
                page_type:'',
                sort:'footer_social'
              }).then((res:any)=>{
                setSocialMedia(res?.data?.footer_social?.map((social: any) => {
                  return {
                    id: social.id,
                    image_url: social?.logo,
                    link: social?.link,
                    image: undefined
                  }
                }))
              })
              toast(res?.data?.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
              setShowMediaCRUD(false);
            } else {
              setErrorPopup(true);
              setMessage(res?.data?.message);
            }
          }
        })
        .catch((err) => {
          console.log('error ', err)
        });
    }

  };
  
  return <div
    className={twJoin(
      "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
    )}
  >
    <ToastContainer className={"text-12 font-poppins font-normal"} />
    <ErrorPopup
      descText="Error Message!"
      confirmBtnText="Yes, Close."
      show={errorPopup}
      setShow={setErrorPopup}
      message={message}
      setMessage={setMessage}
    />
    <div>
      {/* <h2 className="text-sm text-black font-medium mb-3">Social Media</h2> */}
      {showMediaCRUD == false ?
        <FooterSocialMedia
          social_media={socialMedia}
          setShow={setShowMediaCRUD}
          setDetailData={setSocialDetail}
        /> :
        <FooterSocialMediaInput
          social={socialDetail}
          setShow={setShowMediaCRUD}
          handle_submit={handle_submit_social}
          setSocialDetail={setSocialDetail}
        />}
    </div>
  </div>;
};
export default FooterSocialMediaPage;