import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import LabelAndNumber from "../../components/SeoData/LabelAndNumber";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import CommonLanguage from "../../components/common/CommonLanguage";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";

export interface IBoardOfDirectoryInput {
    director: IBoardOfDirectoryInputFields;
    setDirector: React.Dispatch<React.SetStateAction<IBoardOfDirectoryInputFields>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handle_submit: (data: IBoardOfDirectoryInputFields) => Promise<void>;
    isSave:boolean;
}
export interface IBoardOfDirectoryInputFields {
    id:number;
    order:number;
    image: File | undefined;
    image_url: string;
    name_en: string;
    name_tc: string;
    name_sc: string;
    position_en: string;
    position_tc: string;
    position_sc: string;
}
const BoardOfDirectoryInput: FC<IBoardOfDirectoryInput> = ({
    director,
    setDirector,
    setShow,
    handle_submit,
    isSave
}) => {
    const position_ref_en=useRef();
    const position_ref_tc=useRef();
    const position_ref_sc=useRef();
    const [isRequired, setIsRequired] = useState<boolean>(false);
    const [data, setData] = useState<IBoardOfDirectoryInputFields>(director);
    const [activeLang, setActiveLang] = useState<string>("en");
    const change_data = (value: any, key: string) => {
        if (director) {
            if(key=="image"){
                const url=value?URL.createObjectURL(value):'';
                setDirector((prev)=>{
                    return {
                        ...prev,
                        image:value,
                        image_url:url
                    }
                });
                return;
            }
            setDirector({ ...director, [key]: value });
        }
    }
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(()=>{
        if(director){
            setData({
                name_en:director.name_en,
                name_tc:director.name_tc,
                name_sc:director.name_sc,
                position_en:director.position_en,
                position_tc:director.position_tc,
                position_sc:director.position_sc,
                image:typeof(director.image)=="object"?director.image:undefined,
                image_url:typeof(director.image)=="object"?director.image_url:director.image as any,
                id:director.id,
                order:director.order
            })
        }
    },[director]);
    const check_validation=()=>{
        if(data?.name_en && data?.name_tc && data?.name_sc && data?.position_en && data?.position_tc && data?.position_sc){
          return true;
        }
        return false;
      }
    return <div>
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox isRequired={true} label="Name (EN)" value={data?.name_en} setValue={(value) => change_data(value, 'name_en')} />
                {(isRequired==true && !data?.name_en)?<span className="text-12 text-red-500 font-normal">Name is required.</span>:null}
                <div className="mt-5">
                    <LabelAndEditor refId={position_ref_en} isRequired={true} label="Position (EN)" value={data?.position_en} setValue={(value) => change_data(value, 'position_en')} />
                    {(isRequired==true && !data?.position_en)?<span className="text-12 text-red-500 font-normal">Position is required.</span>:null}
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox  isRequired={true} label="Name (TC)" value={data?.name_tc} setValue={(value) => change_data(value, 'name_tc')} />
                {(isRequired==true && !data?.name_tc)?<span className="text-12 text-red-500 font-normal">Name is required.</span>:null}
                <div className="mt-5">
                <LabelAndEditor refId={position_ref_tc}  isRequired={true} label="Position (TC)" value={data?.position_tc} setValue={(value) => change_data(value, 'position_tc')} />
                    {(isRequired==true && !data?.position_tc)?<span className="text-12 text-red-500 font-normal">Position is required.</span>:null}
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox  isRequired={true} label="Name (SC)" value={data?.name_sc} setValue={(value) => change_data(value, 'name_sc')} />
                {(isRequired==true && !data?.name_sc)?<span className="text-12 text-red-500 font-normal">Name is required.</span>:null}
                <div className="mt-5">
                <LabelAndEditor refId={position_ref_sc}  isRequired={true} label="Position (SC)" value={data?.position_sc} setValue={(value) => change_data(value, 'position_sc')} />
                    {(isRequired==true && !data?.position_sc)?<span className="text-12 text-red-500 font-normal">Position is required.</span>:null}
                </div>
            </div>
        </div>
        <div className="mt-5">
            <LabelAndImage isRequired={true} label="Profile Image" value={data?.image_url??''} setValue={(value)=>change_data(value,'image_url')} setFile={(value)=>change_data(value,'image')} />
        </div>
        <div className="mt-5">
                <LabelAndNumber label="Order" value={data?.order??0} setValue={(value) => change_data(value, 'order')} />
            </div>
        <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={twJoin(
                     isSave ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={() => {
                    if(check_validation()==false){  
                        setIsRequired(true);
                        return;
                      }
                    handle_submit(data)
                }}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShow(true);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
    </div>
}
export default BoardOfDirectoryInput;