import { ReactElement, useState } from "react";
import BasicLayout from "../../layouts/BasicLayout";
import BaggageList from "./BaggageList";

const BaggageListRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  return (
    <BasicLayout userData={data} permissions={permissions}>
       <BaggageList/>
    </BasicLayout>
  );
};

export default BaggageListRoute;
