import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import { useState } from "react";
import BannerSlider, { IBannerSlider } from "../components/BannerSlider";
import BannerSliderSectionList from "./BannerSlider/BannerSliderSectionList";
import { IHomeBanner } from "../../app/services/home/home";

const BannerSliderSection = () => {
  const [activeLang, setActiveLang] = useState<string>("en");
  const [detailData,setDetailData]=useState<IHomeBanner>({
    id:0,
    title_en: '',
    title_tc: '',
    title_cn: '',
    slider_desktop_image: undefined,
  })
  const [showList, setShowList] = useState<boolean>(true);
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
      {showList == true
        ? <BannerSliderSectionList setShowList={setShowList} setDetailData={setDetailData}/>
        : <div>
            <CommonLanguage
              changeLanguage={changeLanguage}
              activeLang={activeLang}
            />
            <BannerSlider
              activeLang={activeLang}
              initialData={detailData}
              setValue={setDetailData}
              setShowList={setShowList}
            />
          </div>}
    </div>
  );
};
export default BannerSliderSection;
