import React, { useEffect, useState } from "react";

type MultipleImageUploaderProps = {
  imageUrlList: string[]|[]; // Existing image URLs
  onChange: (files: File[], remainingUrls: string[],updatedPreviews:string[]) => void; // Pass both new files and remaining URLs
  setRemoveImage: React.Dispatch<React.SetStateAction<string[]>>;
  imageObj:any;
  removeImages: string[];
};

const MultipleImageUploader: React.FC<MultipleImageUploaderProps> = ({
  imageUrlList,
  onChange,
  setRemoveImage,
  imageObj,
  removeImages
}) => {
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [previewUrls, setPreviewUrls] = useState<string[]>(imageUrlList??[]);

  const isImageFile = (file: File) => file.type.startsWith("image/");
  useEffect(()=>{
    if(imageUrlList){
      setPreviewUrls(imageUrlList);
    }
  },[imageUrlList])
  const isDuplicateFile = (file: File) =>
    selectedImages.some(
      (existingFile) =>
        existingFile.name === file.name && existingFile.size === file.size
    );

  const handleImageAdd = (files: FileList | File[]) => {
    const newFiles = Array.from(files)
      .filter(isImageFile) // Filter only image files
      .filter((file) => !isDuplicateFile(file)); // Exclude duplicates

    const newPreviewUrls = newFiles.map((file) => URL.createObjectURL(file));

    setSelectedImages((prev) => [...prev, ...newFiles]);
    setPreviewUrls((prev) => [...prev, ...newPreviewUrls]);

    // Notify parent with updated files and URLs
    onChange([...selectedImages, ...newFiles], previewUrls,[]);
  };

  const handleRemoveImage = (index: number) => {
    // Update previews
    const updatedPreviews = [...previewUrls];
    updatedPreviews.splice(index, 1);
    setRemoveImage([...removeImages,...imageObj.map((x:any,i:number)=>{
      if(i==index){
        return x.id;
      }
    }).filter((x:any)=>x!=undefined)]);
    console.log("index ",removeImages)
    //console.log("updatedPreviews ",updatedPreviews)
    // If the image is from `imageUrlList`, remove it from there
    if (index < imageUrlList.length) {
      const updatedUrls = [...imageUrlList];
      updatedUrls.splice(index, 1);
      setPreviewUrls(updatedPreviews);
      onChange(selectedImages, updatedUrls,updatedPreviews); // Notify parent with remaining URLs
    } else {
      // Otherwise, remove it from the new files
      const updatedFiles = [...selectedImages];
      updatedFiles.splice(index - imageUrlList.length, 1);
      setSelectedImages(updatedFiles);
      setPreviewUrls(updatedPreviews);      
      onChange(updatedFiles, imageUrlList,updatedPreviews); // Notify parent with remaining files and original URLs
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files) handleImageAdd(files);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div className="my-5 w-full">
      <p className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
        Mulitple Image Uploader <span className="text-14 text-red-500">*</span>
      </p>
      <div
        className="space-y-4 focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div className="text-center border-2 border-dashed border-gray-300 p-5 rounded-lg bg-gray-50 hover:bg-gray-100 cursor-pointer">
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={(e) => e.target.files && handleImageAdd(e.target.files)}
            className="hidden"
            id="image-uploader"
          />
          <label htmlFor="image-uploader" className="cursor-pointer">
            <p className="text-sm text-gray-500">
              Drag and drop images here, or{" "}
              <span className="text-vorpblue font-medium">browse</span>
            </p>
          </label>
        </div>
        {previewUrls?.length > 0 ? (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4">
            {previewUrls.map((url, index) => (
              <div key={index} className="relative group">
                <img
                  src={url}
                  alt={`Preview ${index}`}
                  className="aspect-[100/100] bg-center w-full max-h-[100px] group-hover:shadow-lg transition-all ease-in-out duration-300 object-cover rounded"
                />
                <button
                  type="button"
                  className="absolute top-2 shadow-md right-2 bg-white border border-vorpblue hover:bg-vorpblue text-vorpblue hover:text-white transition-all ease-in-out duration-300 rounded-full w-6 h-6 flex items-center justify-center"
                  onClick={() => handleRemoveImage(index)}
                >
                  ✕
                </button>
              </div>
            ))}
          </div>
        ) : (
          <p className="mt-5 text-center text-gray-400">No Image choosen</p>
        )}
      </div>
    </div>
  );
};

export default MultipleImageUploader;
