import { FC, useRef, useState } from "react";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import { twJoin } from "tailwind-merge";
import classNames from "classnames";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import LabelAndSelect from "../../components/SeoData/LabelAndSelect";
import SeoData from "../../components/SeoData/SeoData";
import CommonLanguage from "../../components/common/CommonLanguage";
import MultipleImageUploader from "../../components/SeoData/MultipleImageUploader";
export interface ILabelValueData{
  value:string;
  label:string;
}
interface IData {
  initialData: LatestNewsType;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  handle_submit: (data: LatestNewsType, imageFiles: File[]) => Promise<void>
  isSave:boolean;
  categoryList: ILabelValueData[]
}

export interface LatestNewsType {
  id: number;
  meta_title_en: string;
  meta_title_tc: string;
  meta_title_sc: string;
  meta_description_en: string;
  meta_description_tc: string;
  meta_description_sc: string;
  meta_image: string;
  category: string;
  title_en: string;
  title_sc: string;
  title_tc: string;
  description_en: string;
  description_sc: string;
  description_tc: string;
  image: File | undefined;
  image_url: string;
  image_list: File[] | undefined;
  image_url_list: string[];
}

const options = [
  {
    label: "Passenger services",
    value: "1",
  },
  {
    label: "Ramp Services",
    value: "2",
  },
  {
    label: "Load Control and Flight Operations ",
    value: "3",
  },
];

const LatestNews: FC<IData> = ({ setShowList, initialData,handle_submit,isSave,categoryList }) => {
  const [activeLang, setActiveLang] = useState<string>("en");
  const [data, setData] = useState<LatestNewsType>(initialData);
  const [metaTitle_en, setMetaTitleEn] = useState<string>(
    initialData.meta_title_en || ""
  );
  const [metaTitle_zh, setMetaTitleZh] = useState<string>(
    initialData.meta_title_sc || ""
  );
  const [metaTitle_cn, setMetaTitleCn] = useState<string>(
    initialData.meta_title_tc || ""
  );
  const [metaDescription_en, setMetaDescriptionEn] = useState<string>(
    initialData.meta_description_en || ""
  );
  const [metaDescription_zh, setMetaDescriptionZh] = useState<string>(
    initialData.meta_description_sc || ""
  );
  const [metaDescription_cn, setMetaDescriptionCn] = useState<string>(
    initialData.meta_description_tc || ""
  );
  const [metaImage, setMetaImage] = useState<string>(
    initialData.meta_image || ""
  );
  const [metaImageFile, setMetaImageFile] = useState<File | undefined>();

  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [imageUrlList, setImageUrlList] = useState<string[]>(
    data?.image_url_list
  );

  const description_en_Ref = useRef();

  const change_data = (value: any, key: string) => {
    if (data) {
      if(key=="image"){
        setData((prev)=>{
          return {
            ...prev,
            image:value,
            image_url:value?URL.createObjectURL(value):''
          }
        })
      }
      setData({ ...data, [key]: value });
    }
  };

  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };

  const handleFilesChange = (newFiles: File[], remainingUrls: string[]) => {
    setImageFiles(newFiles);
    setImageUrlList(remainingUrls);
    console.log("New selected files:", newFiles);
    console.log("image url list:", remainingUrls);
  };

  return (
    <div>
      <h2 className="text-lg text-black font-bold text-left my-5">
        Latest News
      </h2>
      <div className="flex flex-col lg:flex-row gap-10">
        <div className="">
          <div className="mb-5">
            <CommonLanguage
              changeLanguage={changeLanguage}
              activeLang={activeLang}
            />
          </div>
          <LabelAndSelect
            label="Category"
            value={data?.category}
            options={categoryList?.map((cat)=>{
              return {
                label:cat.label,
                value:cat.value
              }
            })}
            setValue={(value) => change_data(value, "category")}
          />

          <div
            className={twJoin(
              activeLang == "en"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div>
              <div>
                <div className="grid grid-cols-1">
                  <LabelAndTextbox isRequired={true}
                    label="Title (EN)"
                    value={data?.title_en}
                    setValue={(value: any) =>
                      change_data(value, "title_en")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={twJoin(
              activeLang == "tc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div>
              <div className="grid grid-cols-1">
                <LabelAndTextbox isRequired={true}
                  label="Title (TC)"
                  value={data?.title_tc}
                  setValue={(value: any) =>
                    change_data(value, "title_tc")
                  }
                />
              </div>
            </div>
          </div>
          <div
            className={twJoin(
              activeLang == "sc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div>
              <div className="grid grid-cols-1">
                <LabelAndTextbox isRequired={true}
                  label="Title (SC)"
                  value={data?.title_sc}
                  setValue={(value: any) =>
                    change_data(value, "title_sc")
                  }
                />
              </div>
            </div>
          </div>
          <div
            className={twJoin(
              activeLang == "en"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div className="mt-5">
              <LabelAndEditor isRequired={true}
                refId={description_en_Ref}
                label="Description (EN)"
                value={data?.description_en}
                setValue={(value) =>
                  change_data(value, "description_en")
                }
              />
            </div>
          </div>
          <div
            className={twJoin(
              activeLang == "tc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div className="mt-5">
              <LabelAndEditor isRequired={true}
                refId={description_en_Ref}
                label="Description (TC)"
                value={data?.description_tc}
                setValue={(value) =>
                  change_data(value, "description_tc")
                }
              />
            </div>
          </div>

          <div
            className={twJoin(
              activeLang == "sc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <div className="mt-5">
              <LabelAndEditor isRequired={true}
                refId={description_en_Ref}
                label="Description (SC)"
                value={data?.description_sc}
                setValue={(value) =>
                  change_data(value, "description_sc")
                }
              />
            </div>
          </div>

          <div>
            <LabelAndImage isRequired={true}
              margin="mt-5"
              setFile={(value: any) => change_data(value, "image")}
              label="Image"
              value={data?.image_url}
              setValue={(value: any) =>
                change_data(value, "image_url")
              }
            />
          </div>
          {/* <div>
            <MultipleImageUploader
              imageUrlList={data?.image_url_list}
              onChange={handleFilesChange}
            />
          </div> */}
        </div>

        <div className="">
          <h2 className="text-lg text-black font-bold text-left my-5">SEO</h2>
          <SeoData
            showPageTitle={false}
            setPageTitleEn={() => 1}
            setPageTitleZh={() => 1}
            setPageTitleCn={() => 1}
            metatitle_en={data?.meta_title_en}
            metatitle_zh={data?.meta_title_tc}
            metatitle_cn={data?.meta_title_sc}
            setMetaTitleEn={(value)=>change_data(value,'meta_title_en')}
            setMetaTitleZh={(value)=>change_data(value,'meta_title_tc')}
            setMetaTitleCn={(value)=>change_data(value,'meta_title_sc')}
            metadescription_en={metaDescription_en}
            metadescription_zh={metaDescription_zh}
            metadescription_cn={metaDescription_cn}
            setMetaDescriptionEn={(value)=>change_data(value,'meta_description_en')}
            setMetaDescriptionZh={(value)=>change_data(value,'meta_description_tc')}
            setMetaDescriptionCn={(value)=>change_data(value,'meta_description_sc')}
            meta_image={metaImage}
            setMetaImage={setMetaImage}
            setMetaImageFile={(value)=>change_data(value,'meta_image')}
          />
        </div>
      </div>
      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className={classNames(
             isSave ? "opacity-50 pointer-events-none" : "",
            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
          )}
          onClick={() => handle_submit(data,imageFiles)}
        >
          <SaveIcon className="mr-2" /> Save
        </button>
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => {
            setShowList(true);
          }}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Cancel
        </button>
      </div>
    </div>
  );
};
export default LatestNews;
