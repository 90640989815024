import React, { useState } from "react";
import styles from "./ResetPasswordComponent.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
//import logo from '../../login/img/companyLogo.svg';
import logo from '../../login/img/companyLogo.svg';
import eyehide from "../img/eye-open.svg";
import eyeshow from "../img/eye-show.svg";
import axios from "axios";
import { endpointUrl } from "../../app/services/api";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { logout } from "../../app/services/auth.service";
// import axios from "../../axios";

var classNames = require("classnames");
const ResetPasswordComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);

  // const dispatch = useDispatch();
  // const {
  //   data: users,

  //   isFetching: isFetchingUserLists,
  // } = useGetUsersQuery();
  // const [deleteUser] = useDeleteUserMutation();
  // const [addUser] = useAddUserMutation();
  //const [isChecked, setIsChecked] = React.useState(false);
  // 15.2.2023
  const [errorMessages, setErrorMessages] = useState({ name: "", message: "" });
  const [loginError, setLoginError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  //const [attemptAccess, { data, error, isLoading }] = useProtectedMutation();
  const params = new URLSearchParams(location.search);
  var email = params.get("email");
  var token = params.get("token");
  var isadmin = params.get("isadmin");

  const handleSubmit = (event: any) => {
    // Prevent page reload
    event.preventDefault();
    var { password, confirmPassword } = document.forms[0];
    var userData = localStorage.getItem("userData");
    //var userJson=userData?JSON.parse(userData):{};
    var values = {
      token: token,
      email: email,
      password: password.value,
      password_confirmation: confirmPassword.value,
      isadmin:isadmin
    };

    axios
      .post(endpointUrl + "password/reset", values, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response.status == 200) {
          if(response?.data?.ismobile==true){
            navigate("/reset-success");
            return;
          }
         
          logout();
          navigate("/");
        } else {
        }
        //var data = response.data;
      })
      .catch((reason) => {
        // Object.keys(reason.response.data.errors).forEach(key => {
        //   const d = reason.response.data.errors[key];
        //   console.log(d);
        // })
        var error = reason.response?.data.errors?.password?reason.response?.data.errors?.password[0]:'';
        setErrorPopup(true);
        // setLoginError(error);
        setMessage([error]);
      });
  };
  const renderErrorMessage = (name: any) => {
    return (
      name == errorMessages.name && (
        <div className="text-red-500 text-15 font-primary">
          {errorMessages.message}
        </div>
      )
    );
  };

  const switchEyeHandler = (event: any) => {
    var hideimg = eyehide;
    var showimg = eyeshow;

    const type =
      event.target.previousElementSibling.getAttribute("type") == "password"
        ? "text"
        : "password";
    const img =
      event.target.previousElementSibling.getAttribute("type") == "password"
        ? hideimg
        : showimg;
    event.target.previousElementSibling.setAttribute("type", type);
    event.target.setAttribute("src", img);
  };

  const renderForm = (
    <form
      onSubmit={handleSubmit}
      className="w-full htzxs:w-[440px] px-5 htzxs:px-0 "
    >
      <div
        className={classNames(
          "flex justify-start text-left flex-col w-full ",
          styles.signinform
        )}
      >
        <h3 className="text-darkblue text-25 sm:text-30 font-semibold mb-[29px]">
          Reset Password
        </h3>

        <div className={classNames(styles.inputContainer, "last:mb-0")}>
          <div className="flex items-center mb-[12px]">
            <label
              htmlFor="password"
              className="text-black2 text-15 w-1/2 block"
            >
              Password
            </label>
          </div>
          <div className="relative mb-[30px]">
            <input
              type="password"
              name="password"
              required
              className="h-[70px] placeholder:text-black2 placeholder:text-14 text-black2 rounded-[9px] pl-[24px] pr-16 w-full focus:outline-none"
              placeholder="password"
              style={{
                border:
                  errorMessages?.name == "password" ? "1px solid red" : "",
              }}
            />
            <img
              src={eyeshow}
              alt="eye-open"
              className={classNames(
                styles.theeye,
                "absolute top-1/2 right-4 cursor-pointer w-[22px] h-[16px] object-cover"
              )}
              onClick={switchEyeHandler}
            />
          </div>
          <div className="flex items-center mb-[12px]">
            <label
              htmlFor="password"
              className="text-black2 text-15 w-1/2 block"
            >
              Confirmed Password
            </label>
          </div>
          <div className="relative">
            <input
              type="password"
              name="confirmPassword"
              required
              className="h-[70px] placeholder:text-black2 placeholder:text-14 text-black2 rounded-[9px] pl-[24px] pr-16 w-full focus:outline-none"
              placeholder="password"
              style={{
                border:
                  errorMessages?.name == "password" ? "1px solid red" : "",
              }}
            />
            <img
              src={eyeshow}
              alt="eye-open"
              className={classNames(
                styles.theeye,
                "absolute top-1/2 right-4 cursor-pointer w-[22px] h-[16px] object-cover"
              )}
              onClick={switchEyeHandler}
            />
          </div>

          {renderErrorMessage("password")}
        </div>
        <div className={classNames(styles.buttonContainer)}>
          <button
            type="submit"
            className="bg-vorpblue text-15 text-white w-[109px] flex items-center justify-center mt-[37px] rounded-[6px] py-[0.8rem]"
          >
            Reset
          </button>
        </div>
      </div>
      <div className="text-red-500 text-15 font-primary">{loginError}</div>
    </form>
  );
  return (
    <>
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <div
        className={classNames(
          "flex items-stretch h-screen font-primary flex-col md:flex-row",
          styles.logincomponent
        )}
      >
        <div
          className={classNames(
            // styles.leftLogoBox,
            "w-full md:w-2/5 bg-bottom 7xl:bg-auto py-[50px] md:pb-0 bg-no-repeat flex"
          )}
         // style={{ backgroundImage: `url(${bglogin})` }}
        >
          <img
            src={logo}
            alt="logo"
            className={classNames("mx-auto lg:w-full max-w-[300px]", styles.companylogo)}
          />
          {/* <p className="text-vorpgray text-10 md:text-12 xl:text-16 4xl:text-18 font-semibold">
            Visible One Internal Operation System
          </p> */}
        </div>
        <div
          className={classNames(
            styles.rightLoginBox,
            "flex items-center justify-center w-full md:w-3/5 bg-offwhite"
          )}
        >
          {isSubmitted ? (
            <div className="text-black2 text-15 font-primary">
              User is successfully logged in
            </div>
          ) : (
            renderForm
          )}
        </div>
      </div>
    </>
  );
};

export default ResetPasswordComponent;
