import { ReactElement, useState } from "react";
import BasicLayout from "../../layouts/BasicLayout";
import Sitemap from "./Sitemap";

const SitemapRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  return (
    <BasicLayout userData={data} permissions={permissions}>
       <Sitemap/>
    </BasicLayout>
  );
};

export default SitemapRoute;
