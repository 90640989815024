import { FC, useEffect, useState } from "react";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { AddIcon, CancelIcon, SaveIcon } from "../../components/common/Icons";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import { twJoin } from "tailwind-merge";
import classNames from "classnames";
import { CircularProgress } from "@mui/material";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";

interface IData {
  activeLang: string;
  initialData: IServiceHomeInfoData;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
}
export interface IServiceHomeInfoData {
  id: number;
  title_en: string;
  title_tc: string;
  title_sc: string;
  image: any;
  image_url: string;
}
export interface IServiceInfoData {
  id: number;
  title_en: string;
  image_en: File | undefined;
  image_en_url: string;
  title_zh: string;
  image_zh: File | undefined;
  image_zh_url: string;
  title_cn: string;
  image_cn: any;
  image_cn_url: string;
  image: any;
  image_url: string;
}
const Services: FC<IData> = ({
  activeLang,
  setShowList,
  initialData,
  setValue
}) => {
  const [message, setMessage] = useState<string>("");
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [isRequired, setIsRequired] = useState<boolean>(false);
  const [image_url, setImageUrl] = useState<string>('');
  const [data, setData] = useState<IServiceInfoData>({
    id: 0,
    title_en: '',
    title_zh: '',
    title_cn: '',
    image: undefined,
    image_url: '',
    image_en: undefined,
    image_en_url: '',
    image_zh: undefined,
    image_zh_url: '',
    image_cn: undefined,
    image_cn_url: ''
  });
  const change_data = (value: any, key: string) => {
    if (data) {
      if (key == "image") {
        const url=value?URL.createObjectURL(value):'';
        setData({ ...data, ['image_url']: url });
        setData({ ...data, [key]: value });
        setImageUrl(url);
      }
      setData({ ...data, [key]: value });
    }
    if (initialData) {
      // setValue({ ...initialData, [key]: value });
    }
  };
  const check_validation=()=>{
    if(data?.title_en && data?.title_zh && data?.title_cn && image_url){
      return true;
    }else{
      return false;
    }
  }
  const handle_submit = async () => {
    setSaving(true);
    setIsRequired(true);
    if(check_validation()==false){  
      setIsRequired(true);
      setSaving(false);
      return;
    }
    const image: any = data?.image;
    const image_zh: any = data?.image_zh;
    const image_cn: any = data?.image_cn;
    const formData = new FormData();
    formData.append('id',data?.id.toString()??0);
    formData.append('title_en', data?.title_en);
    formData.append('title_tc', data?.title_zh);
    formData.append('title_sc', data?.title_cn);
    formData.append('image_en', image);
    formData.append('image_zh', image_zh);
    formData.append('image_cn', image_cn);
    formData.append('image', image);
    const url=data?.id?"update-home-services":"home-services"
    await axios(endpointUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res: any) => {
        if (res?.data?.status == true) {
          toast(res?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        } else {

        }
        setShowList(true);
        setSaving(false);
      })
      .catch((err) => {
        setErrorPopup(true);
        setMessage(err?.response?.data?.message)
        setSaving(false);
      });
  }
  useEffect(() => {
    if (initialData) {
      setData({
        id: initialData?.id,
        title_en: initialData?.title_en,
        title_zh: initialData?.title_tc,
        title_cn: initialData?.title_sc,
        image_en: undefined,
        image_en_url: '',
        image_zh: undefined,
        image_zh_url: '',
        image_cn: undefined,
        image_cn_url: '',
        image: undefined,
        image_url: initialData?.image ?? ''
      });
      setImageUrl(initialData?.image);
    }
  }, [initialData])
  return (
    <div>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <CommonErrorPopup
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
      />
      <h2 className="text-lg text-black font-bold text-left my-5">Services</h2>
      <div
        className={twJoin(
          activeLang == "en"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <div>
          <div className="grid grid-cols-1 gap-0">
            <LabelAndTextbox
              label="Title (EN)"
              value={data?.title_en}
              setValue={(value: any) => change_data(value, 'title_en')}
            />
            {(isRequired && !data?.title_en)?<span className="text-12 text-red-500 font-normal block">Title is required.</span>:null}
          </div>
        </div>
      </div>
      <div
        className={twJoin(
          activeLang == "tc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <div>

          <div>
            <div className="grid grid-cols-1 gap-3">
              <LabelAndTextbox
                label="Title (TC)"
                value={data?.title_zh}
                setValue={(value: any) => change_data(value, 'title_zh')}
              />
              {(isRequired && !data?.title_en)?<span className="text-12 text-red-500 font-normal block">Title is required.</span>:null}
            </div>
          </div>
        </div>
      </div>
      <div
        className={twJoin(
          activeLang == "sc"
            ? ""
            : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
        )}
      >
        <div>
          <div>
            <div className="grid grid-cols-1 gap-3">
              <LabelAndTextbox
                label="Title (SC)"
                value={data?.title_cn}
                setValue={(value: any) => change_data(value, 'title_cn')}
              />
             {(isRequired && !data?.title_en)?<span className="text-12 text-red-500 font-normal block">Title is required.</span>:null}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <LabelAndImage
          margin="mt-0"
          setFile={(value: any) => change_data(value, 'image')}
          label="Image"
          value={image_url}
          setValue={() => 1}
        />
        {(isRequired && !image_url)?<span className="text-12 text-red-500 font-normal mt-5 block">Image is required.</span>:null}
      </div>
      <div>
        <div className="mt-5 last:mb-0 flex items-center">
          <button
            className={classNames(
              saving ? "opacity-50 pointer-events-none" : "",
              "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
            )}
            onClick={() => handle_submit()}
          >
            <SaveIcon className="mr-2" /> Save
          </button>
          <button
            className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
            onClick={() => {
              setShowList(true);
            }}
          >
            <CancelIcon className="mr-2" color="#9E3039" />
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
export default Services;
