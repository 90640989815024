import { ReactElement } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import OverViewPage from "./sections/OverviewPage";
import JobOpeningPage from "./sections/JobOpeningPage";
import JobApplicationPage from "./sections/JobApplicationsPage";
import JobCategoryPage from "./sections/JobCategoryPage";
import JobDepartmentPage from "./sections/JobDepartment";
import CareerPathOverviewPage from "../career-path-development/sections/CareerPathOverviewPage";
export const jobOpportunitiesPagesList = [
  {
    id: 1,
    path: "#",
    component: <OverViewPage />,
  },
  {
    id: 2,
    path: "overview",
    component: <OverViewPage />,
  },
  {
    id: 3,
    path: "job-openings",
    component: <JobOpeningPage />,
  },
  {
    id: 4,
    path: "job-applications",
    component: <JobApplicationPage />,
  },
  {
    id: 4,
    path: "job-category",
    component: <JobCategoryPage />,
  },
  {
    id: 4,
    path: "job-department",
    component: <JobDepartmentPage />,
  },
  {
    id: 5,
    path: "career-path-development",
    component: <CareerPathOverviewPage />,
  },
];
const JobOpportunitiesPageRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  return (
    <BasicLayout userData={data} permissions={permissions}>
    <div className="pt-0 pb-[58px]">
      <Outlet />
    </div>
  </BasicLayout>
  );
};
export default JobOpportunitiesPageRoute;
