import React, { useEffect } from "react";
import done_bg from "../img/done-bg.svg";

const ResetPasswordSuccess = (props:any) => {
  return (
    <div className="max-w-full">
      <div className="w-full mx-auto px-5">
        <div className="password-successful-container">
          <div className="pt-[124px] pb-10">
            <img
              src={done_bg}
              alt="done_bg"
              className="max-w-full mx-auto mt-10"
            />
            <div className="mt-[75px]">
              <p className="heading1 font-secondary font-normal leading-[135%] text-sat_darkblackv2 text-center">
              You’re all set!
              </p>
              <p className="mt-3 text-body-3 font-secondary font-normal text-sat_darkblackv2 text-center">
              Your password has been reset successfully.
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordSuccess;
