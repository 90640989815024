import { FC, useEffect, useRef, useState } from "react";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
export interface IMissionData {
    mission: IMission | undefined;
    setMission: React.Dispatch<React.SetStateAction<IMission | undefined>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handle_submit: (data: IMission) => Promise<void>;
    isSave: boolean;
}
export interface IMission {
    id: number;
    title_en: string;
    title_tc: string;
    title_sc: string;
    description_en: string;
    description_tc: string;
    description_sc: string;
    image: File | undefined;
    image_url: string;
}
const CompanyMissionInput: FC<IMissionData> = ({
    mission,
    setMission,
    setShow,
    handle_submit,
    isSave
}) => {
    const [data, setData] = useState<IMission>({
        id: 0,
        title_en: '',
        title_tc: '',
        title_sc: '',
        description_en: '',
        description_tc: '',
        description_sc: '',
        image: undefined,
        image_url: ''
    });
    const [activeLang, setActiveLang] = useState<string>("en");
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(() => {
        if (mission) {
            setData({
                id: mission.id,
                title_en: mission.title_en,
                title_tc: mission.title_tc,
                title_sc: mission.title_sc,
                description_en: mission.description_en,
                description_tc: mission.description_tc,
                description_sc: mission.description_sc,
                image: undefined,
                image_url: mission.image as any
            });
        }
    }, [mission]);
    const change_data = (value: any, key: string) => {
        if (data) {
            if (key == "image") {
                const url=value?URL.createObjectURL(value):'';
                setData((prev) => {
                    return {
                        ...prev,
                        image: value,
                        image_url: url
                    }
                });
                return;
            }
            setData({ ...data, [key]: value })
        }
    }
    return <div>
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div className="">
                    <LabelAndTextbox isRequired={true} label="Title (EN)" value={data?.title_en} setValue={(value) => change_data(value, 'title_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Description (EN)" value={data?.description_en} setValue={(value) => change_data(value, 'description_en')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div className="">
                    <LabelAndTextbox isRequired={true} label="Title (TC)" value={data?.title_tc} setValue={(value) => change_data(value, 'title_tc')} />

                </div>
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Description (TC)" value={data?.description_tc} setValue={(value) => change_data(value, 'description_tc')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div className="">
                    <LabelAndTextbox isRequired={true} label="Title (SC)" value={data?.title_sc} setValue={(value) => change_data(value, 'title_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Description (SC)" value={data?.description_sc} setValue={(value) => change_data(value, 'description_sc')} />
                </div>
            </div>
        </div>
        <LabelAndImage isRequired={true} label="Image" value={data?.image_url} setFile={(value) => change_data(value, 'image')} />
        <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={twJoin(
                    isSave ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={() => handle_submit(data)}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShow(true);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
    </div>
}
export default CompanyMissionInput;