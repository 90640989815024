import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../../components/common/CommonLanguage";
import LabelAndTextbox from "../../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../../components/SeoData/LabelAndImage";
import { CancelIcon, SaveIcon } from "../../../components/common/Icons";
import { StringDropdownList } from "../../../components/common/DropdownList";
import SeoData from "../../../components/SeoData/SeoData";
import LabelAndEditor from "../../../components/SeoData/LabelAndEditor";
import LabelAndDate from "../../../components/SeoData/LabelAndDate";
import LabelAndSelect from "../../../components/SeoData/LabelAndSelect";
import { ILabelValueData } from "../../../latest-news/components/LatestNews";
export interface IGoodPeople {
    id: number;
    title_en: string;
    title_tc: string;
    title_sc: string;
    description_en: string;
    description_tc: string;
    description_sc: string;
    date: Date | undefined;
    short_description_en: string;
    short_description_tc: string;
    short_description_sc: string;
    image: File | undefined;
    image_url: string;
    meta_title_en: string;
    meta_title_tc: string;
    meta_title_sc: string;
    meta_description_en: string;
    meta_description_tc: string;
    meta_description_sc: string;
    meta_image: File | undefined;
    meta_image_url: string
    category_id: string
}
export interface IBoardOfDirectoryInput {
    initialData: IGoodPeople;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handle_submit: (data: IGoodPeople) => Promise<void>;
    isSave: boolean;
    categoryList: ILabelValueData[];
}
const GoodPeopleInput: FC<IBoardOfDirectoryInput> = ({
    initialData,
    setShow,
    handle_submit,
    isSave,
    categoryList
}) => {
    const [data, setData] = useState<IGoodPeople>({
        id: 0,
        title_en: '',
        title_tc: '',
        title_sc: '',
        description_en: '',
        description_tc: '',
        description_sc: '',
        date: undefined,
        short_description_en: '',
        short_description_tc: '',
        short_description_sc: '',
        image: undefined,
        image_url: '',
        meta_title_en: '',
        meta_title_tc: '',
        meta_title_sc: '',
        meta_description_en: '',
        meta_description_tc: '',
        meta_description_sc: '',
        meta_image: undefined,
        meta_image_url: '',
        category_id: '0'
    });
    const description_en_ref = useRef(null);
    const description_tc_ref = useRef(null);
    const description_sc_ref = useRef(null);
    const [metaTitle_en, setMetaTitleEn] = useState<string>(initialData.meta_title_en || "");
    const [metaTitle_zh, setMetaTitleZh] = useState<string>(
        initialData.meta_title_sc || ""
    );
    const [metaTitle_cn, setMetaTitleCn] = useState<string>(initialData.meta_title_tc || "");
    const [metaDescription_en, setMetaDescriptionEn] = useState<string>(initialData.meta_description_en || "");
    const [metaDescription_zh, setMetaDescriptionZh] = useState<string>(initialData.meta_description_sc || "");
    const [metaDescription_cn, setMetaDescriptionCn] = useState<string>(initialData.meta_description_tc || "");
    const [metaImage, setMetaImage] = useState<File | undefined>(undefined);
    const [metaImageUrl, setMetaImageUrl] = useState<string>(initialData.meta_image as any || "");
    const [metaImageFile, setMetaImageFile] = useState<File | undefined>();
    const [activeLang, setActiveLang] = useState<string>("en");
    const change_data = (value: any, key: string) => {
        if (data) {
            if (key == "image") {
                const url = value ? URL.createObjectURL(value) : '';
                setData((prev) => {
                    return {
                        ...prev,
                        image: value,
                        image_url: url
                    }
                });
                return;
            }
            if (key == "meta_image") {
                const url = value ? URL.createObjectURL(value) : '';
                setData((prev) => {
                    return {
                        ...prev,
                        meta_image: value,
                        meta_image_url: url
                    }
                });
                return;
            }
            setData({ ...data, [key]: value });

        }
    }
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(() => {
        if (initialData) {
            setData({
                id: initialData.id,
                title_en: initialData.title_en,
                title_tc: initialData.title_tc,
                title_sc: initialData.title_sc,
                description_en: initialData.description_en,
                description_tc: initialData.description_tc,
                description_sc: initialData.description_sc,
                image: undefined,
                image_url: initialData.image as any,
                date: initialData?.date,
                short_description_en: initialData?.short_description_en,
                short_description_tc: initialData?.short_description_tc,
                short_description_sc: initialData?.short_description_sc,
                meta_title_en: initialData.meta_title_en,
                meta_title_tc: initialData.meta_title_tc,
                meta_title_sc: initialData.meta_title_sc,
                meta_description_en: initialData.meta_description_en,
                meta_description_tc: initialData.meta_description_tc,
                meta_description_sc: initialData.meta_description_sc,
                meta_image: undefined,
                meta_image_url: initialData.meta_image as any,
                category_id: initialData.category_id
            })
        }
    }, [initialData]);
    return <div>
        <div className="grid grid-cols-2 gap-10">
            <div>
                <CommonLanguage
                    changeLanguage={changeLanguage}
                    activeLang={activeLang}
                />
                <div className="mt-5">
                    <div
                        className={twJoin(
                            activeLang == "en"
                                ? ""
                                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                        )}
                    >
                        <LabelAndTextbox label="Title (EN)" value={data?.title_en} setValue={(value) => change_data(value, 'title_en')} />
                        <div className="mt-5">
                            <LabelAndEditor refId={description_en_ref} label="Description (EN)" value={data?.description_en} setValue={(value) => change_data(value, 'description_en')} />
                        </div>
                        <div className="mt-5">
                            <LabelAndTextbox label="Short Description (EN)" value={data?.short_description_en} setValue={(value) => change_data(value, 'short_description_en')} />
                        </div>
                    </div>
                    <div
                        className={twJoin(
                            activeLang == "tc"
                                ? ""
                                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                        )}
                    >
                        <LabelAndTextbox label="Title (TC)" value={data?.title_tc} setValue={(value) => change_data(value, 'title_tc')} />
                        <div className="mt-5">
                            <LabelAndEditor refId={description_tc_ref} label="Description (TC)" value={data?.description_tc} setValue={(value) => change_data(value, 'description_tc')} />
                        </div>
                        <div className="mt-5">
                            <LabelAndTextbox label="Short Description (TC)" value={data?.short_description_tc} setValue={(value) => change_data(value, 'short_description_tc')} />
                        </div>

                    </div>
                    <div
                        className={twJoin(
                            activeLang == "sc"
                                ? ""
                                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                        )}
                    >
                        <LabelAndTextbox label="Title (SC)" value={data?.title_sc} setValue={(value) => change_data(value, 'title_sc')} />
                        <div className="mt-5">
                            <LabelAndEditor refId={description_sc_ref} label="Description (SC)" value={data?.description_sc} setValue={(value) => change_data(value, 'description_sc')} />
                        </div>
                        <div className="mt-5">
                            <LabelAndTextbox label="Short Description (SC)" value={data?.short_description_sc} setValue={(value) => change_data(value, 'short_description_sc')} />
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <LabelAndSelect label="Category" value={data?.category_id} options={categoryList} setValue={(value) => change_data(value, 'category_id')} />
                </div>
                <div className="mt-5">
                    <LabelAndDate label="Date" value={data?.date} setValue={(value) => change_data(value, 'date')} />
                </div>
                <div className="mt-5">
                    <LabelAndImage label="Image" value={data?.image_url ?? ''} setValue={(value) => change_data(value, 'image_url')} setFile={(value) => change_data(value, 'image')} />
                </div>
            </div>
            <div className="">
                <h2 className="text-lg text-black font-bold text-left my-5">SEO</h2>
                <SeoData
                    showPageTitle={false}
                    setPageTitleEn={() => 1}
                    setPageTitleZh={() => 1}
                    setPageTitleCn={() => 1}
                    metatitle_en={data.meta_title_en}
                    metatitle_zh={data.meta_title_tc}
                    metatitle_cn={data.meta_title_sc}
                    setMetaTitleEn={(value)=>change_data(value,'meta_title_en')}
                    setMetaTitleZh={(value)=>change_data(value,'meta_title_tc')}
                    setMetaTitleCn={(value)=>change_data(value,'meta_title_sc')}
                    metadescription_en={data.meta_description_en}
                    metadescription_zh={data.meta_description_tc}
                    metadescription_cn={data.meta_description_sc}
                    setMetaDescriptionEn={(value)=>change_data(value,'meta_description_en')}
                    setMetaDescriptionZh={(value)=>change_data(value,'meta_description_tc')}
                    setMetaDescriptionCn={(value)=>change_data(value,'meta_description_sc')}
                    meta_image={data.meta_image_url}
                    setMetaImage={(value)=>1}
                    setMetaImageFile={(value)=>change_data(value,'meta_image')}
                />
            </div>
        </div>

        <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={twJoin(
                    isSave ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={() => handle_submit(data)}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShow(true);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
    </div>
}
export default GoodPeopleInput;