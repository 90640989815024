import { FC } from "react";

interface IHeaderDetail{
    title:string;
    link:string;
    title_label:string;
    link_label:string;
    
}
const HeaderDetailView:FC<IHeaderDetail>=({
    title,
    link,
    link_label,
    title_label
})=>{
    return <div className="mt-5">
        <div className="mb-5 flex">
            <label htmlFor="" className="block min-w-[120px]">{title_label}</label>
            <span>{title}</span>
        </div>
        <div className="flex">
            <label htmlFor="" className="block min-w-[120px]">{link_label}</label>
            <span>{link}</span>
        </div>        
    </div>
}
export default HeaderDetailView;