import {
  ColumnDef,
  ColumnFiltersState,
  PaginationState,
  RowSelectionState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { baseObj } from "../../utils/constants";
import classNames from "classnames";
import {
  CancelIcon,
  CheckIcon,
  CloseSmallIcon,
  EditIcon,
  TableSaveIcon,
  TrashIcon,
  ViewIcon,
} from "../common/Icons";
import styles from "./WorkgroupTable.module.scss";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import TablePaginator from "../Tables/TablePaginator";
import DeletePopup from "../DeletePopup/DeletePopup";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import { useNavigate } from "react-router-dom";
import CommonErrorPopup from "../common/CommonErrorPopup";
import { getLanguageCode } from "../../app/services/api";
interface IOTTable {
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  hiddenFields: string[];
  setHiddenFields: any;
  setCurrentView: any;
  total: number;
  changePage?: any;
  setCurrentPage: any;
  currentPage: number;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  isEditing?: boolean;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  localModuleId: number;
  visArray: any;
  setCols?: any;
  setIsSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  isSuccess?: boolean;
  setSkip: React.Dispatch<React.SetStateAction<boolean>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  setEditData?: any;
  deleteWorkgroup?: any;
}

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));

const WorkgroupTable: FC<IOTTable> = ({
  setIsOpenPopup,
  deleteWorkgroup,
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  data,
  setData,
  hiddenFields,
  total,
  changePage,
  setCurrentPage,
  currentPage,
  setIsEdit,
  isEditing,
  setIsTemplate,
  localModuleId,
  visArray,
  setCols,
  setSkip,
  setSorting,
  sorting,
  setEditData,
}) => {
  const languageCode=getLanguageCode();
  const navigate = useNavigate();
  const classes = useStyles();
  const [customClass, setCustomClass] = useState<string>("");
  const columnHelper = useMemo(() => createColumnHelper<any>(), []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [clickLoading, setClickLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [itemCount, setItemCount] = useState<number>(total);
  const selectRowsCount = Object.keys(rowSelection).length;
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [error, setError] = useState<string>('');
  const editRow = useCallback(
    (id: number) => {
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id == id) {
            return { ...data, isNew: true };
          } else {
            return { ...data };
          }

          return data;
        });
      });
    },
    [setData]
  );
  const cancelRow = (id: number) => {
    if (isEditing) {
      setIsEdit(false);
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id == id) {
            return { ...data, isNew: false };
          }
          return data;
        });
      });
    } else {
      const filterData = data?.filter((d: any) => d?.id !== id);
      setData(filterData);
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("name_en", {
        header: "Name (EN)",
        id: "name_en",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" font-normal text-left overflow-hidden">
              <span className="flex items-center h-full">{name}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("name_tc", {
        header: "Name (TC)",
        id: "name_tc",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" font-normal text-left overflow-hidden">
              <span className="flex items-center h-full">{name}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("name_sc", {
        header: "Name (SC)",
        id: "name_sc",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" font-normal text-left overflow-hidden">
              <span className="flex items-center h-full">{name}</span>
            </div>
          );
        },
      }),
      columnHelper.display({
        header: "Action",
        id: `actions`,
        cell: ({ cell }) => {
          var isHaveNew = data?.find((x) => x.isNew == true);
          var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
          const isNew: boolean = cell.row.original?.isNew;
          return (
            <div
              className={classNames(
                "flex justify-center h-full items-center min-w-max " //max-w-[98px]
              )}
            >
              {isNew ? (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      setIsEdit(true);
                    }}
                    className={`w-8 h-8 flex justify-center items-center bg-vorpgreen rounded-md ${isEditing ? " pointer-events-none opacity-40" : ""
                      }`}
                  >
                    <TableSaveIcon />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      cancelRow(Number(cell?.row?.original?.id));
                    }}
                    className={classNames(
                      styles.customCloseIcon,
                      "w-8 h-8 ml-2 mr-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300"
                    )}
                  >
                    <CancelIcon width={18} height={18} />
                  </button>
                </>
              ) : (
                <>
                  <button
                    disabled={isHaveNew ? true : false}
                    type="button"
                    onClick={() => {
                      //   editRow(cell?.row?.original?.id);
                      setEditData(cell?.row?.original);
                      setIsOpenPopup(true);
                    }}
                    className={classNames(
                      disabledClass,
                      "w-8 h-[35px] mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                    )}
                  >
                    <EditIcon />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setDeleteID(cell?.row?.original?.id);
                      setOpenDeleteopup(true);
                    }}
                    className={classNames(
                      disabledClass,
                      "w-8 h-8 mr-2 flex justify-center items-center group rounded-md bg-redPink hover:bg-redPinkLight transition-all duration-300"
                    )}
                  >
                    <TrashIcon />
                  </button>
                </>
              )}
            </div>
          );
        },
      }),
    ],
    [columnHelper, data, hiddenFields, uploading]
  );

  const visibleColumns: { [key: string]: boolean } = {};
  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  document.querySelectorAll("th,td").forEach((x) => {
    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }

    var div = document.getElementById(x.id);
  });

  if (true) {
    visibleColumns["actions"] = true;
  } else {
    visibleColumns["actions"] = false;
  }

  if (hiddenFields.length > 0) {
    columns.map((x) => {
      if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );
  const confirmDeleteData = () => {
    setClickLoading(true);
    deleteWorkgroup(deleteID).then((res: any) => {
      if (res?.data?.status == true) {
        setOpenDeleteopup(false);
        setDeleteID(0);
        setClickLoading(false);
        toast(res?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
        setData(prev => prev.filter(x => x.id != deleteID));
      } else {
        if(res.error){
          setError(res?.error?.data?.message);
        }
        else{
          setError(res?.data?.message);
        }
        
        setUploading(false);
        setOpenDeleteopup(false);
        setClickLoading(false);
      }
      setClickLoading(false);
    }).catch((error: any) => {
      setError(error?.data?.message);
      setUploading(false);
      setOpenDeleteopup(false);
    });;
  };
  return (
    <div
      id="progress-checker-acc"
      className={classNames(styles.otListTable, "relative")}
    >
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <LayoutAdjustableTable
        hideColumn={visibleColumns}
        columns={columns}
        data={data}
        sorting={sorting}
        setSortingState={setSorting}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        unDraggableFields={["actions", "selection", "id"]}
        theaderColor="text-graydark"
        isLayoutControlOpen={isLayoutControlOpen}
        setIsLayoutControlOpen={setIsLayoutControlOpen}
        label="Task Cateogry List"
        setItemCount={setItemCount}
        pagination={pagination}
        setPagination={setPagination}
        globalFilter={globalSearch}
        setGlobalFilter={setGlobalSearch}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        noPaddingCells={["department_id"]}
        localModuleId={localModuleId}
        visArray={visArray}
        setIsTemplate={setIsTemplate}
        setCols={setCols}
      />
      {selectRowsCount > 0 && (
        <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-3 mb-3 lg:mt-3 lg:mb-3 ">
          <p>{selectRowsCount} selected.</p>
          <button
            type="button"
            onClick={() => setOpenDeleteopup(true)}
            className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
          >
            <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-[#FFEAEF]  transition-all duration-300 ">
              <TrashIcon width={10} height={12} />
            </div>
            Delete
          </button>
        </div>
      )}
      <div className="pt-2">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
        />
      </div>
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <DeletePopup
        loading={clickLoading}
        descText="Are you sure to delete?"
        confirmBtnText="Yes, delete."
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
      />
      <CommonErrorPopup
        show={error != "" ? true : false}
        setShow={() => setError("")}
        message={error}
      />
    </div>
  );
};
export default memo(WorkgroupTable);
