import { redirectToLogin } from "./annoucement-sheet";
import { api, language } from "./api";
import authHeader from "./auth-header";
export type Header = {
  id: number;
  title_en: string;
  title_tc: string;
  title_sc: string;
  type: string;
};
export type IBaggageOverview = {
  "id": number,
  "sub_menu_id": number,
  "menu_id": number,
  "slug": string,
  "title_en": string,
  "title_tc": string,
  "title_sc": string,
  "meta_title_en": string,
  "meta_title_tc": string,
  "meta_title_sc": string,
  "meta_description_en": string,
  "meta_description_tc": string,
  "meta_description_sc": string,
  "meta_image": string,
  "banner_mobile_image": string,
  "banner_desktop_image": string,
  "is_slider": number,
}
export type FooterData = {
  footer: {
    footer_description_en: string;
    footer_description_tc: string;
    footer_description_sc: string;
    location_en: string;
    location_tc: string;
    location_sc: string;
    copy_right_text_en: string;
    copy_right_text_tc: string;
    copy_right_text_sc: string;
    logo_image: string;
    phone_en: string;
    phone_tc: string;
    phone_sc: string;
    email_en: string;
    email_tc: string;
    email_sc: string;
    company_image_one: any;
    company_image_two: any;
    company_image_three: any;
  },
  footer_links: IFooterLink[],
  footer_social: []
}
export type IFooterLink = {
  "id": number,
  "title_en": string,
  "title_tc": string,
  "title_sc": string,
  "link_en": string,
  "link_tc": string,
  "link_sc": string,
}
export type PaginationData = {
  page: number;
  limit: number | string | undefined;
  search: string | number;
  sort?: string;
};
export type MenuData = {
  menu_list: Header[];
};
export type BaggagePageData = {
  baggage_tracing: IBaggageOverview;
  located_baggage: LocatedData;
  world_tracer: IWorldTracer;
}
export type LocatedData = {
  "id": number,
  "sub_title_en": string
  "sub_title_tc": string
  "sub_title_sc": string
  "title_en": string,
  "title_tc": string,
  "title_sc": string,
  "description_en": string,
  "description_tc": string,
  "description_sc": string,
  "image": string,
  "additional_data_en": {
    "button1": {
      "link": string,
      "text": string,
    }
  },
  "additional_data_tc": {
    "button1": {
      "link": string,
      "text": string,
    }
  },
  "additional_data_sc": {
    "button1": {
      "link": string,
      "text": string,
    }
  }
}
export type IWorldTracer = {
  "id": number,
  "unique_name": string,
  "sub_title_en": string,
  "sub_title_tc": string,
  "sub_title_sc": string,
  "title_en": string,
  "title_tc": string,
  "title_sc": string,
  "description_en": string,
  "description_tc": string,
  "description_sc": string,
  "image": string,
  "additional_data_en": string,
  "additional_data_tc": string,
  "additional_data_sc": string,
}
export type TData = {
  data: [];
  total: number;
};
export type ISitemap = {
  sitemap: {
    "id": number,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "meta_title_en": string,
    "meta_title_tc": string,
    "meta_title_sc": string,
    "meta_description_en": string,
    "meta_description_tc": string,
    "meta_description_sc": string,
    "meta_image": string,
    "banner_mobile_image": string,
    "banner_desktop_image": string,
  }
}
export interface IPrivacyPage{
  privacy_policy_page:{
    "id": number,
    "page_title_en": string,
    "page_title_tc": string,
    "page_title_sc": string,
    "meta_title_en": string,
    "meta_title_tc": string,
    "meta_title_sc": string,
    "meta_description_en": string,
    "meta_description_tc": string,
    "meta_description_sc": string,
    "meta_image": string,
    "banner_mobile_image": string,
    "banner_desktop_image": string,
    "content_en": string,
    "content_tc": string,
    "content_sc": string,
  }
}
export interface ITermPage{
  terms_page:{
    "id": number,
    "page_title_en": string,
    "page_title_tc": string,
    "page_title_sc": string,
    "meta_title_en": string,
    "meta_title_tc": string,
    "meta_title_sc": string,
    "meta_description_en": string,
    "meta_description_tc": string,
    "meta_description_sc": string,
    "meta_image": string,
    "banner_mobile_image": string,
    "banner_desktop_image": string,
    "content_en": string,
    "content_tc": string,
    "content_sc": string,
  }
}
export interface footerType{
  page_type:string,
  sort?:string,
  search:string,
}
export const footerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFooter: build.query<FooterData, footerType>({
      query: ({page_type,sort,search}) => ({
        url: `footer-page?sort=${sort}&page_type=${page_type}&search=${search}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "Footer" as const, id: "LIST" }],
    }),
    updateFooter: build.mutation<Header, Header>({
      query(body) {
        return {
          headers: authHeader(),
          url: `footer-page`,
          body,
          method: "POST",
        };
      },
    }),
    getFooterData: build.mutation<FooterData, footerType>({
      query({page_type,sort,search}) {
        return {
          headers: authHeader(),
          url: `footer-page?sort=${sort}&page_type=${page_type}&search=${search}`,
          method: "GET",
        };
      },
      invalidatesTags: ['Footer']
    }),
    deleteFooterData: build.mutation<FooterData, number>({
      query(body) {
        return {
          headers: authHeader(),
          url: `delete-footer-link`,
          body: {
            id: body
          },
          method: "POST",
        };
      },
    }),
    fetch_baggage_page: build.query<BaggagePageData, void>({
      query: () => ({
        url: `fetch-baggage-tracing-page`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    fetch_baggage_list: build.query<TData, PaginationData>({
      query: ({ limit, page, search, sort }) => ({
        url: `baggage-list?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
        headers: authHeader()
      }),
      keepUnusedDataFor: 0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "Airline" as const, id: "LIST" }]
    }),
    fetch_baggage_list_info: build.mutation<TData, PaginationData>({
      query({ limit, page, search }) {
        return {
          headers: authHeader(),
          url: `baggage-list?per_page=${limit}&page=${page}&search=${search}`,
          method: "GET",
        };
      },
      invalidatesTags: ['Awards']
    }),
    delete_baggage: build.mutation<TData, number>({
      query(body) {
        return {
          headers: authHeader(),
          url: `delete-baggage-list`,
          body: {
            id: body
          },
          method: "POST",
        };
      },
      invalidatesTags: ['Airline']
    }),
    getFooterSitemap: build.query<ISitemap, void>({
      query: () => ({
        url: `fetch-sitemap-page`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "Footer" as const, id: "LIST" }],
    }),
    getPrivacyPage: build.query<IPrivacyPage, void>({
      query: () => ({
        url: `fetch-privacy-policy`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    getTermsPage: build.query<ITermPage, void>({
      query: () => ({
        url: `fetch-terms`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },      
    }),
  }),
});

export const {
  useGetFooterQuery,
  useUpdateFooterMutation,
  useGetFooterDataMutation,
  useDeleteFooterDataMutation,
  useFetch_baggage_pageQuery,
  useFetch_baggage_listQuery,
  useFetch_baggage_list_infoMutation,
  useDelete_baggageMutation,
  useGetFooterSitemapQuery,
  useGetPrivacyPageQuery,
  useGetTermsPageQuery
} = footerApi;

export const {
  endpoints: { getFooter, updateFooter, getFooterData, deleteFooterData, fetch_baggage_page, fetch_baggage_list,
    fetch_baggage_list_info, delete_baggage, getFooterSitemap,getPrivacyPage,getTermsPage },
} = footerApi;
