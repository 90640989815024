import { FC, useEffect, useState } from "react";
import Button from "../../components/common/Button";
import CompanyHistoryTable from "./CompanyHistoryTable";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { baseObj } from "../../utils/constants";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { twJoin } from "tailwind-merge";
import CompanyHistoryInput, { ICompanyHistoryInputFields } from "./CompanyHistoryInput";
import { useGetCompanyInfoDataMutation, useGetHistoryQuery } from "../../app/services/company/company";
import { endpointUrl } from "../../app/services/api";
import axios from "../../axios";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../components/common/Icons";
import { NumberDropdownList } from "../../components/common/DropdownList";
export interface ICompanyHistory {
    start_year: number;
    end_year: number;
    description: string;
    image: File | undefined;
    image_url: string;
}
export interface ICompanyHistoryData {
    history_list: ICompanyHistory[];
}
const CompanyHistoryList = ({
}) => {
    const [editRowData, setEditRowData] = useState<any>();
    const [detailData, setDetailData] = useState<ICompanyHistoryInputFields>({
        id: 0,
        start_year: 0,
        end_year: 0,
        image: undefined,
        image_url: '',
        description_en: '',
        description_tc: '',
        description_sc: '',
    });
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [viewCols, setCols] = useState<any>([]);
    const [isLayoutControlOpen, setIsLayoutControlOpen] = useState<boolean>(
        false
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    let [localModuleId, setLocalModuleId] = useState<number>(0);
    const [data, setData] = useState<baseObj[]>([]);
    const [itemCount, setItemCount] = useState<number>(0);
    const [tempList, setTempList] = useState<any>(templateList);
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [getHistoryList] = useGetCompanyInfoDataMutation();
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20
    });
    const { data: history } = useGetHistoryQuery({
        limit: pagination.pageSize,
        page: currentPage,
        search: globalSearch,
        sort: sorting?sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(','):'',
    });
    useEffect(() => {
        if (history) {
            setData(history?.data);
            setItemCount(history?.total);
        }
    }, [history]);
    const clear_data = () => {
        setDetailData({
            id: 0,
            start_year: 0,
            end_year: 0,
            image: undefined,
            image_url: '',
            description_en: '',
            description_tc: '',
            description_sc: '',
        })
    }
    const handle_submit = async (data: ICompanyHistoryInputFields | undefined) => {
        const formData = new FormData();
        formData.append('id', data?.id?.toString() ?? '0');
        formData.append('start_year', data?.start_year?.toString() ?? '0');
        formData.append('end_year', data?.end_year?.toString() ?? '0');
        formData.append('description_en', data?.description_en ?? '');
        formData.append('description_tc', data?.description_tc ?? '');
        formData.append('description_sc', data?.description_sc ?? '');
        if (data?.image) {
            formData.append('image', data?.image ?? '');
        }

        const url = data?.id ? 'update-our-journey-content' : 'our-journey-content';
        await axios(endpointUrl + url, {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
            .then((res: any) => {
                if (res?.data?.status == true) {
                    getHistoryList({
                        page: 1,
                        limit: 20,
                        search: '',
                        sort:''
                    }).then((res: any) => {                        
                        setData(res?.data?.data);
                        setItemCount(res?.data?.total);
                    })
                    toast(res?.data?.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        type: "success",
                    });
                    setShow(true);
                } else {
                    setErrorPopup(true);
                    setMessage(res?.data?.message);
                }
                setIsSave(false);
            })
            .catch((err) => {
                setErrorPopup(true);
                setMessage(Object.values(err?.response?.data?.errors)?.join(' '));
            });
    }
    return <div
        className={twJoin(
            "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
        )}
    >

        <ToastContainer className={"text-12 font-poppins font-normal"} />
        <CommonErrorPopup
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
        />
        <div className="text-left">
            {show == false ? <CompanyHistoryInput history={detailData} setHistory={setDetailData} setShow={setShow} handle_submit={handle_submit} isSave={isSave} /> :
                <>
                    <div className="flex justify-between items-center">
                        <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
                            <DebounceSearchInput
                                setState={setGlobalSearch}
                                debounce={800}
                            >
                                {(setState) => (
                                    <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px] sm:w-[260px] w-[225px]">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                                            onChange={(e) => setState(e.target.value)}
                                        />
                                        <SearchIcon className="mr-2" />
                                    </div>
                                )}
                            </DebounceSearchInput>
                        </div>
                        <Button
                            label="+ Add New"
                            onClick={() => { clear_data(); setShow(false) }}
                            type="button"
                            variant="primary"
                            customClass="py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                            size="base"
                        />
                    </div>
                    <div className="flex sm:flex-row flex-col justify-end mb-1">
                        <div className="flex items-center sm:justify-start justify-end">
                            <div className="flex items-center 3xl:ml-3 mr-[10px]">
                                <p className="xl:text-sm text-12 mr-2 text-black2 font-primary">
                                    DISPLAY
                                </p>
                                <NumberDropdownList
                                    state={pagination.pageSize}
                                    setState={value =>
                                        setPagination(prev => ({
                                            ...prev,
                                            pageSize: value as number
                                        }))}
                                    dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                                        const count = (index + 1) * 20;
                                        return {
                                            value: count,
                                            label: String(count)
                                        };
                                    })}
                                    className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                                    textAlign="text-left pl-3"
                                    paddingClass="py-[2px]"
                                    placeholder="All"
                                />
                            </div>
                        </div>
                    </div>
                    <CompanyHistoryTable
                        // columnsData={columnsData}
                        isLayoutControlOpen={isLayoutControlOpen}
                        setIsLayoutControlOpen={setIsLayoutControlOpen}
                        globalSearch={globalSearch}
                        setGlobalSearch={setGlobalSearch}
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}
                        pagination={pagination}
                        data={data}
                        setData={setData}
                        setPagination={setPagination}
                        hiddenFields={hiddenFields ? hiddenFields : []}
                        setHiddenFields={setHiddenFields}
                        checkManagement={true}
                        total={itemCount}
                        localModuleId={localModuleId}
                        // visArray={visArray}
                        templateList={tempList}
                        setCurrentView={() => 1}
                        setCols={setCols}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setEditRowData={setEditRowData}
                        editRowData={editRowData}
                        setShowList={setShow}
                        setDetailData={setDetailData}
                        sorting={sorting}
                        setSorting={setSorting}
                    />
                </>
            }
        </div>
    </div>
}
export default CompanyHistoryList;