import { FC } from "react";
import { IMenu } from "./HeaderSectionDetail";
import HeaderDetailView from "./HeaderDetailView";
import HeaderSubmenuList from "./HeaderSubmenuList";
import HeaderSection from "./HeaderSection";
import HeaderSubmenuSection from "./HeaderSubmenuSection";

interface IHeaderSubmenuView {
    isShow: boolean;
    data: IMenu | undefined;
    activeLang: string;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    detailData: any;
    setDetailData: React.Dispatch<any>;
    title_label:string;
    link_label:string;
    setShowList: React.Dispatch<React.SetStateAction<boolean>>;
    setShowEditDetail: React.Dispatch<React.SetStateAction<boolean>>
}
const HeaderSubmenuView: FC<IHeaderSubmenuView> = ({
    isShow,
    data,
    setShow,
    activeLang,
    detailData,
    setDetailData,
    link_label,
    title_label,
    setShowList,
    setShowEditDetail
}) => {
    const render_title = () => {
        if (activeLang == 'en') {
            return {
                title: data?.title_en,
                link: data?.slug
            }
        }
        if (activeLang == 'tc') {
            return {
                title: data?.title_tc,
                link: data?.slug
            }
        }
        if (activeLang == 'sc') {
            return {
                title: data?.title_sc,
                link: data?.slug
            }
        }
    }
    return <div>
        {isShow == false ?
            <>
                <HeaderDetailView title_label={title_label} link_label={link_label} title={render_title()?.title ?? ''} link={data?.slug ?? ''} />
                <HeaderSubmenuList submenu={data?.sub_menus ?? []} setShowList={setShowEditDetail} setDetailData={setDetailData}/>
            </> :
            <HeaderSubmenuSection
                initialData={detailData}
                setValue={setDetailData}
                setShowList={setShowList}
                setShowEditDetail={setShowEditDetail}
                setShow={setShow}
            />}
    </div>
}
export default HeaderSubmenuView;