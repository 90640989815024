import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndNumber from "../../components/SeoData/LabelAndNumber";
import { IAirlineInfo, ITitleDescription, IWhyChoose } from "../../app/services/home/home";
interface IData {
    activeLang: string;
    initialData: any;
    setValue: React.Dispatch<React.SetStateAction<ITitleDescription>>;
    title:string;
    isShowButtonText?:boolean;
    isShowButtonLink?:boolean;
    isCancel?:boolean;
  }
const TitleDescriptionBoxForNews: FC<IData> = ({ activeLang, initialData,title,setValue,isShowButtonText,
  isShowButtonLink,isCancel }) => {
    const [history, setHistory] = useState<any>([]);
    const [current, setCurrent] = useState(0);
  const [data,setData]=useState<ITitleDescription>({
    description_en:'',
    description_tc:'',
    description_sc:'',
    sub_title_en:'',
    sub_title_tc:'',
    sub_title_sc:'',
    title_en:'',
    title_tc:'',
    title_sc:'',
    news_btn_link_en:'',
    news_btn_link_tc:'',
    news_btn_link_sc:'',
    news_btn_text_en:'',
    news_btn_text_tc:'',
    news_btn_text_sc:'',
  });
  const DescriptionRef_en: any = useRef(null);
  const DescriptionRef_tc: any = useRef(null);
  const DescriptionRef_sc: any = useRef(null);
  useEffect(()=>{
    if(initialData){
      setData({
        description_en:initialData?.description_en??'',
        description_tc:initialData?.description_tc??'',
        description_sc:initialData?.description_sc??'',
        sub_title_en:initialData?.sub_title_en??'',
        sub_title_tc:initialData?.sub_title_tc??'',
        sub_title_sc:initialData?.sub_title_sc??'',
        title_en:initialData?.title_en??'',
        title_tc:initialData?.title_tc??'',
        title_sc:initialData?.title_sc??'',
        news_btn_link_en:initialData?.news_btn_link_en,
        news_btn_link_tc:initialData?.news_btn_link_tc,
        news_btn_link_sc:initialData?.news_btn_link_sc,
        news_btn_text_en:initialData?.news_btn_text_en,
        news_btn_text_tc:initialData?.news_btn_text_tc,
        news_btn_text_sc:initialData?.news_btn_text_sc,
      });
    }
  },[initialData]);
  const change_data=(value:any,key:string)=>{
    if(data){
      //setData({...data,[key]:value});
    }
    if(initialData){
      if(setValue){
        setValue({...initialData,[key]:value});
      }      
    }
  }
  return (
    <div>
      <h2 className="text-15 text-black font-bold text-left my-5">
        {title}
      </h2>
      <div className="mt-5">
        <div
          className={twJoin(
            activeLang == "en"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >         
        <LabelAndTextbox
            label="Subtitle (EN)"
            value={data?.sub_title_en??''}
            setValue={(value)=>change_data(value,'sub_title_en')}
          />
         
          <LabelAndTextbox
            label="Title (EN)"
            value={data?.title_en??''}
            setValue={(value)=>change_data(value,'title_en')}
          />

          <LabelAndTextbox
            refId={DescriptionRef_en}
            label="Description (EN)"
            value={data?.description_en}
            setValue={(value)=>change_data(value,'description_en')}
          />     
          <div className={twJoin(isShowButtonText==false?'hidden':'')}>
           <LabelAndTextbox
            label="Button Text (EN)"
            value={data?.news_btn_text_en??''}
            setValue={(value)=>change_data(value,'news_btn_text_en')}
          />   
          </div>  
          <div className={twJoin(isShowButtonLink==false?'hidden':'mt-5')}>
          <LabelAndTextbox
            label="Button Link (EN)"
            value={data?.news_btn_link_en??''}
            setValue={(value)=>change_data(value,'news_btn_link_en')}
          />   
           </div>  
        </div>
        <div
          className={twJoin(
            activeLang == "tc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <LabelAndTextbox
            label="Subtitle (TC)"
            value={data?.sub_title_en??''}
            setValue={(value)=>change_data(value,'sub_title_en')}
          />
            <LabelAndTextbox
            label="Title (TC)"
            value={data?.title_tc}
            setValue={(value)=>change_data(value,'title_tc')}
          />
          <LabelAndTextbox
            refId={DescriptionRef_tc}
            label="Description (TC)"
            value={data?.description_tc}
            setValue={(value)=>change_data(value,'description_tc')}
          /> 
          <div className={twJoin(isShowButtonText==false?'hidden':'')}>  
          <LabelAndTextbox
            label="Button Text (TC)"
            value={data?.news_btn_text_tc??''}
            setValue={(value)=>change_data(value,'news_btn_text_tc')}
          />   </div>    
          <div className={twJoin(isShowButtonLink==false?'hidden':'mt-5')}>
          <LabelAndTextbox
            label="Button Link (TC)"
            value={data?.news_btn_link_tc??''}
            setValue={(value)=>change_data(value,'news_btn_link_tc')}
          />      </div>
        </div>
        <div
          className={twJoin(
            activeLang == "sc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
          <LabelAndTextbox
            label="Subtitle (SC)"
            value={data?.sub_title_sc??''}
            setValue={(value)=>change_data(value,'sub_title_sc')}
          />
             <LabelAndTextbox
            label="Title (SC)"
            value={data?.title_sc}
            setValue={(value)=>change_data(value,'title_sc')}
          />
          <LabelAndTextbox
            refId={DescriptionRef_sc}
            label="Description (SC)"
            value={data?.description_sc}
            setValue={(value)=>change_data(value,'description_sc')}
          />         
          <div className={twJoin(isShowButtonText==false?'hidden':'mt-5')}>  
          <LabelAndTextbox
            label="Button Text (SC)"
            value={data?.news_btn_text_sc??''}
            setValue={(value)=>change_data(value,'news_btn_text_sc')}
          />   
          </div>
          <div className={twJoin(isShowButtonLink==false?'hidden':'mt-5')}>  
          <LabelAndTextbox
            label="Button Link (SC)"
            value={data?.news_btn_link_sc??''}
            setValue={(value)=>change_data(value,'news_btn_link_sc')}
          />   
           </div>
        </div>
      </div>
    </div>
  );
};
export default TitleDescriptionBoxForNews;
