import { redirectToLogin } from "../annoucement-sheet";
import { api } from "../api";
import authHeader from "../auth-header";
export interface ISustainabilityApiInfo {
    "commitment":ICommitment;
    "sustainable": IAwardInfo,
    
}
export interface IAwardInfo {
    "id": number,
    "sub_menu_id": number,
    "menu_id": number,
    "slug": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "meta_title_en": string,
    "meta_title_tc": string,
    "meta_title_sc": string,
    "meta_description_en": string,
    "meta_description_tc": string,
    "meta_description_sc": string,
    "meta_image": string,
    "banner_mobile_image": string,
    "banner_desktop_image": string,
}
export interface ICommitment{
    "id": number,
    "sub_title_en": string,
    "sub_title_tc": string,
    "sub_title_sc": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "description_en": string,
    "description_tc": string,
    "description_sc": string,
    "image": string,
    "additional_data_en": string,
    "additional_data_tc": string,
    "additional_data_sc": string,
}

export type TData = {
    data: [];
    total: number;
};
export type PaginationData = {
    page: number;
    limit: number | string | undefined;
    search: string | number;
    sort?: string;
    type?: string;
};
export const sustainabilityApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSustainability: build.query<ISustainabilityApiInfo, void>({
            query: () => ({
                url: `fetch-sustainable-page`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Sustainability" as const, id: "LIST" }]
        }),
        getSustainabilityList: build.query<TData, PaginationData>({
            query: ({ limit, page, search, sort }) => ({
                url: `sustainable-content?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Sustainability" as const, id: "LIST" }]
        }),
        getSustainabilityInfo: build.mutation<TData, PaginationData>({
            query({ limit, page, search, type, sort }) {
                return {
                    headers: authHeader(),
                    url: `sustainable-content?type=${type}&per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['Sustainability']
        }),
        deleteSustainability: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `delete-sustainable-content`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['Sustainability']
        }),
    })
});

export const { useGetSustainabilityQuery, useGetSustainabilityListQuery, useGetSustainabilityInfoMutation, useDeleteSustainabilityMutation } = sustainabilityApi;

export const {
    endpoints: { getSustainability, getSustainabilityList, getSustainabilityInfo, deleteSustainability }
} = sustainabilityApi;
