import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import { useState } from "react";
import ResourceSectionList from "../components/resources/ResourceSectionList";
import Resources, { ResurceType } from "../components/Resources";

const ServicePage = () => {
  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pb-8 sm:pb-9 "
      )}
    >
       <ResourceSectionList/>
    </div>
  );
};
export default ServicePage;
