import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
} from "react";
// import {
//   Box,
//   ClickAwayListener,
//   makeStyles,
//   Popper,
//   PopperPlacementType,
//   Tooltip,
// } from "@material-ui/core";

import { makeStyles } from '@mui/styles';
import { Box, Popper, Tooltip,ClickAwayListener } from "@mui/material"
// import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useSpring, animated } from 'react-spring'


import styles from "./EmployeeTable.module.scss";
import clsx from "clsx";
interface OptionsList {
  id: number;
  title: string;
  value: string;
}
interface ICustomDropdownList {
  options: OptionsList[];
  title?: string;
  id?: string;
  value?: number;
  label: string;
  state: any;
  setState: any;
  className?: string;
  customListStyle?: string;
}
const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));
interface ICurrentData {
  id: string;
  open: boolean;
}

interface FadeProps {
  children?: React.ReactElement;
  in?: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const Fade = forwardRef<HTMLDivElement, FadeProps>(function Fade(props: any, ref: any) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const CustomDropdownListNormal: FC<ICustomDropdownList> = ({
  options,
  title,
  value,
  state,
  setState,
  id,
  customListStyle
}) => {
  const [placement, setPlacement] = useState<any>();
  // const [placement, setPlacement] = useState<PopperPlacementType>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [currentPopup, setCurrentPopup] = useState<ICurrentData[]>([
    {
      id: "",
      open: false,
    },
  ]);
  const mainContainer = useRef<HTMLDivElement | null>(null);
  
  const [width, setWidth] = useState<number>(0);
  useEffect(() => {
    setWidth(mainContainer.current?.clientWidth as number);
    const resizer: any = window.addEventListener("resize", () =>
      setWidth(mainContainer.current?.clientWidth as number)
    );
    return () => window.removeEventListener("resize", resizer);
  }, []);

  const handleClick = (
    newPlacement: any,
    // newPlacement: PopperPlacementType,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setOpen(!open);

    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
    event.stopPropagation();
    event.preventDefault();
  };

  const handleClickAway = (e: any, currentID: any) => {
    setOpen(false);
    // e.preventDefault();
  };

  const canBeOpen = open && Boolean(anchorEl);
  const ids = canBeOpen ? 'spring-popper' : undefined;


  return (
    <div ref={mainContainer} id={id} className="h-full w-full ">
      <ClickAwayListener onClickAway={(e) => handleClickAway(e, id)}>
        <Box sx={{ position: "relative" }} className="h-full">
          <button
            onClick={(event) => {
              handleClick("bottom-start", event);
              // setOpen(!open);
            }}
            className={
              "flex h-full min-h-[35px] w-full items-center justify-between rounded-[4px] border border-lightGrey bg-white  p-3 px-[10px] transition-all duration-300"
            }
          >
            <p
              className={clsx(
                "w-[90%] whitespace-nowrap font-main text-left",
                styles.customTextOverflow
              )}
            >
              {state ? state : "Please Select"}
            </p>
            <svg
              width="10"
              height="5"
              viewBox="0 0 10 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0L5 5L10 0H0Z" fill="black" />
            </svg>
          </button>

          {
            open ? (
              <Popper id={ids} open={open} anchorEl={anchorEl} transition className=" poppuerCustom">
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps}>
                    <div className="w-full">
                      <div
                        style={{ width }}
                        className={clsx(
                          "rounded-4 border-1 z-10 w-full overflow-hidden border-[#CECECE] bg-white"
                        )}
                      >
                        <div className="flex max-h-[222px] w-full flex-col overflow-y-auto bg-white">
                          {options?.map((item, key: number) => (
                            <div
                              key={key}
                              className={clsx(
                                item.id == 0 ? "hidden" : "flex",
                                " hover:bg-vorpblue hover:text-[#fff] text-graydark flex-row items-center justify-between"
                              )}
                            >
                              <button
                                type="button"
                                onClick={() => {
                                  setState(item.title);
                                  setOpen(false);
                                }}
                                key={item.id * Math.random() * 100}
                                className={clsx(styles.customButton, " w-full group flex min-h-[38px] items-center justify-between text-left font-main  font-medium capitalize  leading-5 transition-all duration-300 focus:outline-none", customListStyle ? customListStyle : 'text-14 py-2 px-5')}
                              >
                                {item.title}
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Fade>
                )}
              </Popper>
            ) : null
          }

          {/* {open ? (
            <Popper
              open={open}
              anchorEl={anchorEl}
              placement={placement}
              className="poppuerCustom"
              transition
            >
              <div className="w-full">
                <div
                  style={{ width }}
                  className={clsx(
                    "rounded-4 border-1 z-10 w-full overflow-hidden border-[#CECECE] bg-white"
                  )}
                >
                  <div className="flex max-h-[222px] w-full flex-col overflow-y-auto bg-white">
                    {options?.map((item, key: number) => (
                      <div
                        key={key}
                        className={clsx(
                          item.id == 0 ? "hidden" : "flex",
                          " hover:bg-vorpblue hover:text-[#fff] text-graydark flex-row items-center justify-between"
                        )}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            setState(item.title);
                            setOpen(false);
                          }}
                          key={item.id * Math.random() * 100}
                          className={clsx(styles.customButton, " w-full group flex min-h-[38px] items-center justify-between text-left font-main  font-medium capitalize  leading-5 transition-all duration-300 focus:outline-none", customListStyle ? customListStyle : 'text-14 py-2 px-5')}
                        >
                          {item.title}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Popper>
          ) : null} */}

        </Box>
      </ClickAwayListener>
    </div>
  );
};

export default CustomDropdownListNormal;
