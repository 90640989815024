import { twJoin } from "tailwind-merge";
import FooterSocialMedia, { ISocialMedia } from "./FooterSocialMedia";
import { useEffect, useState } from "react";
import FooterSocialMediaInput from "./FooterSocialMediaInput";
import FooterBottom from "./FooterBottom";
import FooterBottomForm, { IFooterBottomInput } from "./FooterBottomForm";
import { FooterData, IFooterLink, useGetFooterDataMutation, useGetFooterQuery } from "../../app/services/footer";
interface IFooterSocialMedia {
  data: ISocialMedia[];
}
const FooterList = () => {
  const [showFooterBottomCRUD, setShowBottomCRUD] = useState<boolean>(false);  
  
  const [footerData,setFooterData]=useState<IFooterLink[]>([]);
  const [footerBottomDetailData, setFooterBottomDetailData] = useState<
    IFooterBottomInput | undefined
  >();
  // useEffect(()=>{
  //   if(footer){
  //     setFooterData(footer?.footer_links);
  //   }
  // },[footer])
  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
      <div className="">
        {/* <h2 className="text-sm text-black font-medium mb-3">Footer</h2> */}
        {showFooterBottomCRUD == true
          ? <FooterBottomForm
              footerData={footerBottomDetailData}
              setShow={setShowBottomCRUD}
              setFooterData={setFooterData}
            />
          : <FooterBottom
          //footer={footerData}
              setShow={setShowBottomCRUD}
              setDetailData={setFooterBottomDetailData}
            />}
      </div>
    </div>
  );
};
export default FooterList;
