import { FC, useEffect, useRef, useState } from "react";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
export interface IOverviewData {
  overview: ICSROverview;
  activeLang: string;
  setActiveLang: React.Dispatch<React.SetStateAction<string>>;
  setOverview: React.Dispatch<React.SetStateAction<ICSROverview>>;
}
export interface ICSROverview {
  sub_title_en: string;
  sub_title_tc: string;
  sub_title_sc: string;

  title_en: string;
  title_tc: string;
  title_sc: string;

  description_en: string;
  description_tc: string;
  description_sc: string;
  image: File|undefined;
  image_url: string;
}
const CSROverviewInput: FC<IOverviewData> = ({
  overview,
  activeLang,
  setActiveLang,
  setOverview
}) => {
  const description_en_Ref = useRef();
  const description_tc_Ref = useRef();
  const description_sc_Ref = useRef();
  const [data, setData] = useState<ICSROverview>({
    sub_title_en:'',
      sub_title_tc:'',
      sub_title_sc:'',    
      title_en:'',
      title_tc:'',
      title_sc: '',  
      description_en:'',
      description_tc:'',
      description_sc: '',   
      image:undefined,
      image_url:''
  });
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  useEffect(() => {
    if (overview) {
      setData(overview);
    }
  }, [overview]);
  const change_data = (value: any, key: string) => {
    if (overview) {
      if(key=="image"){
        setOverview((prev)=>{
          return {
            ...prev,
            image:value,
            image_url:value?URL.createObjectURL(value):'',
          }
        });
        return;
      }
      setOverview({ ...overview, [key]: value });
    }
  };
  return (
    <div>
      <div className="mt-5">
        <div
          className={
            "space-y-5" +
            twJoin(
              activeLang == "en"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )
          }
        >
          <LabelAndTextbox
            label="Sub Title (EN)"
            value={data?.sub_title_en}
            setValue={(value) => change_data(value, "sub_title_en")}
          />
          <LabelAndTextbox
            label="Title (EN)"
            value={data?.title_en}
            setValue={(value) => change_data(value, "title_en")}
          />
          <LabelAndEditor
            refId={description_en_Ref}
            label="Description (EN)"
            value={data?.description_en}
            setValue={(value) => change_data(value, "description_en")}
          />
        </div>

        <div
          className={twJoin(
            activeLang == "tc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
           <LabelAndTextbox
            label="Sub Title (TC)"
            value={data?.sub_title_tc}
            setValue={(value) => change_data(value, "sub_title_tc")}
          />
          <LabelAndTextbox
            label="Title (TC)"
            value={data?.title_tc}
            setValue={(value) => change_data(value, "title_tc")}
          />
          <LabelAndEditor
            refId={description_tc_Ref}
            label="Description (TC)"
            value={data?.description_tc}
            setValue={(value) => change_data(value, "description_tc")}
          />
        </div>
        <div
          className={twJoin(
            activeLang == "sc"
              ? ""
              : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
          )}
        >
           <LabelAndTextbox
            label="Sub Title (SC)"
            value={data?.sub_title_sc}
            setValue={(value) => change_data(value, "sub_title_sc")}
          />
          <LabelAndTextbox
            label="Title (SC)"
            value={data?.title_sc}
            setValue={(value) => change_data(value, "title_sc")}
          />
          <LabelAndEditor
            refId={description_sc_Ref}
            label="Description (SC)"
            value={data?.description_sc}
            setValue={(value) => change_data(value, "description_sc")}
          />
        </div>
        <div className="mt-5">
        <LabelAndImage
          margin="my-5"
          setFile={(value: any) => change_data(value, "image")}
          label="Image"
          value={data?.image_url}
        />
        </div>
      </div>
    </div>
  );
};
export default CSROverviewInput;
