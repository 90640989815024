// import { Popper } from "@material-ui/core";
// import { Popper } from "@mui/material"
// hello

import {
  Cell,
  ColumnDef,
  ColumnFiltersState,
  createColumnHelper,
  PaginationState,
  // RowData,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import classNames from "classnames";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  TemplateData,
  useCreateCountryMutation,
  useCreateCurrencyMutation,
  useCreateDepartmentMutation,
  useCreateDesignationMutation,
  useCreateEmployeeMutation,
  useEnableEmployeeMutation,
  useGetCountryListQuery,
  useGetCurrencyListQuery,
  useGetDepartmentListQuery,
  useGetDesignationListQuery,
  useRemoveCountryMutation,
  useRemoveCurrencyMutation,
  useRemoveDepartmentMutation,
  useRemoveDesignationMutation,
  useSendEmailMutation,
  useUpdateEmployeeMutation,
} from "../../app/services/employee";
import { baseObj } from "../../utils/constants";
import {
  // AddIcon,
  CancelIcon,
  // ChevronDown,
  EditIcon,
  ShareIcon,
  TableSaveIcon,
  TrashIcon,
  ViewIcon,
} from "../common/Icons";
// import CustomSingleDropdown from "../CustomSingleDropdown/CustomSingleDropdown";
import {
  // renderDateFormat,
  renderDateFormatDMY,
  // renderDateFormatMDY,
} from "../DashboardCalendarView/DashboardAddEventPopup";
import DeletePopup from "../DeletePopup/DeletePopup";
// import EnableSwitch from "../Projects/ProjectCompleteSwitch";
import ReportToPopup from "../ReportToPopup/ReportToPopup";
import SharePopup from "../SharePopup/SharePopup";
import EditableCell from "../Tables/EditableCell";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import TablePaginator from "../Tables/TablePaginator";
import CustomDropdownWithAddButton from "./CustomDropdownWithAddButton";
import DatePopup from "./DatePopup";
// import DepartmentDropdownList from "./DepartmentDropdownList";
import styles from "./EmployeeTable.module.scss";
// import { getAuthUser } from "../../app/services/dashboard";
import { templateData } from "../../app/services/template";
// import SuccessPopup from "../SuccessPopup/SuccessPopup";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
// import { NavLink } from "react-router-dom";
// import { deleteCurrency } from "../../app/services/project";
import CommonErrorPopup from "../common/CommonErrorPopup";
import DOMPurify from "dompurify";
import Table from "../Tables/Tables.module.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EnableSwitch } from "../BackendUsers/BackendUsersTable";
import ToggleSwitch from "../common/ToggleSwitch";

export interface StoreEmployeeType {
  employeeID: string;
  id: number;
  name: string;
  preferredName: string;
  email: string;
  mobile: string;
  joinedDate: string;
  department_id: number;
  designation_id: number;
  currency_id: number;
  position: string;
  // reportTo: number;
  report_to: number;
  currency: string;
  salary: number;
  dob: string;
  gender: string;
  race: string;
  religion: string;
  country_id: number;
  nationalityID: string;
  passportNo: string;
  device: string;
  bank: string;
  bankAccNo: string;
  status: string;
  
}

export type RegionResponse = {
  region: EmployeeData[];
};

export interface EmployeeData {
  id: number;
  name: string;
  label: string;
  isChecked?: boolean;
  isCheck?: boolean;
  project_id?: number;
  isDone?: boolean;
  amount?: number;
  uniqeID?: number;
  //for keydate
  key_id?: number;
  date?: string | null;
  flag?: string;
  rowId?: number;
  region_id?: number;
  region_name?: string;
  // region?: any,
}

export interface GenderData {
  id: number;
  name: string;
  label: string;
}

export interface MembereData {
  id: number;
  name: string;
  label: string;

  preferred_name?: string;
  year_w_vo?: number;
  age?: number;
}

export type TPayment = {
  id?: number;
  project_status_id?: number | string;
  amount?: number;
  payment_due_date?: string | null;
  payment_received_date?: string | null;
  invoice_no?: string;
  payment_received_no?: string;
  is_completed: number;
};

export type TCheck = {
  label: string;
  is_checked: number;
};

export type TKeyDate = {
  key_id: number;
  key_date: string;
};

export type TSaveProject = {
  id?: number | string;
  project_name: string | number;
  company_name: string | number;
  primary_contact_person_name: string | number;
  secondary_contact_person_email: string | number;
  primary_contact_person_email: string | number;
  secondary_contact_person_name: string | number;

  primary_contact_person_phone: string;
  primary_contact_person_dial_code: string;
  secondary_contact_person_dial_code: string;
  secondary_contact_person_phone: string;
  finance_contact_person_name: string | number;
  finance_contact_person_dial_code: string;
  finance_contact_person_phone: string;
  team_leader_id: number | string;
  project_manager_id: number | string;
  region_id: number | string;
  department_id: number | string;
  duration_type: string;
  duration: number | string;
  scope: string | number;
  quotation_no: string | number;
  brand_level: number | string;
  project_level: number | string;
  project_type_ids: number[];
  status_id: number | string;
  currency_id: number | string;
  total_amount: number | string;
  payment_term: number | string;
  payment_status_id: number | string;
  payments: TPayment[];
  confirm_date: string | null | undefined;
  start_date: string | null | undefined;
  end_date: string | null | undefined;
  is_completed: number | string;
  remark: string | number;
  check_lists: TCheck[];
  key_dates: TKeyDate[];
};

export interface EmployeeListData {
  name?: string;
  uniqeID: number;
  id: number;
  employeeID: string;
  realName: string;
  preferredName: string;
  email: string;
  mobile: string;
  joinedDate: string;
  years: string;
  department: string;
  designation: string;
  report_to: string | number;
  // reportTo: string;
  salary: string;
  dob: string;
  age: string;
  gender: string;
  race: string;
  religion: string;
  nationality: string;
  nationalityID: string;
  passportNo: string;
  device: string;
  bank: string;
  bankAccNo: string;
  status: string;
}

export interface EmployeeListDataCap {
  EmployeeID: string;
  RealName: string;
  PreferredName: string;
  Email: string;
  Mobile: string;
  JoinedDate: string;
  Years: string;
  Department: string;
  Designation: string;
  Report_to: string | number;
  // ReportTo: string;
  Salary: string;
  DateOfBirth: string;
  Age: string;
  Gender: string;
  Race: string;
  Religion: string;
  Nationality: string;
  NationalityID: string;
  PassportNo: string;
  Device: string;
  Bank: string;
  BankAccNo: string;
  Status: string;
}

interface IEmployeeTable {
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  orgDataList: EmployeeListData[];
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  deleteEmployee: any;
  deleteEmployeeByIDs: any;
  hiddenFields: string[];
  // templateList: TemplateData[];
  setHiddenFields: any;
  setCurrentView: any;
  genderData: EmployeeData[];
  setGenderData: Dispatch<SetStateAction<EmployeeData[]>>;
  statusData: EmployeeData[];
  setStatusData: Dispatch<SetStateAction<EmployeeData[]>>;
  memberData: EmployeeData[];
  setMemberData: Dispatch<SetStateAction<EmployeeData[]>>;
  localModuleId: number;
  templateList: templateData[];
  total: number;
  changePage?: any;
  setCurrentPage: any;
  currentPage: number;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  isEditing?: boolean;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  setCols?: any;
  checkManage: boolean;
  setLeaveID: React.Dispatch<React.SetStateAction<number>>;
  setShowLeaveAllowance: Dispatch<SetStateAction<boolean>>;
  allowanceSuccess: boolean;
  addNew: boolean;
  setEmployeeID: Dispatch<SetStateAction<string>>;
  getAllowance: (id: number) => void;
  setLeaveAllowanceList: any;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  setEditData?: React.Dispatch<any>;
  empDepartmentData:EmployeeData[];
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IEnableSwitch {
  initialState: boolean;
  id: string;
  setData: Dispatch<SetStateAction<baseObj[]>>;
  cell: Cell<any, any>;
  changeUserStatusData: (id: string, value?: any) => void;
  loading: any;
}

export const EmployeeEnableSwitch: FC<IEnableSwitch> = ({
  initialState,
  id,
  setData,
  cell,
  changeUserStatusData,
  loading,
}) => {
  const [isEnable, setIsEnable] = useState<boolean>(initialState);
  const editCell = useCallback(
    (value: boolean) => {
      setData((prev: baseObj[]) => {
        return prev.map((data: baseObj, index: number) => {
          if (index === Number(cell.row.id)) {
            changeUserStatusData(data.uniqeID, value);
            return { ...data, [cell.column.id]: value };
          }
          return data;
        });
      });
    },
    [setData, cell, changeUserStatusData]
  );

  return (
    <div className="max-w-[120px] flex">
      <ToggleSwitch
        id={id}
        state={isEnable}
        setState={setIsEnable}
        updateData={editCell}
      />
      {/* <div
        id={id?.toString() + cell.row.original.uniqeID?.toString()}
        className={classNames(styles.loader, loading ? "" : "hidden")}
      ></div> */}
      {/* {loading ? (
        // <div
        //   id={id?.toString() + (cell.row.original.uniqeID)?.toString()}
        //   className={classNames(styles.loader, "")}
        // ></div>
      ) : (
        // <ToggleSwitch
        //   id={id}
        //   state={isEnable}
        //   setState={setIsEnable}
        //   updateData={editCell}
        // />
        // <div
        //   id={id?.toString() + (cell.row.original.uniqeID)?.toString()}
        //   className={classNames(styles.loader, "")}
        // ></div>
      )} */}
    </div>
  );
};

// interface DropdownFieldData {
//   key: string;
//   value: string;
// }
const EmployeeTableV2: FC<IEmployeeTable> = ({
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  data,
  setData,
  // orgDataList,
  deleteEmployee,
  deleteEmployeeByIDs,
  hiddenFields,
  templateList,
  setHiddenFields,
  setCurrentView,
  genderData,
  setGenderData,
  statusData,
  setStatusData,
  memberData,
  // setMemberData,
  localModuleId,
  total,
  // changePage,
  setCurrentPage,
  currentPage,
  setIsEdit,
  isEditing,
  setIsTemplate,
  setCols,
  checkManage,
  setLeaveID,
  setShowLeaveAllowance,
  allowanceSuccess,
  addNew,
  setEmployeeID,
  getAllowance,
  empDepartmentData,
  sorting,
  setSorting,
  setEditData,
  setIsOpen
}) => {
  const columnHelper = useMemo(() => createColumnHelper<any>(), []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  // const [itemCount, setItemCount] = useState<number>(data.length);
  const [itemCount, setItemCount] = useState<number>(total);
  const selectRowsCount = Object.keys(rowSelection).length;
  const [department, setDepartment] = useState("All");
  const [designate, setDesignation] = useState("All");
  const [currency, setCurrency] = useState("All");
  const [national, setNationality] = useState("All");
  const [status, setStatus] = useState("All");
  const [gender, setGender] = useState("All");
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [msg, setMsg] = useState<string>("");
  // const [message, setMessage] = useState<string>("");

  const [show, setShow] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<string>("");
  const [openSharePopup, setOpenSharePopup] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [shareID, setShareID] = useState<number>(0);
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<number | 0>();

  const [deleteCurrencyID, setDeleteCurrencyID] = useState<number | 0>();
  const [deleteDeptID, setDeleteDeptID] = useState<number | 0>();
  const [deleteDesiID, setDeleteDesiID] = useState<number | 0>();
  const [deleteNatiID, setDeleteNatiID] = useState<number | 0>();
  const [deleteStatID, setDeleteStatID] = useState<number | 0>();

  const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  // const [dropdownData, setDropDownData] = useState<DropdownFieldData[]>();
  // const [isUpdated, setIsUpdated] = useState<boolean>(false);
  // const [visibleFields, setVisibleField] = useState<any>();
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [createEmployee] = useCreateEmployeeMutation();
  const [createCurrency] = useCreateCurrencyMutation();
  const [createCountry] = useCreateCountryMutation();
  const [sendEmail] = useSendEmailMutation();
  
  // const { data: empDesignData } = useGetDesignationListQuery();
  // const { data: empCurrencyData } = useGetCurrencyListQuery();
  // const { data: empCountryData } = useGetCountryListQuery();
  const [createDepartment] = useCreateDepartmentMutation();
  const [createDesignation] = useCreateDesignationMutation();
  const [departmentData, setDepartmentData] = useState<EmployeeData[]>([]);
  const [currencyData, setCurrencytData] = useState<EmployeeData[]>([]);
  const [designationData, setDesignationData] = useState<EmployeeData[]>([]);
  //const [memberData, setMemberData] = useState<EmployeeData[]>([]);
  //const [genderData, setGenderData] = useState<EmployeeData[]>([]);
  const [nationalityData, setNationalityData] = useState<GenderData[]>([]);
  const [deleteCurrency] = useRemoveCurrencyMutation();
  const [deleteDepartment] = useRemoveDepartmentMutation();
  const [deleteCountry] = useRemoveCountryMutation();
  const [deleteDesignation] = useRemoveDesignationMutation();
  const [enableEmployee, { isLoading: enableLoading }] =
    useEnableEmployeeMutation();
  const [lastDateData, setDate] = useState<Date | undefined>();

  const changeUserStatusData = useCallback((id: string) => {
    enableEmployee({ id: parseInt(id) });
  }, []);

  useEffect(() => {
    setDepartmentData(empDepartmentData ? empDepartmentData : []);
  }, [empDepartmentData]);

  const encodeAlphabet = (alphabet: string) => {
    if (alphabet) {
      return alphabet.toString().replace(/[a-zA-Z0-9]./g, "*");
    } else {
      return "";
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.display({
        header: "",
        id: `${checkManage ? "selection" : "none-selection"}`,
      }),
      columnHelper.accessor("employeeID", {
        header: "Employee ID",
        id: "employeeID",
        cell: ({ getValue, cell }) => {
          const employeeID = getValue();
          return (
            <div className="  text-left bg-white text-black2 px-[10px]">
              {/* max-w-[160px] min-w-[140px]*/}
              <p className="h-full flex items-center">{employeeID}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("name", {
        header: "Name (EN)",
        id: "name",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" text-left">
              {/* max-w-[160px]  min-w-[140px]*/}
              <EditableCell
                setData={setData}
                value={name}
                type="text"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("name_tc", {
        header: "Name (TC)",
        id: "name_tc",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" text-center flex items-center justify-center">
              <span>{name}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("name_sc", {
        header: "Name (SC)",
        id: "name_sc",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" text-center flex items-center justify-center">
              <span>{name}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("jobTitle", {
        header: "Job Title",
        id: "jobTitle",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" text-left">
              {/* max-w-[160px]  min-w-[140px]*/}
              <EditableCell
                setData={setData}
                value={name}
                type="text"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("workEmail", {
        header: "Email",
        id: "workEmail",
        cell: ({ getValue, cell }) => {
          const email = getValue();
          return (
            <div className="min-w-[250px]  text-left">
              {/* max-w-[230px] */}
              <EditableCell
                maxLength={30}
                setData={setData}
                value={email}
                type="email"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("mobile", {
        header: "Mobile",
        id: "mobile",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const mobile = getValue()==null?'':getValue();
          return (
            <div className="  text-left">
              {/* max-w-[260px] min-w-[280px]*/}
              <EditableCell
                setData={setData}
                value={mobile?mobile:''}
                type="text"
                cell={cell}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("joinedDate", {
        header: "Joined Date",
        id: "joinedDate",
        cell: ({ getValue, cell }) => {
          const joinedDate = getValue();
          var joinDate = new Date();
          if (joinedDate) {
            joinDate = new Date(joinedDate);
          }
          var formatDate = renderDateFormatDMY(joinDate, "/");
          return (
            <div className=" pr-1 flex justify-between items-center text-left">
              {/* max-w-[200px]  min-w-[180px]*/}
              <EditableCell
                setData={setData}
                value={cell.row.original?.isNew ? formatDate : formatDate}
                type="custom"
                cell={cell}
                CustomComponent={
                  <DatePopup
                    dobDate={joinDate}
                    setDate={() => 1}
                    cell={cell}
                    data={data}
                    setData={setData}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("department", {
        header: "Department",
        id: "department",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const dptment = getValue();
          return (
           <div className="flex h-full items-center px-2">
            <span>{dptment?.name}</span>
           </div>
          );
        },
      }),
      columnHelper.accessor("gender", {
        header: "Gender",
        id: "gender",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const gender = getValue();
          var genderD = genderData.find((x) => x.id == gender);
          const genderValue = genderD
            ? genderD.name.charAt(0).toUpperCase() + genderD.name.slice(1)
            : "Select";
          const originalValues = cell.row.original?.role?.map(
            (role: baseObj) => role.id
          );
          const isNew: boolean = cell.row.original?.isNew;
          return (
            <div
              className={classNames(
                styles.customEmployeeDropdown,
                "  text-left h-full min-w-[120px]"
                // max-w-[200px]
              )}
            >
              <EditableCell
                className="py-0"
                setData={setData}
                value={gender ? gender : "Select"}
                type="custom"
                cell={cell}
                hasPadding={true}
                CustomComponent={
                  <CustomDropdownWithAddButton
                  isShowAdd={false}
                    isShowDelete={false}
                    isText={true}
                    customHeight="min-h-[220px]"
                    initialValues={originalValues}
                    dataList={genderData}
                    setDataList={setGenderData}
                    title="Gender"
                    cell={cell}
                    data={data}
                    setData={setData}
                    setSelectedData={setGender}
                    selectedData={gender ? gender : "Select"}
                    setDeleteID={setDeleteID}
                    setOpenDeleteopup={setOpenDeleteopup}
                    isEditable={isNew}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        id: "status",
        cell: ({ getValue, cell }) => {
          var status = getValue();
          var statusView = statusData.find((x) => x.id == status);
          const originalValues = cell.row.original?.role?.map(
            (role: baseObj) => role.id
          );
          const isNew: boolean = cell.row.original?.isNew;
          return (
            <div
              className={classNames(
                " text-left h-full min-w-[150px] ",
                // max-w-[200px]
                styles.customEmployeeDropdown
              )}
            >
             <span className="flex h-full items-center w-full px-2">{status==1?'Active':'InActive'}</span>
            </div>
          );
        },
      }),
      columnHelper.display({
        header: "Action",
        id: `${(data?.find((x) => x.isNew == true) && addNew) || checkManage
            ? "actions"
            : "none-actions"
          }`,
        // id: `${checkManage ? 'actions' : 'none-actions'}`,
        cell: ({ cell }) => {
          var isHaveNew = data?.find((x) => x.isNew === true);
          var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
          const isNew: boolean = cell.row.original?.isNew;
          var link = "/profile?id=" + cell.row.original.uniqeID;
          return (
            <div
              className={classNames(
                styles.actionedBtn,
                "px-2 flex justify-center items-center "
                // max-w-[98px] min-w-max
              )}
            >
              {isNew ? (
                <>
                  
                </>
              ) : (
                <>
                  
                  {/* {checkManage ? (
                    <button
                      type="button"
                      onClick={() =>
                        shareEmployeeData(Number(cell.row.original.uniqeID))
                      }
                      className={classNames(
                        styles.customSharedBtn,
                        "w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpphillipines hover:bg-turqouiseLight transition-all duration-300"
                      )}
                    >
                      <ShareIcon />
                    </button>
                  ) : null} */}
                  {checkManage ? (
                    <button
                      disabled={isHaveNew ? true : false}
                      type="button"
                      onClick={() => {
                        editEmployee(Number(cell.row.id),cell);
                        if (setIsEdit) {
                          setIsEdit(true);
                        }
                      }}
                      className={classNames(
                        disabledClass,
                        " w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                      )}
                    >
                      <EditIcon />
                    </button>
                  ) : null}

                  {checkManage ? (
                    <button
                      type="button"
                      onClick={() => {
                        deleteEmployeeData(
                          cell.row.id,
                          cell.row.original.uniqeID
                        );
                      }}
                      className={classNames(
                        disabledClass,
                        " w-6 h-6 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300"
                      )}
                    >
                      <TrashIcon />
                    </button>
                  ) : null}
                </>
              )}
            </div>
          );
        },
      }),
    ],
    [
      columnHelper,
      data,
      departmentData,
      designationData,
      nationalityData,
      currencyData,
      hiddenFields,
      // allowanceSuccess,
    ]
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  document.querySelectorAll("th,td").forEach((x) => {
    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }

    x.classList.remove("absolute");
    x.classList.add("left-0");
    x.classList.add("relative");
    var div = document.getElementById(x.id);
    if (div) {
      div.style.setProperty("left", "");
    }
    if (x.id.includes("action")) {
      x.classList.add("absolute");
      x.classList.remove("relative");
      x.classList.add("right-0");
      x.classList.remove("left-0");
    }
  });

  const deleteEmployeeData = useCallback(
    (id?: any, uniqueID?: number) => {
      setDeleteID(id);
      setDeleteRowID(uniqueID);
      setOpenDeleteopup(true);
    },
    [setData, rowSelection]
  );

  const deleteEmpItem = useCallback(
    (id?: number, dataId?: number) => {
      const selectedRows = Object.keys(rowSelection);
      setData((prev: baseObj[]) => {
        if (dataId != undefined) {
          // var $dataId = dataId;
          deleteEmployee(dataId)
            .unwrap()
            .then((payload: any) => {
              if (payload.status) {
                setOpenDeleteopup(false);
                setSuccessPopup(true);
                setMessage(
                  payload ? [payload?.message] : ["Successfully Delete."]
                );
                toast("Successfully Delete.", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  type: "success",
                });
              }
            })
            .catch((error: any) => {
              setErrorPopup(true);
              setOpenDeleteopup(false);
              setMessage(
                error
                  ? [error?.data?.message]
                  : ["Something went wrong!Trying again."]
              );
            });
        }
        return prev.filter((_, index) => {
          return id !== undefined
            ? id !== index
            : !selectedRows.includes(String(index));
        });
      });
      id == undefined && setRowSelection({});
    },
    [setData, rowSelection]
  );

  // const deleteItem = (id?: number) => {
  //   const selectedRows = Object.keys(rowSelection);
  //   setData((prev: baseObj[]) =>
  //     prev.filter((_, index) => {
  //       if (id == index) {
  //         deleteEmployee(prev[id]?.uniqeID);
  //       }
  //       return (id !== undefined && id != -1)
  //         ? id !== index
  //         : !selectedRows.includes(String(index));
  //     })
  //   );
  //   setRowSelection({});
  //   id == undefined && setRowSelection({});
  //   //var uniqueID=
  //   //  deleteEmployee(id);
  // };

  const confirmDeleteData = () => {
    var id = deleteID;
    var dataID = deleteRowID;
    const selectedRows = Object.keys(rowSelection);
    var selectionIds = data
      .filter((x, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x.uniqeID : 0;
      })
      .map((x) => x.uniqeID);
    if (selectionIds.length > 0) {
      deleteEmployeeMultiple(selectionIds);
    } else {
      if (deleteCurrencyID) {
        deleteCurrency(deleteCurrencyID)
          .unwrap()
          .then((payload: any) => {
            if (payload.status) {
              setSuccessPopup(true);
              setMessage([payload?.message]);
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            }
          })
          .catch((error: any) => {
            setErrorPopup(true);
            setMessage(
              error
                ? [error?.data?.message]
                : ["Something went wrong!Trying again."]
            );
          });
        setDeleteCurrencyID(0);
      }
      if (deleteDeptID) {
        deleteDepartment(deleteDeptID)
          .unwrap()
          .then((payload: any) => {
            if (payload.status) {
              setSuccessPopup(true);
              setMessage([payload?.message]);
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            } else {
              setErrorPopup(true);
              setMessage(
                payload
                  ? [payload?.message]
                  : ["Something went wrong!Trying again."]
              );
            }
          })
          .catch((error: any) => {
            setErrorPopup(true);
            setMessage(
              error
                ? [error?.data?.message]
                : ["Something went wrong!Trying again."]
            );
          });
        setDeleteDeptID(0);
      }
      if (deleteNatiID) {
        deleteCountry(deleteNatiID)
          .unwrap()
          .then((payload: any) => {
            if (payload.status) {
              setSuccessPopup(true);
              setMessage([payload?.message]);
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            } else {
              setErrorPopup(true);
              setMessage(
                payload
                  ? [payload?.message]
                  : ["Something went wrong!Trying again."]
              );
            }
          })
          .catch((error: any) => {
            setMsg(
              error
                ? error?.data?.message
                : "Something went wrong!Trying again."
            );
          });
        setDeleteNatiID(0);
      }
      if (deleteDesiID) {
        deleteDesignation(deleteDesiID)
          .unwrap()
          .then((payload: any) => {
            if (payload.status) {
              setSuccessPopup(true);
              setMessage([payload?.message]);
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            } else {
              setErrorPopup(true);
              setMessage(
                payload
                  ? [payload?.message]
                  : ["Something went wrong!Trying again."]
              );
            }
          })
          .catch((error: any) => {
            setMsg(
              error
                ? error?.data?.message
                : "Something went wrong!Trying again."
            );
          });
        setDeleteDesiID(0);
      }
      if (deleteStatID) {
        deleteDepartment(deleteStatID)
          .unwrap()
          .then((payload: any) => {
            if (payload.status) {
              setSuccessPopup(true);
              setMessage([payload?.message]);
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            } else {
              setErrorPopup(true);
              setMessage(
                payload
                  ? [payload?.message]
                  : ["Something went wrong!Trying again."]
              );
            }
          })
          .catch((error: any) => {
            setMsg(
              error
                ? error?.data?.message
                : "Something went wrong!Trying again."
            );
          });
        setDeleteStatID(0);
      }
      if (id) {
        deleteEmpItem(id, dataID);
        setDeleteID(-1);
        setDeleteRowID(-1);
      }
      setOpenDeleteopup(false);
    }
  };

  const deleteEmployeeMultiple = (ids: number[]) => {
    deleteEmployeeByIDs(ids)
      .then((res: any) => {
        if (res.data.status) {
          setOpenDeleteopup(false);
          toast("Successfully Delete.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        }
      })
      .catch((err: any) => {
        setOpenDeleteopup(false);
      });
    setRowSelection({});
  };

  // const getDepartmentID = (department: string) => {
  //   var dptData = departmentData.find((x) => x.name == department);
  //   return dptData?.id;
  // };

  const validateEmail = (email: any) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validateWorkEmail = (email: any) => {
    const customEmailRegex = /^[a-zA-Z0-9._%+-]+@visibleone\.com$/;
    return customEmailRegex.test(email);
  };

  const validateWorkEmailHK = (email: any) => {
    const customEmailHKRegex = /^[a-zA-Z0-9._%+-]+@visibleone\.com\.hk$/;
    return customEmailHKRegex.test(email);
  };

  const checkIsValidate = (editedData: any) => {
    var message: any = [];
    if (editedData.name == "" || editedData.preferredName == "") {
      message.push("The name field is required");
    }
    if (editedData.email === "" || !validateEmail(editedData.email)) {
      message.push("Email not validate");
    }

    if (
      !validateWorkEmailHK(editedData.workEmail) &&
      !validateWorkEmail(editedData.workEmail)
    ) {
      message.push(
        "Work-Email not validate (xx@visibleone.com.hk) (xx@visibleone.com)"
      );
    }

    if (editedData.mobile == "") {
      message.push("Mobile field is required");
    }
    if (editedData.department == "") {
      message.push("Department field is required");
    }
    if (editedData.designation == "") {
      message.push("Designation field is required");
    }
    if (editedData.reportTo === "") {
      message.push("Report to field is required");
    }
    if (editedData.currency_id == "") {
      message.push("Currency field is required");
    }
    if (editedData.salary == "") {
      message.push("Salary field is required");
    }
    if (editedData.reportTo == "") {
      message.push("Report To field is required");
    }
    if (editedData.gender == "") {
      message.push("Gender field is required");
    }
    if (editedData.nationality == "") {
      message.push("Nationality field is required");
    }
    if (editedData.status == "") {
      message.push("Status field is required");
    }
    var emailAlready = data?.find(
      (x) => x.email == editedData.email && x.uniqeID !== editedData.uniqeID
    );
    var workEmailAlready = data?.find(
      (x) =>
        x.workEmail == editedData.workEmail && x.uniqeID !== editedData.uniqeID
    );
    var emailAlreadyInUser = memberData?.find(
      (x: any) => x.email == editedData.email
    );
    var workEmailAlreadyInUser = memberData?.find(
      (x: any) => x.workEmail == editedData.email
    );    
    if (editedData.uniqeID != 0) {
      if (emailAlready) {
        message.push("The email has already been taken.");
      }
      if (workEmailAlready) {
        message.push("The work email has already been taken.");
      }
    } else {
      if (emailAlready || emailAlreadyInUser) {
        message.push("The email has already been taken.");
      }
      if (workEmailAlready || workEmailAlreadyInUser) {
        message.push("The work email has already been taken.");
      }
    }
    var preferredAlready = data?.find(
      (x) =>
        x.preferredName == editedData.preferredName &&
        x.uniqeID !== editedData.uniqeID
    );
    if (preferredAlready) {
      message.push("The preferred name has already been taken.");
    }
    var mobileAlready = data?.find(
      (x) => x.mobile == editedData.mobile && x.uniqeID !== editedData.uniqeID
    );
    if (mobileAlready) {
      message.push("The mobile has already been taken.");
    }
    return message;
  };

  const dptmData = empDepartmentData ? empDepartmentData : [];
  const country = nationalityData;
  const curData = currencyData;

  const saveEmployeeData = (id: number) => {
    var editedData = data.find((x) => x.uniqeID == id);
    
    if (editedData) {
      if (Number(editedData.uniqeID) === Number(id)) {
        var message: any = checkIsValidate(editedData);
        if (message.length > 0) {
          setErrorPopup(true);
          setMessage(message);
          return;
        } else {
          var joinedDate = editedData.joinedDate;
          var lastDate = lastDateData;
          // if (editedData.joinedDate) {
          //   joinedDate = renderDateFormat(new Date(editedData.joinedDate), "-");
          // }
          var dob = editedData.dob;

          var currencyCode = currencyData.find(
            (x) => x.id == editedData?.currency_id
          );
          var isNew = true;
          // const name = DOMPurify.sanitize(data);

          var updatedData = {
            employeeID: editedData.employeeID,
            uniqeID: editedData.uniqeID,
            name: DOMPurify.sanitize(editedData.name),
            preferredName: DOMPurify.sanitize(editedData.preferredName),
            email: editedData.email,
            workEmail: editedData.workEmail,
            mobile: DOMPurify.sanitize(editedData.mobile),
            joinedDate: joinedDate,
            lastDate: lastDate,
            department_id: editedData.department,
            designation_id: editedData.designation,
            currency_id: editedData.currency_id,
            position: designate,
            report_to: editedData.reportTo?.id,
            currency: currencyCode ? currencyCode.label : "",
            salary: editedData?.salary,
            dob: dob,
            gender: editedData.gender,
            race: DOMPurify.sanitize(editedData.race),
            religion: DOMPurify.sanitize(editedData.religion),
            country_id: editedData.nationality,
            nationalityID: DOMPurify.sanitize(editedData.nationalityID),
            passportNo: DOMPurify.sanitize(editedData.passportNo),
            device: DOMPurify.sanitize(editedData.device),
            is_agent_installed: editedData.is_agent_installed??0,
            device_user_name: DOMPurify.sanitize(editedData.device_user_name),
            bank: DOMPurify.sanitize(editedData.bank),
            bankAccountNo: DOMPurify.sanitize(editedData.bankAccountNo),
            status: editedData.status,
            id: editedData.uniqeID,
            // years: editedData.years
          };

          editedData.dob = dob;
          editedData.joinedDate = joinedDate;
          editedData.lastDate = lastDate;

          if (editedData.uniqeID == 0) {
            createEmployee(updatedData)
              .then((payload: any) => {
                if (payload?.data?.status) {
                  toast("Successfully Create.", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: "success",
                  });
                  saveEmployee(id, false);
                  setMessage(
                    payload ? [payload?.message] : ["Successfully Create."]
                  );
                  isNew = false;
                }
              })
              .catch((error: any) => {
                setErrorPopup(true);
                setMessage(
                  error ? [error?.data?.message] : ["Something went wrong!"]
                );
                isNew = true;
              });
          } else {
            if (typeof updatedData.department_id == "string") {
              if (Number.isInteger(updatedData.department_id)) {
                var dptData = dptmData.find(
                  (x) => x.id == updatedData.department_id
                );
                updatedData.department_id = dptData ? dptData.id : 0;
              } else {
                var dptData = dptmData.find(
                  (x) => x.name == updatedData.department_id
                );
                updatedData.department_id = dptData ? dptData.id : 0;
              }
            }

            var designtData = designationData.find(
              (x) => x.name == updatedData.designation_id
            );

            if (typeof updatedData.designation_id == "string") {
              updatedData.designation_id = designtData ? designtData.id : 0;
            }
            var natData = country.find((x) => x.name == updatedData.country_id);
            if (typeof updatedData.country_id == "string") {
              updatedData.country_id = natData ? natData.id : 0;
            }
            var reportTo = updatedData.report_to;
            if (typeof reportTo == "object") {
              updatedData.report_to = reportTo ? reportTo.id : 0;
            }
            var salary = editedData.salary;
            var currency = curData.find((x) => x.id == editedData?.currency_id);
            const currencys = salary.replace(currency?.label, "");
            updatedData.salary = DOMPurify.sanitize(
              currencys?.toString() ?? ""
            );
            updatedData.bankAccountNo = updatedData.bankAccountNo;

            updateEmployee(updatedData)
              .then((payload: any) => {

                if (payload?.data?.status) {
                  saveEmployee(id, false);
                  setSuccessPopup(true);
                  setMessage(
                    payload ? [payload?.message] : ["Successfully Updated."]
                  );
                  isNew = false;
                  toast("Successfully Updated.", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: "success",
                    theme: "light",
                  });
                }
                if (payload?.error?.status) {
                  setErrorPopup(true);
                  setMessage(
                    payload?.error?.data?.message
                      ? [payload?.error?.data?.message]
                      : ["Something went wrong!"]
                  );
                  isNew = true;
                }
              })
              .catch((error: any) => {
                setErrorPopup(true);
                setMessage(
                  error ? [error?.data?.message] : ["Something went wrong!"]
                );
                isNew = true;
              });
          }
        }
      }
    }
  };

  const saveEmployee = useCallback(
    (id: number, isNew: boolean) => {
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.uniqeID == id) {
            return {
              ...data,
              isNew: isNew,
            };
          }
          return data;
        });
      });
    },
    [setData]
  );

  const editEmployee = useCallback(
    (id: number,cell:any) =>{
      if(setEditData){
        setEditData({
          id: cell.row.original.uniqeID,
          name: cell.row.original.name,
          name_en: cell.row.original.name_en,
          name_tc: cell.row.original.name_tc,
          name_sc: cell.row.original.name_sc,
          employeeID: cell.row.original.employeeID,
          workgroup: cell.row.original.workgroup,
          jobTitle: cell.row.original.jobTitle,
          mobile: cell.row.original.mobile,
          password: cell.row.original.password,
          workEmail: cell.row.original.workEmail,
          department: cell.row.original.department,
          status: cell.row.original.status,
          joinedDate: cell.row.original.joinedDate,
          gender: cell.row.original.gender,
          photo: cell.row.original.photo,
          location: cell.row.original.location,
          medical: cell.row.original.medicalPlan,
          qr_in_code: cell.row.original.qr_in_code,
          qr_out_code: cell.row.original.qr_out_code,
        });
        setIsOpen(true);
      }
    },      
    [setData]
  );

  const canCeelRow = useCallback(
    (id: number) => {
      if (isEditing) {
        setIsEdit(false);
        setData((prev: baseObj[]) => {
          return prev.map((data, index: number) => {
            if (data.uniqeID == id) {
              return { ...data, isNew: false };
            }
            return data;
          });
        });
      } else {
        const filterData = data?.filter((d: any) => d?.uniqeID !== id);
        setData(filterData);
      }
    },
    [isEditing, setData, data, setIsEdit]
  );

  const shareEmployeeData = useCallback(
    (id: number) => {
      setOpenSharePopup(true);
      setShareID(id);
    },
    [setData]
  );

  const visibleColumns: { [key: string]: boolean } = {};

  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  if (checkManage) {
    visibleColumns["actions"] = true;
  } else {
    visibleColumns["actions"] = false;
  }

  if (hiddenFields.length > 0) {
    columns.map((x) => {
      if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else if (x.id?.includes("none")) {
        visibleColumns[x.id ? x.id : ""] = true;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }
  const saveTemplateData = (template: any) => {
    var views = columns
      .map((x) => {
        if (x.id) {
          if (template[x.id] == undefined) {
            return x.id;
          }
        }
      })
      .filter((x) => x != undefined);
    var viewsData = views.map((x) => (x != undefined ? x : ""));
    var newTemplate: TemplateData = {
      id: 0,
      name: "View" + templateList.length + 1,
      viewColumns: viewsData,
    };
    setHiddenFields(viewsData);
    setCurrentView("");
  };

  const sendEmailData = (data: any) => {
    sendEmail(data)
      .unwrap()
      .then((payload: any) => {
        if (payload.status) {
          setOpenSharePopup(false);
          setSuccessPopup(true);
          setMessage([payload?.message]);
          setIsSending(false);
          toast("Email Sent Successfully.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        }
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(["Something went wrong!"]);
      });

    // .then((res) => {
    //   setOpenSharePopup(false);
    // });
  };

  useEffect(() => {
    if (hiddenFields.length > 0) {
      setColumnOrder(["selection", ...hiddenFields]);
    }
  }, [hiddenFields]);

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);

  return (
    <div
      id="employeeTable"
      className={classNames(
        styles.employeeTable,
        "relative inline-block max-w-full"
      )}
    >
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      {/* <SuccessPopup
        descText="Done Successfully!"
        confirmBtnText="Yes, Close."
        show={successPopup}
        setShow={setSuccessPopup}
        message={message}
        setMessage={setMessage}
      /> */}

      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <DeletePopup
        descText="Are you sure to delete?"
        confirmBtnText="Yes, delete."
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
      />

      <SharePopup
        setIsSending={setIsSending}
        isSending={isSending}
        sendEmailData={sendEmailData}
        shareID={shareID}
        show={openSharePopup}
        setShow={setOpenSharePopup}
      />

      <LayoutAdjustableTable
        saveTemplateData={saveTemplateData}
        hideColumn={visibleColumns}
        columns={columns}
        data={data}
        sorting={sorting}
        setSortingState={setSorting}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        unDraggableFields={["actions", "selection", "id"]}
        theaderColor="text-graydark"
        isLayoutControlOpen={isLayoutControlOpen}
        setIsLayoutControlOpen={setIsLayoutControlOpen}
        label="Employee List"
        setItemCount={setItemCount}
        pagination={pagination}
        setPagination={setPagination}
        globalFilter={globalSearch}
        setGlobalFilter={setGlobalSearch}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        noPaddingCells={[
          "department",
          "designation",
          "gender",
          "nationality",
          "status",
          "currency_id",
        ]}
        localModuleId={localModuleId}
        setIsTemplate={setIsTemplate}
        setCols={setCols}
      />

      {selectRowsCount > 0 && (
        <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-8 mb-10 lg:mt-10 lg:mb-12 ">
          <p>{selectRowsCount} selected.</p>
          {checkManage ? (
            <button
              type="button"
              onClick={() => setOpenDeleteopup(true)}
              className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
            >
              <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-[#FFEAEF]  transition-all duration-300 ">
                <TrashIcon width={10} height={12} />
              </div>
              Delete
            </button>
          ) : null}
        </div>
      )}
      <div className="pt-6">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
        // changePage={changePage}
        />
      </div>
      <CommonErrorPopup
        show={msg == "" ? false : true}
        setShow={() => setMsg("")}
        message={msg}
      />
    </div>
  );
};
export default EmployeeTableV2;
