import { redirectToLogin } from "../annoucement-sheet";
import { api } from "../api";
import authHeader from "../auth-header";
export interface IServicePageInfoData {
    "page": IServiceDataInfo,
    "overview_section":ICareerPath;
    "sats_passenger_info":ICareerPath;
    "sats_ramp_info":ICareerPath;
    "career_path_info":ICareerPath;
}
export interface ICSRData{
    "sats_care_page":IServiceDataInfo;
    "overview_section":ICareerPath;
}
export interface IServiceDataInfo {
    "id": number,
    "sub_menu_id": number,
    "menu_id": number,
    "slug": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "meta_title_en": string,
    "meta_title_tc": string,
    "meta_title_sc": string,
    "meta_description_en": string,
    "meta_description_tc": string,
    "meta_description_sc": string,
    "meta_image": string,
    "banner_mobile_image": string,
    "banner_desktop_image": string,
}
export interface ICareerPath{
    "id": number,
    "page_id": number,
    "section_order": number,
    "unique_name": string,
    "sub_title_en": string,
    "sub_title_tc":string,
    "sub_title_sc":string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "description_en": string,
    "description_tc": string,
    "description_sc": string,
    "image": string,
    "additional_data_en": null,
    "additional_data_tc": null,
    "additional_data_sc": null,
}

export type TData = {
    data: [];
    total: number;
};
export type PaginationData = {
    page: number;
    limit: number | string | undefined;
    search: string | number;
    sort?: string;
    type?: string;
};
export const careerApi = api.injectEndpoints({
    endpoints: (build) => ({
        getCareerDataPage: build.query<IServicePageInfoData, string>({
            query: (type) => ({
                url: `fetch-join-sats-page?page_type=${type}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Career" as const, id: "LIST" }]
        }),
        getCaresDataPage: build.query<ICSRData, string>({
            query: (type) => ({
                url: `fetch-sats-care-page?page_type=${type}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Career" as const, id: "LIST" }]
        }),
        fetch_activities: build.query<TData, PaginationData>({
            query: ({ limit, page, search, type, sort }) => ({
                url: `sats-care-content?per_page=${limit}&page=${page}&search=${search}&page_type=${type}&sort=${sort??''}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Job" as const, id: "LIST" }]
        }),
        fetch_csr: build.query<TData, PaginationData>({
            query: ({ limit, page, search, type,sort }) => ({
                url: `social-responsibility-content?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Job" as const, id: "LIST" }]
        }),
        fetch_activities_info: build.mutation<TData, PaginationData>({
            query({ limit, page, search, type, sort }) {
                return {
                    headers: authHeader(),
                    url: `sats-care-content?per_page=${limit}&page=${page}&search=${search}&page_type=${type}&sort=${sort??''}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['Job']
        }),
        fetch_csr_info: build.mutation<TData, PaginationData>({
            query({ limit, page, search, type, sort }) {
                return {
                    headers: authHeader(),
                    url: `social-responsibility-content?per_page=${limit}&page=${page}&search=${search}&page_type=${type}&sort=${sort}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['Job']
        }),
        deleteCSR: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `delete-social-responsibility-content`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['Job']
        }),
        deleteActivities: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `delete-sats-care-content`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['Job']
        }),
        fetch_goodpeoplecategory_opening: build.query<TData, PaginationData>({
            query: ({ limit, page, search,type,sort }) => ({
                url: `good-people-category?per_page=${limit}&page=${page}&search=${search}&sort=${sort??''}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "GoodPeopleCategory" as const, id: "LIST" }]
        }),
        fetch_goodpeoplecategory_opening_info: build.mutation<TData, PaginationData>({
            query({ limit, page, search, type, sort }) {
                return {
                    headers: authHeader(),
                    url: `good-people-category?per_page=${limit}&page=${page}&search=${search}&sort=${sort??''}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['GoodPeopleCategory']
        }),
        deleteGoodPeopleCategory: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `delete-good-people-category`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['GoodPeopleCategory']
        }),
    })
});

export const {useGetCareerDataPageQuery,useGetCaresDataPageQuery,useFetch_activitiesQuery,useFetch_activities_infoMutation,
    useDeleteActivitiesMutation,useFetch_csrQuery,useFetch_csr_infoMutation,useDeleteCSRMutation,
useFetch_goodpeoplecategory_openingQuery,useFetch_goodpeoplecategory_opening_infoMutation,useDeleteGoodPeopleCategoryMutation  } = careerApi;

export const {
    endpoints: { getCareerDataPage,getCaresDataPage,fetch_activities,fetch_activities_info,deleteActivities,
        fetch_csr,fetch_csr_info,deleteCSR,
    fetch_goodpeoplecategory_opening,fetch_goodpeoplecategory_opening_info,deleteGoodPeopleCategory }
} = careerApi;
