import { ReactElement } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import CareerPathOverviewPage from "./sections/CareerPathOverviewPage";
import CareActivitiesOverview from "./sections/CareActivitiesOverview";
import ActivitiesList from "./sections/Activities/ActivitiesList";
import CareCSROverview from "./sections/CareCSROverview";
import CareGoodPeople from "./sections/CareGoodPeople";
import CSRList from "./sections/CSR/CSRList";
import GoodPeopleList from "./sections/GoodPeople/GoodPeopleList";
import GoodPeopleCategoryList from "./sections/GoodPeople/GoodPeopleCategoryList";
import GoodPeopleCategoryPage from "./sections/GoodPeople/GoodPeopleCategoryPage";

export const careerPathPagesList = [
  {
    id: 1,
    path: "#",
    component: <CareActivitiesOverview />,
  },
  {
    id: 2,
    path: "overview",
    component: <CareActivitiesOverview />,
  },
  {
    id: 3,
    path: "activities",
    component: <ActivitiesList />,
  },
  {
    id: 4,
    path: "csr-overview",
    component: <CareCSROverview />,
  },
  {
    id: 5,
    path: "csr-list",
    component: <CSRList />,
  },
  {
    id: 6,
    path: "good-people-overview",
    component: <CareGoodPeople />,
  },
  {
    id: 7,
    path: "good-people-list",
    component: <GoodPeopleList />,
  },
  {
    id: 8,
    path: "good-people-category",
    component: <GoodPeopleCategoryPage />,
  },
];
const CareerPathPageRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  return (
    <BasicLayout userData={data} permissions={permissions}>
    <div className="pt-0 pb-[58px]">
      <Outlet />
    </div>
  </BasicLayout>
  );
};
export default CareerPathPageRoute;
