import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import { useState } from "react";
import DownloadItem, { DownloadItemType } from "../components/DownLoadItem";
import DownloadItemSectionList from "../components/download-items/DownloadItemSectionList";

const DownloadItemPage = () => {
  const [activeLang, setActiveLang] = useState<string>("en");
  
  const [showList, setShowList] = useState<boolean>(true);
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pb-8 sm:pb-9 "
      )}
    >
     <DownloadItemSectionList/>
    </div>
  );
};
export default DownloadItemPage;
