import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../../components/common/CommonLanguage";
import LabelAndTextbox from "../../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../../components/SeoData/LabelAndImage";
import { CancelIcon, SaveIcon } from "../../../components/common/Icons";
import { StringDropdownList } from "../../../components/common/DropdownList";
export interface IActivities {
    id: number;
    title_en: string;
    title_tc: string;
    title_sc: string;
    image: File | undefined;
    image_url: string;
}
export interface IBoardOfDirectoryInput {
    initialData: IActivities;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handle_submit: (data: IActivities) => Promise<void>;
    isSave: boolean;
}
const ActivitiesInput: FC<IBoardOfDirectoryInput> = ({
    initialData,
    setShow,
    handle_submit,
    isSave
}) => {
    const [data, setData] = useState<IActivities>({
        id: 0,
        title_en: '',
        title_tc: '',
        title_sc: '',
        image: undefined,
        image_url: ''
    });
    const [activeLang, setActiveLang] = useState<string>("en");
    const change_data = (value: any, key: string) => {
        if (data) {
            if(key=="image"){
                const url=value?URL.createObjectURL(value):'';
                setData((prev)=>{
                    return {
                        ...prev,
                        image:value,
                        image_url:url
                    }
                });
                return;
            }
            setData({ ...data, [key]: value });

        }
    }
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(()=>{
        if(initialData){
            setData({
                id:initialData.id,
                title_en:initialData.title_en,
                title_tc:initialData.title_tc,
                title_sc:initialData.title_sc,
                image:undefined,
                image_url:initialData.image as any
            })
        }
    },[initialData]);
    return <div>
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox label="Title (EN)" value={data?.title_en} setValue={(value) => change_data(value, 'title_en')} />                
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox label="Name (TC)" value={data?.title_tc} setValue={(value) => change_data(value, 'title_tc')} />
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox label="Name (SC)" value={data?.title_sc} setValue={(value) => change_data(value, 'title_sc')} />
            </div>
        </div>        
        <div className="mt-5">
            <LabelAndImage label="Image" value={data?.image_url ?? ''} setValue={(value) => change_data(value, 'image_url')} setFile={(value) => change_data(value, 'image')} />
        </div>
        <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={twJoin(
                    isSave ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={() => handle_submit(data)}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShow(true);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
    </div>
}
export default ActivitiesInput;