import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../components/common/CommonLanguage";
import Services from "../components/Services";
import { useState } from "react";
import ServiceSectionList from "./Services/ServiceSectionList";

const ServiceSection = () => {
  const [activeLang, setActiveLang] = useState<string>("en");
  const [servicesData, setServicesData] = useState<any>();
  const [showList, setShowList] = useState<boolean>(true);
  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
      {showList == true
        ? <ServiceSectionList setShowList={setShowList} setServicesData={setServicesData}/>
        : <div>
            <CommonLanguage
              changeLanguage={changeLanguage}
              activeLang={activeLang}
              
            />
            <Services
              activeLang={activeLang}
              initialData={servicesData}
              setValue={setServicesData}
              setShowList={setShowList}
            />
          </div>}
    </div>
  );
};
export default ServiceSection;
