import { useEffect, useRef, useState } from "react";
import SeoData from "../../components/SeoData/SeoData";
import { twJoin } from "tailwind-merge";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import BannerComponent from "../../components/SeoData/BannerComponent";
import CommonLanguage from "../../components/common/CommonLanguage";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import classNames from "classnames";
import BannerSingle, { IBannerData } from "../../components/SeoData/BannerSingle";
import { useGetCompanyDataQuery } from "../../app/services/company/company";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import ContactUsOverviewInput,{ ICompanyProfile } from "./ContactUsOverviewInput";
import { useGetContactUsDataQuery } from "../../app/services/contact-us/contact-us";
import UndoChanges from "../../components/UndoChanges/UndoChanges";

const ContactUsSectionPage = () => {
    const [metaTitle_en, setMetaTitleEn] = useState<string>("");
    const [metaTitle_zh, setMetaTitleZh] = useState<string>("");
    const [metaTitle_cn, setMetaTitleCn] = useState<string>("");
    const [metaDescription_en, setMetaDescriptionEn] = useState<string>("");
    const [metaDescription_zh, setMetaDescriptionZh] = useState<string>("");
    const [metaDescription_cn, setMetaDescriptionCn] = useState<string>("");
    const [metaImage, setMetaImage] = useState<string>("");
    const [metaImageFile, setMetaImageFile] = useState<File | undefined>();
    const [banner, setBanner] = useState<IBannerData | undefined>({
        title_en: '',
        title_tc: '',
        title_sc: '',
        image: undefined,
        image_url: ''
    });
    const [company_profile, setCompanyProfile] = useState<ICompanyProfile | undefined>({
        title_en: '',
        title_tc: '',
        title_sc: '',
        description_en: '',
        description_tc: '',
        description_sc: '',
        image: undefined,
        image_url: '',
        subtitle_en: '',
        subtitle_tc: '',
        subtitle_sc: '',
        name_en:'',
        name_tc:'',
        name_sc:'',
        position_en:'',
        position_tc:'',
        position_sc:'',    
        phone_en:'',
        phone_tc:'',
        phone_sc:'',    
        email_en:'',
        email_tc:'',
        email_sc:'',    
    });
    const [activeLang, setActiveLang] = useState<string>("en");
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isSave, setIsSave] = useState<boolean>(false);
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const { data: companyData } = useGetContactUsDataQuery('partner-ship');
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    const set_initial_data=()=>{
        if (companyData) {
            const page = companyData?.page;
            const overview_section = companyData?.overview_section;
            if(overview_section){
                setCompanyProfile({
                    title_en:overview_section.title_en,
                    title_tc:overview_section.title_tc,
                    title_sc:overview_section.title_sc,
                    subtitle_en:overview_section.sub_title_en,
                    subtitle_tc:overview_section.sub_title_tc,
                    subtitle_sc:overview_section.sub_title_sc,
                    description_en:overview_section.description_en,
                    description_tc:overview_section.description_tc,
                    description_sc:overview_section.description_sc,
                    image:undefined,
                    image_url:overview_section.image,
                    name_en:overview_section?.additional_data_en?.name,
                    name_tc:overview_section?.additional_data_tc?.name,
                    name_sc:overview_section?.additional_data_sc?.name,
                    position_en:overview_section?.additional_data_en?.position,
                    position_tc:overview_section?.additional_data_tc?.position,
                    position_sc:overview_section?.additional_data_sc?.position,
                    phone_en:overview_section?.additional_data_en?.phone,
                    phone_tc:overview_section?.additional_data_tc?.phone,
                    phone_sc:overview_section?.additional_data_sc?.phone,
                    email_en:overview_section?.additional_data_en?.email,
                    email_tc:overview_section?.additional_data_tc?.email,
                    email_sc:overview_section?.additional_data_sc?.email,
                })
            }
            if (page) {
                setMetaTitleEn(page?.meta_title_en);
                setMetaTitleZh(page?.meta_title_tc);
                setMetaTitleCn(page?.meta_title_sc);
                setMetaDescriptionEn(page?.meta_description_en);
                setMetaDescriptionZh(page?.meta_description_tc);
                setMetaDescriptionCn(page?.meta_description_sc);
                setMetaImage(page?.meta_image as any);
                setBanner({
                    title_en: page?.title_en,
                    title_tc: page?.title_tc,
                    title_sc: page?.title_sc,
                    image_url:page?.banner_desktop_image as any,
                    image: undefined
                });
            }
        }
    }
    useEffect(() => {
        set_initial_data();
    }, [companyData]);
    const handle_submit = async() => {
        const formData = new FormData();
        const meta_image: any = metaImageFile;
        const banner_image: any = banner?.image;
        const overview_image: any = company_profile?.image;
        formData.append('meta_title_en', metaTitle_en ?? '');
        formData.append('meta_title_tc', metaTitle_zh ?? '');
        formData.append('meta_title_sc', metaTitle_cn ?? '');
        formData.append('meta_description_en', metaDescription_en ?? '');
        formData.append('meta_description_tc', metaDescription_zh ?? '');
        formData.append('meta_description_sc', metaDescription_cn ?? '');
        formData.append('meta_image', meta_image);

        formData.append('partner_title_en', company_profile?.title_en ?? '');
        formData.append('partner_title_tc', company_profile?.title_tc ?? '');
        formData.append('partner_title_sc', company_profile?.title_sc ?? '');
        formData.append('partner_sub_title_en', company_profile?.subtitle_en ?? '');
        formData.append('partner_sub_title_tc', company_profile?.subtitle_sc ?? '');
        formData.append('partner_sub_title_sc', company_profile?.subtitle_sc ?? '');
        formData.append('partner_description_en', company_profile?.description_en ?? '');
        formData.append('partner_description_tc', company_profile?.description_sc ?? '');
        formData.append('partner_description_sc', company_profile?.description_sc ?? '');

        formData.append('partner_name_en', company_profile?.name_en ?? '');
        formData.append('partner_name_tc', company_profile?.name_sc ?? '');
        formData.append('partner_name_sc', company_profile?.name_sc ?? '');
        formData.append('partner_position_en', company_profile?.position_en ?? '');
        formData.append('partner_position_tc', company_profile?.position_sc ?? '');
        formData.append('partner_position_sc', company_profile?.position_sc ?? '');
        formData.append('partner_phone', company_profile?.phone_en ?? '');
        formData.append('partner_phone_en', company_profile?.phone_en ?? '');
        formData.append('partner_phone_tc', company_profile?.phone_sc ?? '');
        formData.append('partner_phone_sc', company_profile?.phone_sc ?? '');
        formData.append('partner_email_en', company_profile?.email_en ?? '');
        formData.append('partner_email_tc', company_profile?.email_sc ?? '');
        formData.append('partner_email_sc', company_profile?.email_sc ?? '');
        formData.append('partner_email', company_profile?.email_sc ?? '');

        formData.append('page_title_en', banner?.title_en??'');
        formData.append('page_title_tc', banner?.title_tc??'');
        formData.append('page_title_sc', banner?.title_sc??'');
        formData.append('overview_image', overview_image);
        formData.append('banner_desktop_image', banner_image);
        formData.append('page_type', 'partner-ship');
        await axios(endpointUrl + "update-contact-us-page", {
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
        .then((res: any) => {
            if (res?.data?.status == true) {
                toast(res?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: "success",
                });
            } else {
                setErrorPopup(true);
                setMessage(res?.data?.message);
            }
            setIsSave(false);
        })
        .catch((err) => {
            setErrorPopup(true);
            setMessage(err?.data?.message);
        });
    }
    const undoChanges=()=>{
        set_initial_data();
        setIsCancel(false);
      }
    return (
        <div
            className={twJoin(
                "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
            )}
        >
            <ToastContainer className={"text-12 font-poppins font-normal"} />
        <ErrorPopup
            descText="Error Message!"
            confirmBtnText="Yes, Close."
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
            setMessage={setMessage}
        />
        <UndoChanges setShow={setIsCancel} show={isCancel} undoChange={()=>undoChanges()}/>             
            <div className="text-left">
                <h2 className="text-lg text-left font-bold mb-5 mt-5 text-black">
                    Overview
                </h2>
                <div className="mt-5">
                    <CommonLanguage
                        changeLanguage={changeLanguage}
                        activeLang={activeLang}
                    />
                    <ContactUsOverviewInput company_profile={company_profile} setCompanyProfile={setCompanyProfile} activeLang={activeLang} setActiveLang={setActiveLang} />
                </div>
            </div>
            <div className="flex">
                <div className="text-left w-1/2 mr-5">
                    <SeoData
                        showPageTitle={false}
                        setPageTitleEn={() => 1}
                        setPageTitleZh={() => 1}
                        setPageTitleCn={() => 1}
                        metatitle_en={metaTitle_en}
                        metatitle_zh={metaTitle_zh}
                        metatitle_cn={metaTitle_cn}
                        setMetaTitleEn={setMetaTitleEn}
                        setMetaTitleZh={setMetaTitleZh}
                        setMetaTitleCn={setMetaTitleCn}
                        metadescription_en={metaDescription_en}
                        metadescription_zh={metaDescription_zh}
                        metadescription_cn={metaDescription_cn}
                        setMetaDescriptionEn={setMetaDescriptionEn}
                        setMetaDescriptionZh={setMetaDescriptionZh}
                        setMetaDescriptionCn={setMetaDescriptionCn}
                        meta_image={metaImage}
                        setMetaImage={setMetaImage}
                        setMetaImageFile={setMetaImageFile}
                    />

                </div>
                <div className="w-1/2 text-left">
                    <BannerSingle banner={banner} setBanner={setBanner} />
                </div>
            </div>
            <div className="mt-5 last:mb-0 flex items-center">
                <button
                    className={classNames(
                         isSave ? "opacity-50 pointer-events-none" : "",
                        "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                    )}
                    onClick={() => handle_submit()}
                >
                    <SaveIcon className="mr-2" /> Save
                </button>
                <button
                    className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                    onClick={() => {
                        setIsCancel(true);
                    }}
                >
                    <CancelIcon className="mr-2" color="#9E3039" />
                    Cancel
                </button>
            </div>
        </div>
    );
};
export default ContactUsSectionPage;
