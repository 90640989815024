import { FC, useEffect, useRef, useState } from "react";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import { IContactLocation } from "./ContactUsLocationList";
export interface IMissionData {
    detailData: IContactLocation;
    setDetailData: React.Dispatch<React.SetStateAction<IContactLocation>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handle_submit: (data: IContactLocation) => Promise<void>;
    isSave: boolean;
}
const ContactUsLocationInput: FC<IMissionData> = ({
    detailData,
    setDetailData,
    setShow,
    handle_submit,
    isSave
}) => {
    const [data, setData] = useState<IContactLocation>({
        id: 0,
        map_en: '',
        map_tc: '',
        map_sc: '',
        name_en: '',
        name_tc: '',
        name_sc: '',
    });
    const [activeLang, setActiveLang] = useState<string>("en");
    const mapRef = useRef(null);
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(() => {
        if (detailData) {
            setData({
                id: detailData.id,
                map_en: detailData?.map_en,
                map_tc: detailData?.map_tc,
                map_sc: detailData?.map_sc,
                name_en: detailData.name_en,
                name_tc: detailData.name_tc,
                name_sc: detailData.name_sc,
            });
        }
    }, [detailData]);
    const change_data = (value: any, key: string) => {
        if (data) {
            setData({ ...data, [key]: value })
        }
    }
    return <div>
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div className="">
                    <LabelAndTextbox isRequired={true} label="Map (EN)" value={data?.map_en} setValue={(value) => change_data(value, 'map_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Name (EN)" value={data?.name_en} setValue={(value) => change_data(value, 'name_en')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                 <div className="">
                    <LabelAndTextbox isRequired={true} refId={mapRef} label="Map (TC)" value={data?.map_tc} setValue={(value) => change_data(value, 'map_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Name (TC)" value={data?.name_tc} setValue={(value) => change_data(value, 'name_tc')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <div className="">
                    <LabelAndTextbox isRequired={true} refId={mapRef} label="Map (SC)" value={data?.map_sc} setValue={(value) => change_data(value, 'map_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Name (SC)" value={data?.name_sc} setValue={(value) => change_data(value, 'name_sc')} />
                </div>
            </div>
        </div>
        <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={twJoin(
                    isSave ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={() => handle_submit(data)}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShow(true);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
    </div>
}
export default ContactUsLocationInput;