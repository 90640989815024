import { FC, useEffect, useRef, useState } from "react";
import CommonLanguage from "../../components/common/CommonLanguage";
import { twJoin } from "tailwind-merge";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../components/SeoData/LabelAndImage";
import LabelAndEditor from "../../components/SeoData/LabelAndEditor";
export interface ICompanyProfileData {
    company_profile: ICompanyProfile | undefined;
    activeLang: string;
    setActiveLang: React.Dispatch<React.SetStateAction<string>>;
    setCompanyProfile?: any;
}
export interface ICompanyProfile {
    title_en: string;
    title_tc: string;
    title_sc: string;
    subtitle_en: string;
    subtitle_tc: string;
    subtitle_sc: string;
    description_en: string;
    description_tc: string;
    description_sc: string;
    name_en: string;
    name_tc: string;
    name_sc: string;
    position_en: string;
    position_tc: string;
    position_sc: string;
    image: File | undefined;
    image_url: string;
    phone_en: string;
    phone_tc: string;
    phone_sc: string;
    email_en: string;
    email_tc: string;
    email_sc: string;
    
}
const ContactUsOverviewInput: FC<ICompanyProfileData> = ({
    company_profile,
    activeLang,
    setCompanyProfile
}) => {
    const description_en_Ref = useRef();
    const description_tc_Ref = useRef();
    const description_sc_Ref = useRef();
    const [data, setData] = useState<ICompanyProfile | undefined>({
        title_en:'',
        title_tc:'',
        title_sc:'',
        description_en:'',
        description_tc:'',
        description_sc:'',
        image:undefined,
        image_url:'',
        subtitle_en:'',
        subtitle_tc:'',
        subtitle_sc:'',
        name_en:'',
        name_tc:'',
        name_sc:'',
        position_en:'',
        position_tc:'',
        position_sc:'',
        phone_en:'',
        phone_tc:'',
        phone_sc:'',
        email_en:'',
        email_tc:'',
        email_sc:'',
    });
    useEffect(() => {
        if (company_profile) {
            setData(company_profile);
        }
    }, [company_profile]);
    const change_data = (value: any, key: string) => {
        if(company_profile){
            if(key=='image'){
                const url=value?URL.createObjectURL(value):'';
                setCompanyProfile((prev:any)=>{
                    if(prev){
                        return {
                            ...prev,
                            image:value,
                            image_url:url
                        }
                    }
                });
                return;
            }
            setCompanyProfile({...company_profile, [key]: value})
        }
    }
    return <div>

        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >

                <LabelAndTextbox label="Title (EN)" value={data?.title_en} setValue={(value) => change_data(value, 'title_en')} />
                <div className="mt-5">
                    <LabelAndTextbox label="Subtitle (EN)" value={data?.subtitle_en} setValue={(value) => change_data(value, 'subtitle_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndEditor refId={description_en_Ref} label="Description (EN)" value={data?.description_en} setValue={(value) => change_data(value, 'description_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Name (EN)" value={data?.name_en} setValue={(value) => change_data(value, 'name_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Position (EN)" value={data?.position_en} setValue={(value) => change_data(value, 'position_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Phone (EN)" value={data?.phone_en} setValue={(value) => change_data(value, 'phone_en')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Email (EN)" value={data?.email_en} setValue={(value) => change_data(value, 'email_en')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >

                <LabelAndTextbox label="Title (TC)" value={data?.title_tc} setValue={(value) => change_data(value, 'title_tc')} />
                <div className="mt-5">
                    <LabelAndTextbox label="Subtitle (TC)" value={data?.subtitle_tc} setValue={(value) => change_data(value, 'subtitle_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndEditor refId={description_tc_Ref} label="Description (TC)" value={data?.description_tc} setValue={(value) => change_data(value, 'description_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Name (TC)" value={data?.name_tc} setValue={(value) => change_data(value, 'name_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Position (TC)" value={data?.position_tc} setValue={(value) => change_data(value, 'position_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Phone (TC)" value={data?.phone_tc} setValue={(value) => change_data(value, 'phone_tc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Email (TC)" value={data?.email_tc} setValue={(value) => change_data(value, 'email_tc')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >

                <LabelAndTextbox label="Title (SC)" value={data?.title_sc} setValue={(value) => change_data(value, 'title_sc')} />
                <div className="mt-5">
                    <LabelAndTextbox label="Subtitle (SC)" value={data?.subtitle_sc} setValue={(value) => change_data(value, 'subtitle_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndEditor refId={description_sc_Ref} label="Description (SC)" value={data?.description_sc} setValue={(value) => change_data(value, 'description_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Name (SC)" value={data?.name_sc} setValue={(value) => change_data(value, 'name_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Position (SC)" value={data?.position_sc} setValue={(value) => change_data(value, 'position_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Phone (SC)" value={data?.phone_sc} setValue={(value) => change_data(value, 'phone_sc')} />
                </div>
                <div className="mt-5">
                    <LabelAndTextbox label="Email (SC)" value={data?.email_sc} setValue={(value) => change_data(value, 'email_sc')} />
                </div>
            </div>               
            <LabelAndImage label="Image" value={data?.image_url} setFile={(value) => change_data(value, 'image')} />
        </div>
    </div>
}
export default ContactUsOverviewInput;