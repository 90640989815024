
import { twJoin } from "tailwind-merge";
import { useState } from "react";
import { CategoryType } from "../../../latest-news/components/Category";
import GoodPeopleCategoryList from "./GoodPeopleCategoryList";

const GoodPeopleCategoryPage = () => {
  const [data, setData] = useState<CategoryType>({
    id: 0,
    title_en: "",
    title_sc: "",
    title_tc: "",
  });
  const [showList, setShowList] = useState<boolean>(true);

  return (
    <div
      className={twJoin(
        "text-left px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
      )}
    >
       <GoodPeopleCategoryList/>
    </div>
  );
};
export default GoodPeopleCategoryPage;