import { FC, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import CommonLanguage from "../../../components/common/CommonLanguage";
import LabelAndTextbox from "../../../components/SeoData/LabelAndTextbox";
import LabelAndImage from "../../../components/SeoData/LabelAndImage";
import { CancelIcon, SaveIcon } from "../../../components/common/Icons";
import { StringDropdownList } from "../../../components/common/DropdownList";
export interface IAirline {
    id: number;
    title_en: string;
    title_tc: string;
    title_sc: string;
    link_en: string;
    link_tc: string;
    link_sc: string;
    image: File | undefined;
    image_url: string;
    type: string;
}
export interface IBoardOfDirectoryInput {
    airlines: IAirline;
    setAirlines: React.Dispatch<React.SetStateAction<IAirline>>;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    handle_submit: (data: IAirline) => Promise<void>;
    isSave: boolean;
}
export const airlines_type = [
    {
        id: 0,
        name: "All",
        value:''
    },
    {
        id: 1,
        name: "Passenger & Ramp",
        value:'passenger'
    },   
    {
        id: 2,
        name: "Freighter",
        value:'freighter'
    }
]
const CustomerAirlinesInput: FC<IBoardOfDirectoryInput> = ({
    airlines,
    setAirlines,
    setShow,
    handle_submit,
    isSave
}) => {
    const [data, setData] = useState<IAirline>({
        id: 0,
        title_en: '',
        title_tc: '',
        title_sc: '',
        link_en: '',
        link_tc: '',
        link_sc: '',
        type: '',
        image: undefined,
        image_url: ''
    });
    const [activeLang, setActiveLang] = useState<string>("en");
    const change_data = (value: any, key: string) => {
        if (data) {
            if(key=="image"){
                const url=value?URL.createObjectURL(value):'';
                setData((prev)=>{
                    return {
                        ...prev,
                        image:value,
                        image_url:url
                    }
                });
                return;
            }
            setData({ ...data, [key]: value });

        }
    }
    const changeLanguage = (code: string) => {
        setActiveLang(code);
    };
    useEffect(() => {
        if (airlines) {
            setData({
                id: airlines.id,
                title_en: airlines.title_en,
                title_tc: airlines.title_tc,
                title_sc: airlines.title_sc,
                link_en: airlines.link_en,
                link_tc: airlines.link_tc,
                link_sc: airlines.link_sc,
                type: airlines.type,
                image: undefined,
                image_url: airlines.image_url
            })
        }
    }, [airlines])
    return <div>
        <CommonLanguage
            changeLanguage={changeLanguage}
            activeLang={activeLang}
        />
        <div className="mt-5">
            <div
                className={twJoin(
                    activeLang == "en"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox isRequired={true} label="Title (EN)" value={data?.title_en} setValue={(value) => change_data(value, 'title_en')} />
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Link" value={data?.link_en} setValue={(value) => change_data(value, 'link_en')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "tc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox isRequired={true} label="Name (TC)" value={data?.title_tc} setValue={(value) => change_data(value, 'title_tc')} />
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Link" value={data?.link_tc} setValue={(value) => change_data(value, 'link_tc')} />
                </div>
            </div>
            <div
                className={twJoin(
                    activeLang == "sc"
                        ? ""
                        : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
                )}
            >
                <LabelAndTextbox isRequired={true} label="Name (SC)" value={data?.title_sc} setValue={(value) => change_data(value, 'title_sc')} />
                <div className="mt-5">
                    <LabelAndTextbox isRequired={true} label="Link" value={data?.link_sc} setValue={(value) => change_data(value, 'link_sc')} />
                </div>
            </div>
        </div>
        <div className="mt-5">
            <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                Type
            </label>
            <StringDropdownList
                state={data?.type ?? ''}
                setState={(value) => change_data(value, 'type')}
                placeholder="Select Type"
                className="w-full py-[12px] pl-3 mb-3 text-14"
                dropdownItems={airlines_type?.map(
                    (status: any) => ({
                        value: status.value,
                        label: status.name,
                    })
                )}
                textAlign={"text-left"}
                fontSize="xl:text-15 text-12 font-normal"
                paddingLi=" py-2"
            />
        </div>
        <div className="mt-5">
            <LabelAndImage isRequired={true} label="Image" value={data?.image_url ?? ''} setValue={(value) => change_data(value, 'image_url')} setFile={(value) => change_data(value, 'image')} />
        </div>
        <div className="mt-5 last:mb-0 flex items-center">
            <button
                className={twJoin(
                    isSave ? "opacity-50 pointer-events-none" : "",
                    "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={() => handle_submit(data)}
            >
                <SaveIcon className="mr-2" /> Save
            </button>
            <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                    setShow(true);
                }}
            >
                <CancelIcon className="mr-2" color="#9E3039" />
                Cancel
            </button>
        </div>
    </div>
}
export default CustomerAirlinesInput;