import { redirectToLogin } from "../annoucement-sheet";
import { api } from "../api";
import authHeader from "../auth-header";
export interface IServicePageInfoData {
    "page": IServiceDataInfo,
    "opportunities_info": IJobOpportunity;
    "contact_recruitment_info": IJobContact;
    "competitive_compensation_info": ICompetitive;
    "amazing_coworkers_info": IAmazing;
}
export interface IServiceDataInfo {
    "id": number,
    "sub_menu_id": number,
    "menu_id": number,
    "slug": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "meta_title_en": string,
    "meta_title_tc": string,
    "meta_title_sc": string,
    "meta_description_en": string,
    "meta_description_tc": string,
    "meta_description_sc": string,
    "meta_image": string,
    "banner_mobile_image": string,
    "banner_desktop_image": string,
}
export interface IJobOpportunity {
    "id": number,
    "page_id": number,
    "section_order": number,
    "sub_title_en": string,
    "sub_title_tc": string,
    "sub_title_sc": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "description_en": string,
    "description_tc": string,
    "description_sc": string,
    "image": string,
    "additional_data_en": string,
    "additional_data_tc": string,
    "additional_data_sc": string,
}
export interface IJobContact {
    "id": number,
    "page_id": number,
    "section_order": number,
    "unique_name": string,
    "sub_title_en": string,
    "sub_title_tc": string,
    "sub_title_sc": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "description_en": string
    "description_tc": string
    "description_sc": string
    "image": string,
    "additional_data_en": {
        "contact_recruitment_email": string,
        "contact_recruitment_phone": string,
        "contact_recruitment_address": string
    },
    "additional_data_tc": {
        "contact_recruitment_email": string,
        "contact_recruitment_phone": string,
        "contact_recruitment_address": string
    },
    "additional_data_sc": {
        "contact_recruitment_email": string,
        "contact_recruitment_phone": string,
        "contact_recruitment_address": string
    },
}
export interface ICompetitive {
    "id": number,
    "page_id": number,
    "section_order": number,
    "unique_name": string,
    "sub_title_en": string,
    "sub_title_tc": string,
    "sub_title_sc": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "description_en": string,
    "description_tc": string,
    "description_sc": string,
    "image": string,
    "additional_data_en": null,
    "additional_data_tc": null,
    "additional_data_sc": null,
}
export interface IAmazing {
    "id": number,
    "page_id": number,
    "section_order": number,
    "unique_name": string,
    "sub_title_en": string,
    "sub_title_tc": string,
    "sub_title_sc": string,
    "title_en": string,
    "title_tc": string,
    "title_sc": string,
    "description_en": string,
    "description_tc": string,
    "description_sc": string,
    "image": string,
    "additional_data_en": null,
    "additional_data_tc": null,
    "additional_data_sc": null,
}
export type TData = {
    data: [];
    total: number;
};
export type PaginationData = {
    page: number;
    limit: number | string | undefined;
    search: string | number;
    sort?: string;
    type?: string;
};
export const jobApi = api.injectEndpoints({
    endpoints: (build) => ({
        getJobsPage: build.query<IServicePageInfoData, string>({
            query: (page_type) => ({
                url: `fetch-join-sats-page?page_type=${page_type}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Job" as const, id: "LIST" }]
        }),
        fetch_job_opening: build.query<TData, PaginationData>({
            query: ({ limit, page, search, type, sort }) => ({
                url: `fetch-job-openings?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: () => [{ type: "Job" as const, id: "LIST" }]
        }),
        fetch_job_opening_info: build.mutation<TData, PaginationData>({
            query({ limit, page, search, type, sort }) {
                return {
                    headers: authHeader(),
                    url: `fetch-job-openings?per_page=${limit}&page=${page}&search=${search}&sort=${sort}`,
                    method: "GET",
                };
            },
            invalidatesTags: ['Job']
        }),
        deleteService: build.mutation<TData, number>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `delete-job-openings`,
                    body: {
                        id: body
                    },
                    method: "POST",
                };
            },
            invalidatesTags: ['Job']
        }),
    })
});

export const { useGetJobsPageQuery, useFetch_job_openingQuery, useFetch_job_opening_infoMutation, useDeleteServiceMutation } = jobApi;

export const {
    endpoints: { getJobsPage, fetch_job_opening, fetch_job_opening_info, deleteService }
} = jobApi;
