import { FC, useRef, useState } from "react";
import LabelAndTextbox from "../../components/SeoData/LabelAndTextbox";
import { CancelIcon, SaveIcon } from "../../components/common/Icons";
import { twJoin } from "tailwind-merge";
import classNames from "classnames";
import CommonLanguage from "../../components/common/CommonLanguage";

interface IData {
  initialData: JobDepartmentType;
  setValue: React.Dispatch<any>;
  setShowList: React.Dispatch<React.SetStateAction<boolean>>;
  handle_submit: (data: JobDepartmentType) => Promise<void>;
  isSave:boolean;
}

export interface JobDepartmentType {
  id: number;
  title_en: string;
  title_sc: string;
  title_tc: string;
}

const JobDepartment: FC<IData> = ({ setShowList, initialData,handle_submit,isSave }) => {
  const [activeLang, setActiveLang] = useState<string>("en");
  const [data, setData] = useState<JobDepartmentType>(initialData);
  const change_data = (id: number, value: any, key: string) => {
    if (data) {
      setData({ ...data, [key]: value });
    }
  };

  const changeLanguage = (code: string) => {
    setActiveLang(code);
  };
  return (
    <div>
      <h2 className="text-lg text-black font-bold text-left my-5">
        Job Department
      </h2>
      <div className="flex flex-col lg:flex-row gap-10">
        <div className="">
          <div className="mb-5">
            <CommonLanguage
              changeLanguage={changeLanguage}
              activeLang={activeLang}
            />
          </div>

          <div
            className={twJoin(
              activeLang == "en"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <LabelAndTextbox
              label="Title (EN)"
              value={initialData?.title_en}
              setValue={(value: any) =>
                change_data(data?.id, value, "title_en")
              }
            />
          </div>

          <div
            className={twJoin(
              activeLang == "sc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <LabelAndTextbox
              label="Title (SC)"
              value={initialData?.title_sc}
              setValue={(value: any) =>
                change_data(data?.id, value, "title_sc")
              }
            />
          </div>

          <div
            className={twJoin(
              activeLang == "tc"
                ? ""
                : "opacity-0 pointer-events-none max-h-0 overflow-hidden"
            )}
          >
            <LabelAndTextbox
              label="Title (TC)"
              value={initialData?.title_tc}
              setValue={(value: any) =>
                change_data(data?.id, value, "title_tc")
              }
            />
          </div>
        </div>
      </div>
      <div className="mt-5 last:mb-0 flex items-center">
        <button
          className={classNames(
             isSave ? "opacity-50 pointer-events-none" : "",
            "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
          )}
          onClick={() => handle_submit(data)}
        >
          <SaveIcon className="mr-2" /> Save
        </button>
        <button
          className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
          onClick={() => {
            setShowList(true);
          }}
        >
          <CancelIcon className="mr-2" color="#9E3039" />
          Cancel
        </button>
      </div>
    </div>
  );
};
export default JobDepartment;
